<template>
    <div>
        <HelloComponentVue :username="getCurrentUser.name"
            :message="'Manage your projects and your team more easily !'">
        </HelloComponentVue>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class=" col-4">
                                <div class="btn-group" role="group" aria-label="Navigation">
                                    <button type="button" :class="1 == currentPage ? 'active' : ''" class="btn btn-light"
                                        @click="changePage(1)">Validations</button>
                                    <button type="button" :class="2 == currentPage ? 'active' : ''" class="btn btn-light"
                                        @click="changePage(2)">{{ $t('component.AuthPageComponent.SupervisorPageComponent.list_task') }}</button>
                                </div>
                            </div>
                            <div class="col-8">
                                <select v-show="currentPage === 1" class="form-select flex-grow-1" v-model="vaidator">
                                    <option value="yes">{{ $t('component.AuthPageComponent.SupervisorPageComponent.option_validated') }}</option>
                                    <option value="no">{{ $t('component.AuthPageComponent.SupervisorPageComponent.option_not_validated') }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="validated-task-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('component.AuthPageComponent.SupervisorPageComponent.validate_task') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <form @submit.prevent="validateTask_" class="row g-3 needs-validation">
                            <div class="col-md-12">
                                <label class="form-label">{{ $t('component.AuthPageComponent.SupervisorPageComponent.label_enter_a_score') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <input type="number" min="0" max="20" class="form-control" name="name"
                                    v-model="validate_note" placeholder="Validate note" required>
                                <div class="invalid-feedback">
                                    {{ $t('component.AuthPageComponent.SupervisorPageComponent.please_enter_note') }}
                                </div>
                            </div>
                            <div v-if="isDelayed" class="form-group">
                            <label for="delayReason">{{ $t('component.AuthPageComponent.SupervisorPageComponent.please_enter_delay') }}</label>
                            <textarea v-model="delay_reason" class="form-control" rows="3" required></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-success text-end" type="submit">{{ $t('component.AuthPageComponent.SupervisorPageComponent.validate_task') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="rejeter-task-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('component.AuthPageComponent.SupervisorPageComponent.rejected_task') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <form @submit.prevent="rejetedTask_" class="row g-3 needs-validation">
                            <div class="col-md-12">
                                <label for="validationCustom02" class="form-label">{{ $t('component.AuthPageComponent.SupervisorPageComponent.reasons_for_rejection') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <textarea v-model="raison_rejet" class="form-control" rows="5" id="validationCustom02"
                                    placeholder="Enter description" required></textarea>
                                <div class="invalid-feedback">
                                    {{ $t('component.AuthPageComponent.SupervisorPageComponent.please_enter_raison') }}
                                   
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-danger text-end" type="submit">{{ $t('component.AuthPageComponent.SupervisorPageComponent.rejected_task') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 1">
            <div class="row">
                <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                    <div class="card flex-fill p-4">
                        <div class="card-header">
                            <h5 class="card-title mb-0">{{ $t('component.AuthPageComponent.SupervisorPageComponent.list_of_tasks') }}</h5>
                        </div>
                        <div v-show="!filteredTasks.length">
                            <div class="alert alert-info alert-dismissible" role="alert">
                                <div class="alert-icon">
                                    <i class="far fa-fw fa-bell"></i>
                                </div>
                                <div class="alert-message">
                                    <strong>{{ $t('component.AuthPageComponent.SupervisorPageComponent.no_task_found') }}</strong>
                                </div>
                            </div>
                        </div>
                        <div v-show="filteredTasks.length">
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>{{ $t('component.AuthPageComponent.SupervisorPageComponent.name') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('component.AuthPageComponent.SupervisorPageComponent.date') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.duration') }}</th>
                                        <th class="d-md-table-cell">{{ $t('general.assignee') }}</th>
                                        <th class="d-md-table-cell">{{ $t('component.AuthPageComponent.SupervisorPageComponent.progress') }}</th>
                                        <th class="d-md-table-cell">
                                            {{ $t('component.AuthPageComponent.SupervisorPageComponent.validated_date') }}/<br>
                                            {{ $t('component.AuthPageComponent.SupervisorPageComponent.validation_deadline') }}
                                            </th>
                                        <th class="d-md-table-cell">{{ $t('general.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="task in filteredTasks" :key="task.task.id">
                                        <td>
                                            <router-link class="text-hover" title="View"
                                                :to="{ name: 'task-detail', params: { id: task.task.id } }">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-eye align-middle btn-link">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                            </router-link>
                                            {{ task.task.service_task.name }}
                                        </td>
                                        <td class="d-xxl-table-cell">
                                            <strong>F: {{ $formatDateWithTime(task.task.end_date) }}</strong>
                                            <div class="text-muted">
                                                D : {{ $formatDateWithTime(task.task.start_date) }}
                                            </div>
                                        </td>
                                        <td class="d-xl-table-cell">{{ $formatDuration(task?.details?.duration) }} hours</td>
                                        <td class="d-md-table-cell">{{ task.task?.assigne?.name }}</td>
                                        <td>
                                            <span v-show="task.task.status == 'In progress'" class="badge bg-warning">{{
                                                task.task.status }}</span>
                                            <span v-show="task.task.status == 'Completed'" class="badge bg-success">{{
                                                task.task.status }}</span>
                                            <span v-show="task.task.status == 'Pending'" class="badge bg-info">{{
                                                task.task.status }}</span>
                                            <span v-show="task.task.status == 'On pause'" class="badge bg-warning">{{
                                                task.task.status }}</span>
                                            <span v-show="task.task.status == 'Rejeted'" class="badge bg-danger">{{
                                                task.task.status }}</span>
                                        </td>
                                        <td class="d-md-table-cell"><span class="badge bg-secondary">
                                            {{ task.task.validated_date ? $formatDateWithTime(task.task.validated_date) : '-' }}</span><br>
                                            {{ task.task.validation_deadline ? $formatDateWithTime(task.task.validation_deadline) : '-' }}</td> 
                                        <td class="d-md-table-cell">
                                            <span v-show="task.task.is_validated">
                                                <button class="btn btn-outline-primary btn-sm" disabled>Validate</button>
                                            </span>
                                            <span v-show="!task.task.is_validated">
                                                <button data-bs-toggle="modal" data-bs-target="#rejeter-task-modal"
                                                    :data-id="task.task.id" v-show="task.task.status == 'Completed'"
                                                    class="btn btn-danger btn-sm rejeted_btn me-1">{{ $t('component.AuthPageComponent.SupervisorPageComponent.rejected') }}</button>
                                                <button data-bs-toggle="modal" data-bs-target="#validated-task-modal"
                                                    :data-id="task.task.id" v-show="task.task.status == 'Completed'"
                                                    class="btn btn-primary btn-sm me-1 validated_btn">{{ $t('component.AuthPageComponent.SupervisorPageComponent.validate') }}</button>
                                                <button
                                                    v-show="(task.task.status == 'Pending') || (task.task.status == 'In progress') || (task.task.status == 'On pause') || (task.task.status == 'Rejeted')"
                                                    class="btn btn-primary btn-sm" disabled>...</button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 2">
            <div v-if="projects.length">
                <table class="table table-hover my-0" width="100%">
                    <thead>
                        <tr>
                            <th>{{ $t('component.AuthPageComponent.SupervisorPageComponent.name') }}/{{ $t('component.AuthPageComponent.SupervisorPageComponent.date_assigne') }}</th>
                            <th >{{ $t('general.start_date') }}/{{ $t('general.end_date') }}</th>
                            <th>{{ $t('component.AuthPageComponent.SupervisorPageComponent.type_service') }}</th>
                            <th>{{ $t('component.AuthPageComponent.SupervisorPageComponent.point') }}</th>
                            <th>{{ $t('general.status') }}</th>
                            <th>{{ $t('component.AuthPageComponent.SupervisorPageComponent.close_date') }}</th>
                            <th >{{ $t('general.action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(project, index) in projects" :key="index">
                            <td>
                                {{ project.name }}<br>
                                <small>{{ $formatDateWithTime(project.date_assigned) }}</small>
                            </td>
                            <td>
                                {{ $formatDateWithTime( project.start_date) }}<br>
                                {{ $formatDateWithTime(project.end_date) }}
                            </td>
                            <td>
                                <span class="badge bg-info">{{ project.service.name }}</span>
                            </td>
                            <td>
                               <span v-if="project.point > 10" class="badge bg-primary">{{ project.point }}/20</span>
                               <span v-else class="badge bg-danger">{{ project.point }}/20</span>
                            </td>
                            <td>
                                <span v-if="project.status == 'In progress'" class="badge bg-warning my-2">{{
                                            project.status }}</span>
                                        <span v-if="project.status == 'Pending'" class="badge bg-danger my-2">{{
                                            project.status }}</span>
                                        <span v-if="project.status == 'Aborded'" class="badge bg-danger my-2">{{
                                            project.status }}</span>
                                        <span v-if="project.status == 'Completed'" class="badge bg-success my-2">{{
                                            project.status }}</span>
                            </td>
                            <td>
                                <span class="badge bg-secondary">{{ $formatDateWithTime(project.date_assigned) }}</span>
                            </td>
                            <td class="d-none d-xl-table-cell text-center">
                                <router-link v-if="$can('taskassignment_view')" title="View" class="btn btn-primary rounded text-white"  :to="{ name: 'project-detail', params: { id: project.id }}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-eye align-middle btn-link">
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </router-link>
                                <span v-else>...</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <div class="alert alert-info alert-dismissible" role="alert">
                    <div class="alert-icon">
                        <i class="far fa-fw fa-bell"></i>
                    </div>
                    <div class="alert-message">
                        <strong>{{ $t('component.AuthPageComponent.SupervisorPageComponent.info') }}</strong>{{ $t('component.AuthPageComponent.SupervisorPageComponent.you_do') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapActions, mapGetters } from 'vuex'
import $ from 'jquery';
import HelloComponentVue from '../General/HelloComponent.vue'

export default {
    name: 'SupervisorPageComponent',
    data() {
        return {
            tasks: [],
            vaidator: 'no',
            raison_rejet: null,
            delay_reason: '',
            isDelayed: false,
            task_id: null,
            validate_note: 0,
            currentPage: 1,
            projects: []
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        filteredTasks() {
            if (!Array.isArray(this.tasks)) {
                return [];
            }
            const filtered = this.tasks.filter(task => {
                if (this.vaidator === 'no') {
                    return task.task.is_validated === false
                }
                if (this.vaidator === 'yes') {
                    return task.task.is_validated === true
                }
                return true;
            });
            return filtered;
        },
    },
    components: {
        HelloComponentVue,
    },
    async mounted() {
        await this.initProjects()
        await this.initTasks()
        $(document).on('click', '.validated_btn', (e) => {
            const taskId = e.target.getAttribute('data-id');
            const task = this.tasks.find(t => t.task.id === parseInt(taskId));
            
            const now = new Date();
            const deadline = new Date(task.task.validation_deadline);
            this.isDelayed = now > deadline;

            this.task_id = taskId;
        })
        $(document).on('click', '.rejeted_btn', (e) => {
            this.task_id = e.target.getAttribute('data-id')
        })
    },
    methods: {
        ...mapActions('task_assignment', ['getSupervisorTasks', 'validateTask', 'rejetedTask']),
        ...mapActions('project', ['getProjectFromAManagers']),
        async initTasks() {
            this.tasks = await this.getSupervisorTasks(this.getCurrentUser.id)
        },
        changePage(number) {
            this.currentPage = number
        },
        async initProjects(){
            let response = await this.getProjectFromAManagers(this.getCurrentUser.id)
            this.projects = response
        },
        async validateTask_() {
            try {
                const payload = {
                    task_id: this.task_id,
                    point: this.validate_note,
                };

                if (this.isDelayed) {
                    if (!this.delay_reason) {
                        this.$errorNotyf('Veuillez fournir une raison pour le retard de la validation');
                        return;
                    }
                    payload.delay_reason = this.delay_reason;
                }

                await this.validateTask(payload);
                this.$successNotyf('Tâche validée avec succès.');
                this.resetForm();
                await this.initTasks();
                jQuery('#validated-task-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error);
            }
        },

            resetForm() {
                this.validate_note = 0;
                this.delay_reason = '';
                this.task_id = null;
                this.isDelayed = false;
            },
        async rejetedTask_() {
            try {
                await this.rejetedTask({ task_id: this.task_id, notes: this.raison_rejet })
                this.$successNotyf('Task rejeted successfully.')
                this.raison_rejet = null
                this.task_id = null
                await this.initTasks()
                jQuery('#rejeter-task-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
        }
    }
}
</script>
<style></style>
    