<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('menu.users') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('users_create')" class="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#new-user-modal">{{ $t('page.users.new_user') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-user-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.new_user') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addNewUser" class="row g-3">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="userFormData.name" class="form-control"
                                        placeholder="Name" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="userFormData.email" class="form-control"
                                        placeholder="Email" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.parteners.enter_department') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-department"
                                        v-model="userFormData.department" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="dep in departments" :key="dep.id" :value="dep.id">{{ dep.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.parteners.entreprise') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select"
                                        v-model="userFormData.entreprise" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="ent in entreprises" :key="ent.id" :value="ent.id">{{ ent.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="userFormData.phone" class="form-control"
                                        placeholder="Phone" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label"> {{ $t('page.users.superieur') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-sup" v-model="userFormData.superieur"
                                        required>
                                        <option selected value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="sup in allUsers" :key="sup.id" :value="sup.id">{{ sup.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="userFormData.address" class="form-control"
                                        placeholder="Address" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.user_type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-type" v-model="userFormData.user_type"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="userT in userTypeFilter" :key="userT.id" :value="userT.id">{{
                                            userT.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.grade') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-grade" v-model="userFormData.grade"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="grade in listGrades" :key="grade.id"
                                            :value="grade.id">{{
                                                grade.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="userFormData.country"
                                        required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{
                                            country?.name }} ({{ country?.code }})</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonClass="btn-primary"
                                        buttonSubmitType="submit"
                                        :buttonText="$t('general.save')"
                                        :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="user-detail-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <div class="card" v-if="!isLoading">
                                <div class="card-body">
                                    <div class="row mb-xl-3">
                                        <div class="d-flex justify-content-between mb-3">
                                            <h5 class="card-title">
                                                <span>{{ user?.name }}</span>
                                            </h5>
                                            <div>
                                                <button
                                                    @click="changePage(user_id, 'permission')"
                                                    v-if="$can('user_roles_view')"
                                                    class="btn btn-primary btn-sm btn-sm rounded me-2"
                                                >
                                                    {{ $t('page.parteners.define_roles') }}
                                                </button>
                                                <button
                                                    @click="changePage(user_id, 'stat')"
                                                    v-if="$can('statistiques_view')"
                                                    class="btn btn-success btn-sm btn-sm rounded me-2"
                                                >
                                                    {{ $t('page.users.view_statistics') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-sm">
                                        <tbody>
                                            <tr>
                                                <th>{{ $t('page.users.name') }} </th>
                                                <td class="text-end">{{ user?.name }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.department') }} </th>
                                                <td class="text-end">{{ user?.department?.name ?? "N/A" }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.parteners.entreprise') }}</th>
                                                <td class="text-end">{{ user?.entreprise?.name ?? "N/A" }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.email') }} </th>
                                                <td class="text-end">{{ user?.email }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.phone') }} </th>
                                                <td class="text-end">{{ user?.phone }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.adresse') }}</th>
                                                <td class="text-end">{{ user?.address ? user?.address : '---' }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.country') }}</th>
                                                <td class="text-end">{{ user?.country?.name }} ({{ user?.country?.code }})
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.user_type') }}</th>
                                                <td class="text-end">{{ user?.user_type?.name }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.superieur') }}</th>
                                                <td class="text-end">{{ getUserSuperiorName(user?.superieur) }}</td>
                                            </tr>
                                            <tr v-if="$can('grades_view_all')">
                                                <th>{{ $t('page.users.grade') }} </th>
                                                <td class="text-end">{{ user?.grade ? user?.grade?.name : '---' }} ({{
                                                    user?.grade ? user?.grade.cost : '---' }})</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('general.status') }} </th>
                                                <td class="text-end">
                                                    <span v-show="user?.status == 'active'"
                                                        class="badge bg-success">{{ $t('new.message1') }}</span>
                                                    <span v-show="user?.status != 'active'"
                                                        class="badge bg-warning">{{ $t('new.message2') }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td v-if="$can('active_or_desactive_user')" colspan="2" class="text-end">
                                                    <span v-show="user?.status == 'active'">
                                                        <button @click="changeUserStatus()" class="btn btn-danger text-end" title="Desactivate">
                                                            <i class="fas fa-times"></i>
                                                            {{ $t('page.users.desactivate') }} 
                                                        </button>
                                                    </span>
                                                    <span v-show="user?.status != 'active'">
                                                        <button @click="changeUserStatus()" class="btn btn-primary text-end" title="Activate">
                                                            {{ $t('page.users.activate') }} 
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <empty-page-component v-else :code="'Warning'" :message="'Chargement...'"></empty-page-component>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="update-user-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.update_user') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="_updateUserData" class="row g-3">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="updateUserData.name" class="form-control"
                                        placeholder="Name" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="updateUserData.email" class="form-control"
                                        placeholder="Email" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.enter_department') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-department"
                                        v-model="updateUserData.department" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="dep in departments" :key="dep.id" :value="dep.id">
                                            {{ dep.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.parteners.entreprise') }} <i class="text-danger align-middle me-2 fas fa-fw fa-asterisk">
                                        </i>
                                    </label>
                                    <select class="form-select" v-model="updateUserData.entreprise" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="ent in entreprises" :key="ent.id" :value="ent.id">{{ ent.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="updateUserData.phone" class="form-control"
                                        placeholder="Phone" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label"> {{ $t('page.users.superieur') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-sup" v-model="updateUserData.superieur">
                                        <option selected value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="sup in allUsers" :key="sup.id" :value="sup.id">{{ sup.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="updateUserData.address" class="form-control"
                                        placeholder="Address" required>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.user_type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-type" v-model="updateUserData.userType"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="userT in userTypeFilter" :key="userT.id" :value="userT.id">{{
                                            userT.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.grade') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-grade" v-model="updateUserData.grade"
                                        required>
                                        <option>{{ $t('page.users.choice') }}...</option>
                                        <option v-for="grade in listGrades" :key="grade.id"
                                            :value="grade.id">{{
                                                grade.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label class="form-label">{{ $t('page.users.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="updateUserData.country"
                                        required>
                                        <option selected disabled value="">{{ $t('page.users.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id">{{
                                            country?.name }} ({{ country?.code }})</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonClass="btn-primary"
                                        buttonSubmitType="submit"
                                        :buttonText="$t('general.save')"
                                        :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row card pb-4">
                <div class="col-xl-12">
                    <DatatableComponent 
                        :headers="tableHeaders" 
                        :data="dataUsers" 
                        :paginable="true" 
                        :currentPage="currentPage"
                        :totalPage="totalPage"
                        :isSearchable="true"
                        :filters="tableFilters"
                        :reloadFunction="initPage"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component code="" :message="$t('general.loading')+ '...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import DatatableComponent from '@/components/General/DatatableComponent.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'usersComponent',

    components: {
        EmptyPageComponent,
        DatatableComponent,
        CustomButton
    },

    data() {
        return {
            allUsers: [],
            dataUsers: [],
            isDispo: false,
            isStart: false,
            isLoading: false,
            user_id: null,
            user_: {},
            user: {},
            userFormData: {
                name: null,
                country: '',
                email: null,
                department: '',
                entreprise: '',
                phone: null,
                user_type: null,
                password: null,
                address: null,
                superieur: '',
                grade: ''
            },
            countries: [],
            departments: [],
            entreprises: [],
            listGrades: [],
            userTypeFilter: [],
            updateUserData: {},
            currentPage: 1,
            totalPage: 1,
            tableHeaders: [
                { name: this.$t('page.users.name'), key: 'name', type: 'key' },
                { name: this.$t('page.users.department'), key: 'department__name', type: 'key' },
                { name: this.$t('page.users.phone'), key: 'phone', type: 'key' },
                { name: this.$t('page.users.entreprise'), key: 'entreprise__name', type: 'key' },
                { name: this.$t('page.users.country'), key: 'country__name', type: 'key' },
                { name: this.$t('page.users.user_type'), key: 'user_type__name', type: 'key', badge: 'bg-primary' },
                { name: this.$t('page.users.grade'), key: 'grade__name', type: 'key', badge: 'bg-success' },
                { name: this.$t('page.users.action'), 
                    type: 'action', 
                    actions: [
                        { type: 'modal_view', text: '', function: this.userDetails, show_text: false, target: '#user-detail-modal', check_permission: () => this.$can('users_view')  },
                        { type: 'modal_edit', text: '', function: this.loadUserData, show_text: false, target: '#update-user-modal', check_permission: () => this.$can('can_update_user_profile')  },
                    ] 
                },
            ],
            tableFilters: [
                { key: 'country', options: [], label: this.$t('page.users.country'), type: 'select', value: 'all' },
                { key: 'user_type', options: [], label: this.$t('page.users.user_type'), type: 'select', value: 'all' },
                { key: 'department', options: [], label: this.$t('page.users.department'), type: 'select', value: 'all' },
                { key: 'grade', options: [], label: this.$t('page.users.grade'), type: 'select', value: 'all' },
                { key: 'entreprise', options: [], label: this.$t('page.users.entreprise'), type: 'select', value: 'all' },
            ],
        };
    },

    async mounted() {
        this.isDispo = false
        await this.initData()
        await this.initPage()
        await this.loadCountries()
        await this.loadDepartments()
        await this.initGrades()
        await this.initEntreprises()
        this.isDispo = true
    },

    methods: {
        ...mapActions('ticket', ['getAllDepartments',]),
        ...mapActions('auth', ['users', 'userStat', 'registerUser', 'activateOrDeactivateUser', 'getCountries', 'updateUser', 'userList']),
        ...mapActions('grade', ['grade', 'allGrades']),
        ...mapActions('user_type', ['allUserTypes']),
        ...mapActions('entreprise', ['fetchEntreprises','updateEntreprise']),
        async initGrades() {
            let response = await this.allGrades()
            this.listGrades = response
            this.tableFilters[3].options = response
        },
        async initEntreprises() {
            try {
                
                let response = await this.fetchEntreprises();                
                
                // Vérifie la structure de la réponse
                console.log("Réponse de l'API : ", response);
                
                // Extraire les entreprises de response.data
                this.entreprises = response;  // 'data' contient la liste des entreprises

                // Affiche les entreprises pour vérification
                console.log("Données entreprises : ", this.entreprises);
                
                // Assigner les entreprises à options de table ou autre traitement
                this.tableFilters[4].options = this.entreprises;

            } catch (error) {
                console.error("Erreur lors de la récupération des entreprises : ", error);
            }
        },
        changePage(user_id, type){
            jQuery('#user-detail-modal').modal('toggle'); // eslint-disable-line no-undef
            if(type == 'permission') this.$router.push({ name: 'user-permissions', params: { id: user_id } })
            else if(type == 'stat') this.$router.push({ name: 'user-statistic', params: { id: user_id } })
        },

        async changeSubmitType(type, gradeId = null) {
            this.typeSubmitGrade = type
            if (gradeId != null) {
                let response = await this.grade(gradeId)
                const { id, name, cost } = response
                this.gradeFormUpdateData = { id, name, cost }
            }
        },

        loadUserData(user) {
            this.updateUserData.id = user.id;
            this.updateUserData.name = user.name;
            this.updateUserData.email = user.email;
            this.updateUserData.department = user.department__id;
            this.updateUserData.phone = user.phone;
            this.updateUserData.address = user.address;
            this.updateUserData.status = user.status;
            this.updateUserData.grade = user.grade__id;
            this.updateUserData.userType = user.user_type__id;
            this.updateUserData.country = user.country__id;
            this.updateUserData.entreprise = user.entreprise__id;
            console.log('Mise à jour entreprise:', this.updateUserData.entreprise);
            this.updateUserData.superieur = user.superieur__id;
        },

        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },

        async initPage(options = { search: '', filters: {}, perPage: 10, page: 1 }) {
            this.isLoading = true
            try {
                // Ajouter le type à filters de manière correcte
                options.filters = { ...options.filters, type: 'member' };

                this.allUsers = await this.users()
                let response = await this.userList(options); // Passer directement options avec filters modifié
                this.dataUsers = response.data || []
                this.currentPage = response.current_page || 1;
                this.totalPage = response.total_pages || 1;
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }

            let filterName = 'Partener'
            this.allUsers = this.allUsers.filter(user => {
                return user.user_type.name !== filterName;
            });
            this.isLoading = false
        },

        async initData(){
            let filterName = 'Partener'
            try {
                let resp = await this.allUserTypes()
                this.userTypes = resp
            } catch (error) {
                this.$errorNotyf(error)
            }
            
            this.userTypeFilter = this.userTypes.filter(type => {
                return type.name !== filterName;
            });
            this.tableFilters[1].options = this.userTypeFilter
        },

        async loadCountries() {
            let response = await this.getCountries()
            this.countries = response
            this.tableFilters[0].options = response
        },

        async loadDepartments() {
            try {
                let response = await this.getAllDepartments()
                this.departments = response
                this.tableFilters[2].options = response
            } catch (error) {
                console.error('Error loading departments:', error);
            }
        },

        async userDetails(user) {
            this.isLoading = true
            this.user_id = user?.id
            if(this.user_id){
                this.user_ = await this.userStat(this.user_id)
                this.user = this.allUsers.find(element => element.id === user.id);
                console.log(this.user)
            }
            this.isLoading = false
        },

        async changeUserStatus() {
            let userId = this.user.id
            await this.activateOrDeactivateUser({ id: userId })
            this.initPage()
            this.userDetails(userId)
        },

        async addNewUser() {
            this.userFormData.password = this.$genererMotDePasse(10)
            try {
                this.isLoading = true
                await this.registerUser(this.userFormData)
                console.log(this.userFormData)
                this.handleSuccess(this.$t('messages.m'))
                this.userFormData.name = null,
                    this.userFormData.email = null,
                    this.userFormData.department = null,
                    this.userFormData.entreprise = null,
                    this.userFormData.phone = null,
                    this.userFormData.user_type = null,
                    this.userFormData.password = null,
                    this.userFormData.address = null,
                    this.userFormData.superieur = null,
                    this.initPage()
                jQuery('#new-user-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
            this.isLoading = false
            this.initPage()
        },

        async _updateUserData() {
            this.isLoading = true
            try {
                this.userFormData.department = this.updateUserData.department;
                this.userFormData.entreprise = this.updateUserData.entreprise;
                console.log('Mise à jour des données utilisateur:', this.updateUserData);
                await this.updateUser(this.updateUserData)
                console.log('Données utilisateur mises à jour:', this.updateUserData);
                this.handleSuccess(this.$t('messages.l'))
                this.initPage()
                jQuery('#update-user-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
            this.isLoading = false
        },

        handleSuccess(message) {
            return this.$successNotyf(message)
        },

        getUserSuperiorName(superiorId) {
            const superior = this.allUsers.find(user => user.id === superiorId);
            return superior ? superior.name : this.$t('messages.k');
        },
    }
}
</script>
<style></style>