<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-sm-block">
                    <h3><strong>{{ $t('forms.project_task_configuration') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button class="btn btn-danger me-2" data-bs-toggle="modal" data-bs-target="#how_configurate">
                        <i class="align-middle fas fa-fw fa-question-circle"></i>
                        {{ $t('forms.how_configurate') }}
                    </button>
                    <button class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#show-project-detail">
                        {{ $t('forms.show_projec_detail') }}
                    </button>
                    <button class="btn btn-success my-1 me-2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRightDocument" aria-controls="offcanvasRight">
                        <i class="align-middle fas fa-fw fa-file-alt"></i>
                        {{ $t('forms.list_docs') }}
                    </button>
                    <button @click="goBack" class="btn btn-secondary" title="Back"><i
                            class="align-middle fas fa-fw fa-arrow-left"></i></button>
                </div>
            </div>
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightDocument"
                aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">{{ $t('forms.list_docs') }}</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('general.name') }}</th>
                                <th>{{ $t('general.status') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="s_doc in service_docs" :key="s_doc.id">
                                <td><i class="align-middle me-2 fas fa-fw fa-comment-alt"
                                        @click="viewDocDescription(s_doc.id)" data-bs-toggle="modal"
                                        data-bs-target="#decription-doc"></i> {{ s_doc.name }}</td>
                                <td>
                                    <span v-show="s_doc.status == 'unarchive'" class="badge bg-primary">{{ s_doc.status
                                        }}</span>
                                    <span v-show="s_doc.status == 'archive'" class="badge bg-danger">{{ s_doc.status
                                        }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal fade" id="decription-doc" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('forms.document') }} : {{ this_document?.name }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <div class="card m-0">
                                <div class="card-body">
                                    <p>{{ this_document?.description }}</p>
                                    {{ $t('forms.status') }} : <span v-if="this_document?.status == 'unarchive'"
                                        class="text-end badge bg-primary">{{ $t('forms.unarchive') }}</span>
                                    <span v-else class="text-end badge bg-danger">{{ $t('forms.archive') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="how_configurate" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('task_configuration.title') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <p>{{ $t('task_configuration.intro') }}</p>

                            <!-- Liste des étapes -->
                            <ol class="list-group list-group-numbered">
                                <li class="list-group-item">
                                    <strong class="text-primary">{{ $t('task_configuration.step1_title') }}</strong>
                                    {{ $t('task_configuration.step1_description') }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.step2') }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.step3') }}
                                    <strong class="text-primary">{{ $t('task_configuration.task_list') }}</strong>
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.step4_intro') }}
                                    <ul class="mt-2">
                                        <li>{{ $t('task_configuration.step4_substep1') }}</li>
                                        <li>{{ $t('task_configuration.step4_substep2') }}</li>
                                    </ul>
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.step5') }}
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.add_more_task') }}
                                    <strong class="text-danger">{{ $t('task_configuration.add_more_task_btn') }}</strong>
                                </li>
                                <li class="list-group-item">
                                    {{ $t('task_configuration.step6') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="show-project-detail" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ project.name }} ({{ project.service.name }})</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <div class="card p-3 bg-light">
                                {{ project.description }}
                            </div>
                            <hr>
                            <div class="d-flex align-items-center">
                                <strong>{{ $t('general.manager') }}</strong>
                                <div class="ms-auto">
                                    <strong class="text-navy">{{ project.manager?.name }}</strong>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-2">
                                <strong class="text-muted text-sm">{{ $t('forms.open_date') }}</strong>
                                <div class="ms-auto">
                                    <span class="badge bg-success">
                                        {{ project.open_date ? $formatDateWithTime(project.open_date) : '-- -- --' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="new-service-doc-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content bg-light">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('forms.edit_task_docs') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="saveServiceTask" class="row g-3 needs-validation">
                                <div class="card m-0">
                                    <div class="card" v-for="(serviceTask, key) in serviceTaskFormData" :key="key">
                                        <div class="card-body">
                                            <div class="row g-3">
                                                <div class="col-md-6">
                                                    <label for="validationCustom01" class="form-label">{{
                                                        $t('forms.task_name') }}<i
                                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                    <input type="text" class="form-control" v-model="serviceTask.name"
                                                        id="validationCustom01" placeholder="Task name" required>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="validationCustom02" class="form-label">{{
                                                        $t('general.severity') }} <i
                                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                    <select class="form-select" v-model="serviceTask.severity"
                                                        id="validationCustom02" required>
                                                        <option selected disabled value="">{{ $t('general.choice') }}
                                                        </option>
                                                        <option value="Low">{{ $t('general.low') }}</option>
                                                        <option value="Medium">{{ $t('general.medium') }}</option>
                                                        <option value="High">{{ $t('general.high') }}</option>
                                                    </select>
                                                    <div class="invalid-feedback">
                                                        {{ $t('forms.severity_description') }}
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="validationCustom03" class="form-label">{{
                                                        $t('forms.min_et')
                                                    }}<i data-bs-toggle="tooltip" data-bs-placement="top"
                                                            title="{{ $t('forms.min_et_description') }}"
                                                            class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></label>
                                                    <input type="number" v-model="serviceTask.minimal_time" min="1"
                                                        class="form-control" id="validationCustom03" required>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="validationCustom04" class="form-label">{{
                                                        $t('forms.max_et')
                                                    }}<i data-bs-toggle="tooltip" data-bs-placement="top"
                                                            title="{{ $t('forms.max_et_description') }}"
                                                            class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></label>
                                                    <input type="number" v-model="serviceTask.maximal_time" min="1"
                                                        class="form-control" id="validationCustom04" required>
                                                </div>
                                                <div class="col-md-12">
                                                    <label for="validationCustom04" class="form-label">{{
                                                        $t('forms.description') }} <i
                                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                    <textarea class="form-control" v-model="serviceTask.description"
                                                        rows="3" id="validationCustom04" placeholder="Enter description"
                                                        required></textarea>
                                                    <div class="invalid-feedback">
                                                        {{ $t('forms.description_description') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-end">
                                    <CustomButton buttonType="button" buttonSubmitType="submit"
                                        buttonClass="btn-primary" :buttonText="$t('general.save')"
                                        :isLoading="isLoading" :loadingText="$t('general.loading') + '...'" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-7">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <h4 class="text-muted">{{ $t('forms.list_tasks') }}</h4>
                                </div>
                                <div class="col-auto ms-auto">
                                    <CustomButton buttonType="button" buttonClass="btn-primary"
                                        :buttonText="$t('general.save')" :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'" @click="saveProjectTask" />
                                </div>
                            </div>
                        </div>
                        <div class="card-body bg-light">
                            <div id="tasks" style="min-height: 50px;" @drop="dropTasks" @dragover.prevent>
                                <div v-for="(task, index) in tasks" :key="index" :draggable="true"
                                    @dragstart="dragStartTasks(index)" class="card mb-3 cursor-grab">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center mb-3">
                                            <h5 class="card-title">{{ task.name }}</h5>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    v-model="task.make_dependance">
                                                <label class="form-check-label">{{
                                                    $t('forms.execute_dependent_task_first') }}</label>
                                            </div>
                                        </div>
                                        <p class="card-text">{{ task.description }}</p>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label class="form-label">{{ $t('forms.depend') }}</label>
                                                <select class="form-select" v-model="task.dependance">
                                                    <option selected class="text-muted">{{ $t('forms.add_depend') }}
                                                    </option>
                                                    <option v-for="s_task in filteredTasks" :key="s_task.id"
                                                        :value="s_task.id">{{ s_task.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="form-label">{{ $t('forms.niveau_task') }}</label>
                                                <select class="form-select" v-model="task.niveau">
                                                    <option selected class="text-muted">{{ $t('general.choice') }}
                                                    </option>
                                                    <option value="low">{{ $t('general.low') }}</option>
                                                    <option value="medium">{{ $t('general.medium') }}</option>
                                                    <option value="difficult">{{ $t('general.high') }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">{{ $t('forms.task_service') }}</h5>
                        </div>
                        <div class="card-body">
                            <div id="service-tasks" style="min-height: 50px;" @drop="dropServiceTasks"
                                @dragover.prevent>
                                <div v-for="(task, index) in serviceTasks" :key="index" :draggable="true"
                                    @dragstart="dragStartServiceTasks(index)" class="card mb-3 cursor-grab bg-light">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ task.name }}</h5>
                                        <p class="card-text">{{ truncateText(task.description, 50) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-grid">
                                <button class="btn btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#new-service-doc-modal">{{ $t('forms.additional_task') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>
<script>
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import CustomButton from '@/components/CustomButton.vue';
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'EditTaskPage',
    components: {
        EmptyPageComponent,
        CustomButton
    },
    data() {
        return {
            project: {},
            serviceTasks: [],
            service_docs: [],
            this_document: {},
            isDispo: false,
            tasks: [],
            serviceTaskFormData: [
                {
                    service: null,
                    creator: null,
                    name: null,
                    description: null,
                    severity: this.$t('general.low'),
                    minimal_time: 1,
                    maximal_time: 2,
                    status: 'archive'
                }
            ],
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        filteredTasks() {
            return this.tasks.filter(task => !task.done);
        }
    },
    async mounted() {
        if (!this.getCurrentUser) {
            await this._currentUser()
        }
        this.initProjectDetails()
    },
    methods: {
        ...mapActions('auth', ['_currentUser']),
        ...mapActions('service_task', ['getServiceTasksByServiceUnarchives', 'addServiceTask']),
        ...mapActions('service_doc', ['getServiceDocLists']),
        ...mapActions('project', ['getProject', 'listDocuments']),
        ...mapActions('task_assignment', ['createOrUpdateTask']),
        truncateText(text, maxLength=50) {
            if (!text) return '';
            return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
        },
        async initProjectDetails() {
            try {
                let project_id = this.$route.params.id
                this.project = await this.getProject(project_id)
                this.initServiceTasksAndDoc(this.project.service.id)
                this.isDispo = true
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        goBack() {
            window.history.go(-1);
        },
        async saveServiceTask() {
            this.isLoading = true
            for (let i = 0; i < this.serviceTaskFormData.length; i++) {
                this.serviceTaskFormData[i].creator = this.getCurrentUser.id;
                this.serviceTaskFormData[i].service = this.project.service.id;
            }
            try {
                let response = await this.addServiceTask(this.serviceTaskFormData)
                this.$successNotyf(this.$t("forms.additional_task_add_success"))
                this.serviceTasks.push(response[0])
                for (let i = 0; i < this.serviceTaskFormData.length; i++) {
                    this.serviceTaskFormData[i].creator = null
                    this.serviceTaskFormData[i].service = null
                    this.serviceTaskFormData[i].name = null
                    this.serviceTaskFormData[i].description = null
                    this.serviceTaskFormData[i].severity = 'Low'
                    this.serviceTaskFormData[i].minimal_time = 1
                    this.serviceTaskFormData[i].maximal_time = 2
                }
                jQuery('#new-service-doc-modal').modal('toggle'); // eslint-disable-line no-undef
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
        },
        viewDocDescription(id) {
            this.this_document = this.service_docs.find(element => element.id === id);
        },
        transformTasks(tasks) {
            const newTasks = [];
            for (const task of tasks) {
                const { id, name, description, severity, minimal_time, creator, service, dependance, make_dependance, niveau } = task;
                newTasks.push({
                    service_task: id,
                    depend: dependance ? dependance : null,
                    can_execute_dependent_before: make_dependance ? true : false,
                    is_validated: false,
                    project: this.project.id,
                    niveau: niveau
                });
            }
            return newTasks;
        },
        async initServiceTasksAndDoc(serviceTaskId) {
            this.serviceTasks = await this.getServiceTasksByServiceUnarchives(serviceTaskId)
            this.service_docs = await this.getServiceDocLists(serviceTaskId)
        },
        dragStartServiceTasks(index) {
            this.draggedIndex = index;
            this.draggedFrom = 'service-tasks';
        },
        dragStartTasks(index) {
            this.draggedIndex = index;
            this.draggedFrom = 'tasks';
        },
        dropServiceTasks(event) {
            const targetIndex = event.target.getAttribute('data-index');
            const targetPosition = targetIndex ? parseInt(targetIndex) : this.serviceTasks.length;
            if (this.draggedFrom === 'service-tasks') {
                const movedItem = this.serviceTasks.splice(this.draggedIndex, 1)[0];
                this.serviceTasks.splice(targetPosition, 0, movedItem);
            } else {
                this.serviceTasks.splice(targetPosition, 0, this.tasks[this.draggedIndex]);
                this.tasks.splice(this.draggedIndex, 1);
            }
        },
        dropTasks(event) {
            const targetIndex = event.target.getAttribute('data-index');
            const targetPosition = targetIndex ? parseInt(targetIndex) : this.tasks.length;
            if (this.draggedFrom === 'tasks') {
                const movedItem = this.tasks.splice(this.draggedIndex, 1)[0];
                this.tasks.splice(targetPosition, 0, movedItem);
            } else {
                this.tasks.splice(targetPosition, 0, this.serviceTasks[this.draggedIndex]);
                this.serviceTasks.splice(this.draggedIndex, 1);
            }
        },
        async saveProjectTask() {
            this.isLoading = true
            let taskFormData = this.transformTasks(this.tasks)
            if (taskFormData.length > 0) {
                try {
                    await this.createOrUpdateTask(taskFormData)
                    this.$successNotyf(this.$t('forms.configuration_project_success'))
                    this.$router.push({ name: 'project-detail', params: { id: this.project.id } });
                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    this.$errorNotyf(error)
                }
            } else {
                this.isLoading = false
                this.$successNotyf(this.$t('forms.warning_project_configuration_empty'), 'warning')
            }
        }
    }
}
</script>
<style>
.task {
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    cursor: move;
}
</style>
