<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.parteners.entreprises') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('entreprises_create')" class="btn btn-primary border" data-bs-toggle="modal"
                        data-bs-target="#new-entreprise-modal">{{ $t('page.parteners.new_entreprise') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-entreprise-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.new_entreprise') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addNewEntreprise" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-6">
                                    <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.name" class="form-control"
                                        id="validationCustom01" :placeholder="$t('general.name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.name') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.phone" class="form-control"
                                        id="validationCustom02" :placeholder="$t('general.phone')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormData.email" class="form-control"
                                        id="validationCustom03" :placeholder="$t('general.email')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                    <input type="text" v-model="entrepriseFormData.address" class="form-control"
                                        id="validationCustom04" :placeholder="$t('general.address')">
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.parteners.add_new_entreprise')"
                                        :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="update-entreprise-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.update_entreprise') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="_updateEntreprise" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-6">
                                    <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.name" class="form-control"
                                        id="validationCustom01" :placeholder="$t('general.name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.name') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.phone" class="form-control"
                                        id="validationCustom02" :placeholder="$t('general.phone')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="entrepriseFormUpdateData.email" class="form-control"
                                        id="validationCustom03" :placeholder="$t('general.email')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('general.email') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                    <input type="text" v-model="entrepriseFormUpdateData.address" class="form-control"
                                        id="validationCustom04" :placeholder="$t('general.address')">
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.parteners.update_entreprise')"
                                        :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row card">
                <div class="card-body">
                    <DatatableComponent 
                        :headers="tableHeaders" 
                        :data="entreprises" 
                        :paginable="true" 
                        :currentPage="currentPage"
                        :totalPage="totalPage"
                        :isSearchable="true"
                        :filters="tableFilters"
                        :reloadFunction="initEntreprises"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import DatatableComponent from '@/components/General/DatatableComponent.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'EntrepriseComponent',
    components: {
        EmptyPageComponent,
        DatatableComponent,
        CustomButton
    },
    data() {
        return {
            isDispo: false,
            user_id: null,
            isStart: false,
            isLoading: false,
            entrepriseFormUpdateData: {},
            typeSubmitEntreprise: 'create',
            entreprises: [],
            entrepriseFormData:{
                name: null,
                address : null,
                phone : null,
                email: null,
                status : 'active'
            },
            currentPage: 1,
            totalPage: 1,
            tableHeaders: [
                { name: this.$t('page.users.name'), key: 'name', type: 'key' },
                { name: this.$t('page.users.number'), key: 'phone', type: 'key' },
                { name: this.$t('general.email'), key: 'email', type: 'key' },
                { name: this.$t('general.address'), key: 'address', type: 'key' },
                { name: this.$t('general.status'), key: 'status', type: 'key', badge: 'bg-success', format: ((value) => {
                    return value == 'active' ? this.$t('general.active') : this.$t('general.inactive')
                }) },
                { name: this.$t('page.users.action'), 
                    type: 'action', 
                    actions: [
                        { type: 'modal_edit', text: 'Modifier', function: this.loadUpdateData, show_text: false, target: '#update-entreprise-modal', check_permission: () => this.$can('entreprises_update')  },
                    ] 
                },
            ],
            tableFilters: [
                { key: 'status', options: [{id: 'active', name: 'Activer'}, {id: 'inactive', name: 'Désactiver'}, {id: 'archive', name: 'Archiver'}], label: this.$t('general.status'), type: 'select', value: '' },
            ],
        };
    },
    async mounted() {
        this.isDispo = false
        await this.initEntreprises()
        this.isDispo = true
    },
    methods: {
        ...mapActions('entreprise', ['entrepriseList', 'addEntreprise', 'fetchEntreprise', 'updateEntreprise']),
        async initEntreprises(options = { search: '', filters: {}, perPage: 10, page: 1 }) {
            this.isLoading = true
            let response = await this.entrepriseList(options)
            this.entreprises = response.data || [];
            this.currentPage = response.current_page || 1;
            this.totalPage = response.total_pages || 1;
            this.isLoading = false
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        loadUpdateData(entreprise){
            this.entrepriseFormUpdateData = {
                id: entreprise.id,
                name: entreprise.name,
                address : entreprise.address,
                phone : entreprise.phone,
                email: entreprise.email,
            }
        },
        async addNewEntreprise(){
            try {
                this.isLoading = true
                await this.addEntreprise(this.entrepriseFormData)
                this.$successNotyf(this.$t('message.entreprise_success'))
                this.entrepriseFormData.name = null
                this.entrepriseFormData.address = null
                this.entrepriseFormData.email = null
                this.entrepriseFormData.phone = null
                jQuery('#new-entreprise-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
            this.isLoading = false
            this.initEntreprises()
        },
        async _updateEntreprise(){
            try {
                this.isLoading = true
                await this.updateEntreprise(this.entrepriseFormUpdateData)
                this.$successNotyf(this.$t('message.update_entreprise_success'))
                jQuery('#update-entreprise-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
            this.isLoading = false
            this.initEntreprises()
        },
        handleSuccess(message) {
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    