<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <h3 class="col-md-6">{{ $t('evaluation.resultat_evaluation') }} <span class="text-muted" v-if="evaluation_delail"> - {{ evaluation_delail.month_evaluation }}</span></h3>
                    <div v-if="evaluation_delail" class="col-md-6 d-flex align-items-center justify-content-end">
                        <button @click="closeEvaluationData" class="btn btn-outline-danger me-2">
                            <i class="fas fa-times"></i> {{  $t('evaluation.back') }}
                        </button>
                        <button @click="downloadPDF" class="btn btn-danger me-2">{{ $t('general.in_pdf') }}</button>
                    </div>
                </div>
            </div>
            <div v-if="listEvaluations.length">
                <div v-show="!evaluationContents.evaluation" class="row">
                    <div class="col-md-12" v-for="(my_evaluation, index) in listEvaluations" :key="index">
                        <div class="card">
                            <div class="card-body">
                                <div class="row  pt-0 pb-0 mt-0 mb-0">
                                    <div class="col-md-6">
                                        <h5 class="card-title">{{ my_evaluation.name }}</h5>
                                        <p class="card-text">{{ $t('evaluation.month_evaluation') }} {{ my_evaluation.month_evaluation }}</p>
                                        <p class="card-text"><span class="badge bg-info">{{ $t('evaluation.type') }} {{ my_evaluation.type?.toUpperCase() }}</span></p>
                                    </div>
                                    <div class="col-md-6 text-end">
                                        <button @click="changeEvaluationData(my_evaluation)" class="btn btn-primary">{{ $t('evaluation.voir') }}</button>
                                        <h5 class="card-title mt-2">{{ my_evaluation.user.name }}</h5>
                                        <p class="card-text">Envoyé: {{ formatCommentDate(my_evaluation.updated_at) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="evaluationContents.evaluation" class="row" id="element-to-print">
                    <MonthStat 
                        v-if="dataReport.length" 
                        :dataReport="dataReport"
                        :reportStartDate="reportStartDate"
                        :reportEndDate="reportEndDate"
                        :reportFrequency="reportFrequency"
                    />
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body m-sm-3 m-md-5">
                                    <div class="row mb-4">
                                        <div class="col-md-12">
                                            <strong>{{ evaluation_delail?.name }}</strong><br>
                                            <strong>{{ $t('evaluation.collaborateur') }} </strong> {{ evaluation_delail?.user.name }}
                                                <br>
                                            <em>{{ $t('evaluation.evaluation_collaborateur') }}</em>
                                        </div>
                                    </div>

                                    <!-- Partie à fermer ou ouvrir -->
                                    <div v-for="rubrique in collaboratorDatas" :key="rubrique.id" class="rubrique-section mb-4">
                                        <!-- Afficher la rubrique -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="text-primary">
                                                    <strong>{{ $t('evaluation.rubrique') }} : {{ rubrique.name }}</strong>
                                                </div>
                                                <hr class="mt-1 mb-2" />
                                            </div>
                                        </div>

                                        <!-- Afficher les questions/réponses -->
                                        <div class="row" v-for="template in rubrique.templates" :key="template.id">
                                            <div class="col-md-12 d-flex mb-3">
                                                <!-- Question -->
                                                <div class="col-md-6 text-muted">
                                                    <strong>[{{ $t('evaluation.question') }}]</strong> {{ template.question }}
                                                </div>
                                                <!-- Réponse et justification -->
                                                <div class="col-md-6 text-md-end">
                                                    <strong>[{{ $t('evaluation.reponse') }}]</strong> 
                                                    {{ template.evaluation_content?.reponse || $t('evaluation.no_response') }}
                                                    <div v-if="template.evaluation_content?.justification" class="text-muted mt-1">
                                                        <small>[{{ $t('evaluation.justification') }}]</small> {{ template.evaluation_content.justification }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body m-sm-3 m-md-5">
                                    <div class="row mb-4">
                                        <div class="col-md-12">
                                            <strong>{{ evaluation_delail?.name }}</strong><br>
                                            <strong>{{ $t('evaluation.collaborateur') }} </strong> {{ evaluation_delail?.user.name }} 
                                            <br><em>{{ $t('evaluation.evaluation_responsable') }}</em>
                                        </div>
                                    </div>

                                    <!-- Partie à fermer ou ouvrir -->
                                    <div v-for="rubrique in supervisorDatas" :key="rubrique.id" class="rubrique-section mb-4">
                                        <!-- Afficher la rubrique -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="text-primary">
                                                    <strong>{{ $t('evaluation.rubrique') }} : {{ rubrique.name }}</strong>
                                                </div>
                                                <hr class="mt-1 mb-2" />
                                            </div>
                                        </div>

                                        <!-- Afficher les questions/réponses -->
                                        <div class="row" v-for="template in rubrique.templates" :key="template.id">
                                            <div class="col-md-12 d-flex mb-3">
                                                <!-- Question -->
                                                <div class="col-md-6 text-muted">
                                                    <strong>[{{ $t('evaluation.question') }}]</strong> {{ template.question }}
                                                </div>
                                                <!-- Réponse et justification -->
                                                <div class="col-md-6 text-md-end">
                                                    <strong>[{{ $t('evaluation.reponse') }}]</strong> 
                                                    {{ template.evaluation_content?.reponse || $t('evaluation.no_response') }}
                                                    <div v-if="template.evaluation_content?.justification" class="text-muted mt-1">
                                                        <small>[{{ $t('evaluation.justification') }}]</small> {{ template.evaluation_content.justification }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('evaluation.not_evaluation_validate') }}</h4>
                <span>{{ $t('evaluation.aucune_evaluation') }}</span>
            </div>
        </div>
        <div v-else class="loader">
            <span>{{ $t('evaluation.chargement') }}</span>
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
        <Pagination v-if="isDispo && listEvaluations.length && !evaluationContents.evaluation"
            :currentPage="paginateOptions.current_page"
            :totalPages="paginateOptions.total_pages"
            :previousLabel="$t('pagination.previous')"
            :nextLabel="$t('pagination.next')"
            @page-changed="fetchPage"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import MonthStat from '@/components/General/user_month_stat.vue'
import Pagination from '@/components/General/Pagination.vue';
import html2pdf from 'html2pdf.js'
export default {
    name: 'MyCollaboratorPage',
    data() {
        return {
            isDispo: false,
            collaboratorDatas: [],
            supervisorDatas: [],
            isLoad: false,
            listEvaluations: [],
            evaluationContents: {
                evaluation: null,
                type: 'supervisor',
                contents: []
            },
            filterOptions: {
                managerId: null,
                page: 1,
                per_page: 10,
            },
            paginateOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 10,
            },
            evaluation_delail: null,
            open1: false,
            open2: false,
            dataReport: [],
            reportStartDate: null,
            reportEndDate: null,
            reportType: 'month',
        };
    },
    components: {
        MonthStat,
        Pagination
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initEvaluationsTemplate()
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('evaluation', [
            'setTemplateEvaluationStat',
            'getUsersEvaluations',
            'addEvaluationContent',
            'getManagerSubordinatesEvaluationContents',
            'getUserStaticMonth'
        ]),
        ...mapActions('auth', ['_currentUser']),
        downloadPDF(){
			var element = document.getElementById('element-to-print');
			html2pdf(element, {
				margin: 4, 
				filename: `${this.reportStartDate}_evaluation_report.pdf`,
				html2canvas: { scale: 2 }, // Augmente l'échelle pour une meilleure qualité
				jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' }, // Passe au format A3
			});
		},
        async changeEvaluationData(my_eval){
            this.isDispo = false
            this.evaluation_delail = my_eval
            let month_evaluation = my_eval.month_evaluation
            let user_id = this.getCurrentUser.id
            try {
                    let resp = await this.getUserStaticMonth({ id: user_id, date: month_evaluation })
                    this.dataReport = resp.data
                    this.reportFrequency = resp.frequency
                    this.reportStartDate = resp.start_date
                    this.reportEndDate = resp.end_date

                    let response = await this.getManagerSubordinatesEvaluationContents(my_eval.id)
                    this.supervisorDatas = response.supervisor_evaluation_templates
                    this.collaboratorDatas = response.collaborator_evaluation_templates
                    this.evaluationContents.evaluation = my_eval.id
                } catch (error) {
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.errorResponse(error ? error : {message: this.$t('success.f')+ ' ' +this.$t('success.g') })
                }
            this.isDispo = true
        },
        closeEvaluationData(){
            this.evaluationContents.evaluation = null
            this.evaluationContents.contents = []
            this.evaluation_delail = null
            this.dataReport = []
        },
        async fetchPage(page) {
            this.isDispo = false
            this.paginateOptions.current_page = page;
            this.filterOptions.page = page;
            await this.initEvaluationsTemplate(); 
        },
        async initEvaluationsTemplate() {
            if (this.getCurrentUser) {
                let output = await this.getUsersEvaluations(this.filterOptions)
                this.listEvaluations = output.evaluations
                this.paginateOptions = output.pagination || {
                    current_page: 1,
                    total_pages: 1,
                    total_items: 0,
                    per_page: 10,
                };
            }
            this.isDispo = true
        },
        formatCommentDate(date) {
            let formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
            return formattedDate.format('DD MMM YYYY [at] h:mm');
        },
        formatFormData(){
            for (const rubrique of this.supervisorDatas) {
                for (const template of rubrique.templates) {
                    const evaluationContent = {
                        template: template.id,
                        reponse: '',
                        justification: '',
                        type: 'supervisor'
                    };

                    if (template.type_field === 'input') {
                        evaluationContent.reponse = template.inputValue;
                    } else if (template.type_field === 'textarea') {
                        evaluationContent.reponse = template.textareaValue;
                    } else if (template.type_field === 'select') {
                        evaluationContent.reponse = template.selectValue;
                    } else if (template.type_field === 'radio') {
                        evaluationContent.reponse = template.radioValue;
                    }
                    if (template.justify) {
                        evaluationContent.justification = template.textareaValue;
                    }
                    this.evaluationContents.contents.push(evaluationContent);
                }
            }
            return this.evaluationContents
        },
        async submitForm() {
            let data = this.formatFormData()
            if(data){
                try {
                    await this.addEvaluationContent(data)
                    await this.initEvaluationsTemplate()
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.successResponse(this.$t('success.e'))
                } catch (error) {
                    this.errorResponse(error) 
                }
            }else{
                this.errorResponse({message: this.$t('success.f')+ ' ' +this.$t('success.g') })
            }
        },
        errorResponse(error){
            this.$errorNotyf(error)
        },
        successResponse(message){
            this.$successNotyf(message)
        },
    },
}
</script>