<template>
    <div>
        <div class="container-fluid p-0" v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <div class="col-auto d-none d-sm-block">
                        <h3><strong>{{ $t('params.parameter') }}</strong> - <span v-show="currentPage == 1"
                                class="text-secondary">{{ $t('params.system') }}</span><span v-show="currentPage == 2"
                                class="text-secondary">{{ $t('params.evaluation_report') }}</span></h3>
                    </div>
                    <div class="col-auto ms-auto text-end mt-n1">
                        <div class="btn-group" role="group" aria-label="Navigation">
                            <button type="button" :class="1 == currentPage ? 'active btn-primary' : 'btn-secondary'"
                                class="btn" @click="changePage(1)">{{ $t('params.system') }}</button>
                            <button type="button" :class="2 == currentPage ? 'active btn-primary' : 'btn-secondary'"
                                class="btn" @click="changePage(2)">{{ $t('params.evaluation_report') }}</button>
                        </div>
                        <!-- <div>
                        <router-link class="btn btn-outline-secondary" :to="{ name: 'audittrail'}">
                            <i class="align-middle me-1" data-feather="user"></i> Audit Trails
                        </router-link>
                    </div> -->
                    </div>
                </div>
            </div>
            <div v-show="currentPage == 1">
                <div class="row">
                    <div class="col-md-3 col-xl-2">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{ $t('params.system') }}</h5>
                            </div>
    
                            <div class="list-group list-group-flush" role="tablist">
                                <a class="list-group-item list-group-item-action active" data-bs-toggle="list" href="#general"
                                    role="tab">
                                    {{ $t('params.general') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list"
                                    href="#project" role="tab">
                                    {{ $t('params.projects') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#task"
                                    role="tab">
                                    {{ $t('params.tasks') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#ticket"
                                    role="tab">
                                    {{ $t('params.tickets') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#document"
                                    role="tab">
                                    {{ $t('params.documents') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#comment"
                                    role="tab">
                                    {{ $t('params.comments') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#performance"
                                    role="tab">
                                    {{ $t('params.performances') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#security"
                                    role="tab">
                                    {{ $t('params.security') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#cron"
                                    role="tab">
                                    {{ $t('params.cron') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 col-xl-10">
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="general" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.general_parameter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.general" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="project" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.project_parameter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.project" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input @click="setcheckedParams(param.id)"
                                                                class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="task" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.task_parameter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.task" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="ticket" role="tabpanel">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-title">{{ $t('params.ticket_parameter') }}</h5>
                                    </div>
                                    <div class="card-body">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.ticket" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                        <h5 class="card-title">{{ $t('params.criticity_parameter') }}</h5>
    
                                        <div v-if="!showForm">
                                            <div v-for="crit in criticalities" :key="crit.id" class="mb-3">
                                                <strong>{{ $t('params.' + crit.name) }} : </strong> {{ crit.max_resolution_time }} days
                                            </div>
                                            <button @click="showForm = true" class="btn btn-primary">Modify</button>
                                        </div>
                                        <form v-else @submit.prevent="saveSettings">
                                            <div v-for="crit in criticalities" :key="crit.id" class="mb-3">
                                                <label :for="'crit-' + crit.id" class="form-label">{{ $t('params.' + crit.name) }}</label>
                                                <input :id="'crit-' + crit.id" v-model="crit.max_resolution_time"
                                                    type="number" class="form-control" min="1" required>
                                            </div>
                                            <CustomButton
                                                buttonType="button"
                                                buttonClass="btn-primary"
                                                buttonSubmitType="submit"
                                                :buttonText="$t('params.save')"
                                                :isLoading="isLoad"
                                                :loadingText="$t('general.loading') + '...'"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="tab-pane fade" id="document" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.document_parameter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.document" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="comment" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.comment_parameter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.comment" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="performance" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between mb-3">
                                            <div class="flex-grow-1 me-6">
                                                <h5 class="card-title">{{ $t('params.performance_parameter') }}</h5>
                                            </div>
                                        </div>
    
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.performance" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="security" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{ $t('params.security_paramter') }}</h5>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.security" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'simple')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="cron" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between mb-3">
                                            <div class="flex-grow-1 me-6">
                                                <h5 class="card-title">{{ $t('params.cron_parameter') }}</h5>
                                            </div>
                                        </div>
    
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.checked') }}</th>
                                                    <th>{{ $t('params.frequence') }}</th>
                                                    <th>{{ $t('params.hour') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(param, index) in params.cron" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + param.param_key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input class="form-check-input" type="checkbox"
                                                                :value="param.checked" v-model="param.checked"
                                                                @click="setcheckedParams(param.id)">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select class="form-select" aria-label="Frequency"
                                                            :value="param.frequency" v-model="param.frequency">
                                                            <option v-for="(freq, freqIndex) in FREQUENCE_CHOICES"
                                                                :key="freqIndex" :value="freq[0]">{{ freq[1] }}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <input type="time" class="form-control" placeholder="Heure"
                                                                aria-label="Heure" v-model="param.time">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <a href="#" data-bs-toggle="modal"
                                                                data-bs-target="#params-update-modal"
                                                                @click="initFormData(param.id, 'cron')"><svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="currentPage == 2">
                <div class="row">
                    <div class="col-md-3 col-xl-2">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{ $t('params.evaluation') }}</h5>
                            </div>
    
                            <div class="list-group list-group-flush" role="tablist">
                                <a class="list-group-item list-group-item-action active" data-bs-toggle="list"
                                    href="#nouvelle" role="tab">
                                    {{ $t('params.new') }}
                                </a>
                                <a v-show="collaboratorDatas.length" class="list-group-item list-group-item-action"
                                    data-bs-toggle="list" href="#collaborateur" role="tab">
                                    {{ $t('params.collaborator') }}
                                </a>
                                <a v-show="supervisorDatas.length" class="list-group-item list-group-item-action"
                                    data-bs-toggle="list" href="#superviseur" role="tab">
                                    {{ $t('params.supervisor') }}
                                </a>
                                <a class="list-group-item list-group-item-action" data-bs-toggle="list"
                                    href="#configurations" role="tab">
                                    {{ $t('params.configurations') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 col-xl-10">
                        <div class="tab-content">
                            <div class="tab-pane fade" id="collaborateur" role="tabpanel">
                                <div v-for="(rebriqueData, rebriqueIndex) in collaboratorDatas" :key="rebriqueIndex"
                                    class="card mb-3">
                                    <div class="chat-messages card-body">
                                        <h5 class="card-title">{{ 'Rebrique ' + (rebriqueIndex + 1) }}</h5>
                                        <div class="row">
                                            <div class="col-sm-11">
                                                <input v-model="rebriqueData.name" type="text" class="form-control"
                                                    placeholder="Nom de la rebrique" required />
                                            </div>
                                            <div class="col-sm-1">
                                                <div class="text-end">
                                                    <button @click="editRebrique(rebriqueData)" type="button"
                                                        class="btn btn-secondary" title="Modifier la rebrique">
                                                        <i class="align-middle fas fa-fw fa-pencil"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(template, templateIndex) in rebriqueData.templates"
                                            :key="templateIndex" class="mb-3">
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-sm-6">
                                                    <input v-model="template.question" type="text" class="form-control"
                                                        placeholder="Question" required />
                                                </div>
                                                <div class="col-sm-3">
                                                    <select v-model="template.using_by" class="form-select" required>
                                                        <option value="sup_and_col">{{ $t('params.supervisor_collaborator') }}</option>
                                                        <option value="collaborator">{{ $t('params.collaborator_only') }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-3">
                                                    <select v-model="template.type_field" class="form-select" required>
                                                        <option value="input">{{ $t('new.b') }}</option>
                                                        <option value="textarea">{{ $t('new.c') }}</option>
                                                        <option value="select">{{ $t('new.d') }}</option>
                                                        <option value="radio">{{ $t('new.e') }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="input-group">
                                                        <input v-model="template.data" type="text" class="form-control"
                                                            :placeholder="$t('params.placeholder_instruction_field1')" />
                                                        <span class="input-group-text" data-bs-toggle="popover"
                                                            data-bs-placement="top"
                                                            :data-bs-content="$t('params.instruction_field1')">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="input-group">
                                                        <textarea v-model="template.notes" class="form-control" rows="1"
                                                            :placeholder="$t('params.placeholder_instruction_field2')"></textarea>
                                                        <span class="input-group-text" data-bs-toggle="popover"
                                                            data-bs-placement="top"
                                                            :data-bs-content="$t('params.instruction_field2')">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="form-check form-switch mt-1">
                                                        <input class="form-check-input" :title="$t('params.state_title')" type="checkbox"
                                                            v-model="template.is_active" id="flexSwitchCheckChecked"
                                                            @click="setStatus(template.id)">
                                                        <label class="form-check-label"
                                                            for="flexSwitchCheckChecked">{{ $t('params.state') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-check form-switch mt-1">
                                                        <input class="form-check-input" :title="$t('params.state_title')" type="checkbox"
                                                            v-model="template.justify" id="flexSwitchCheckChecked"
                                                            @click="setJustity(template.id)">
                                                        <label class="form-check-label"
                                                            for="flexSwitchCheckChecked">{{ $t('params.justify') }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-start">
                                            <button @click="addEditTemplateCol(rebriqueIndex)" type="button"
                                                class="btn btn-primary">{{ $t('params.add_template') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="superviseur" role="tabpanel">
                                <div v-for="(rebriqueData, rebriqueIndex) in supervisorDatas" :key="rebriqueIndex"
                                    class="card mb-3">
                                    <div class="chat-messages card-body">
                                        <h5 class="card-title">{{ 'Rebrique ' + (rebriqueIndex + 1) }}</h5>
                                        <div class="row">
                                            <div class="col-sm-11">
                                                <input v-model="rebriqueData.name" type="text" class="form-control"
                                                    :placeholder="$t('params.rebrique_name')" required />
                                            </div>
                                            <div class="col-sm-1">
                                                <div class="text-end">
                                                    <button @click="editRebrique(rebriqueData)" type="button"
                                                        class="btn btn-secondary" :title="$t('params.update_rebrique')">
                                                        <i class="align-middle fas fa-fw fa-pencil"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(template, templateIndex) in rebriqueData.templates"
                                            :key="templateIndex" class="mb-3">
                                            <hr>
                                            <div class="row g-3">
                                                <div class="col-sm-6">
                                                    <input v-model="template.question" type="text" class="form-control"
                                                        placeholder="Question" required />
                                                </div>
                                                <div class="col-sm-3">
                                                    <select v-model="template.using_by" class="form-select" required>
                                                        <option value="sup_and_col">{{ $t('params.supervisor_collaborator') }}</option>
                                                        <option value="supervisor">{{ $t('params.collaborator_only') }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-3">
                                                    <select v-model="template.type_field" class="form-select" required>
                                                        <option value="input">{{ $t('new.b') }}</option>
                                                        <option value="textarea">{{ $t('new.c') }}</option>
                                                        <option value="select">{{ $t('new.d') }}</option>
                                                        <option value="radio">{{ $t('new.e') }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="input-group">
                                                        <input v-model="template.data" type="text" class="form-control"
                                                            :placeholder="$t('params.placeholder_instruction_field1')" />
                                                        <span class="input-group-text" data-bs-toggle="popover"
                                                            data-bs-placement="top"
                                                            :data-bs-content="$t('params.instruction_field1')">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="input-group">
                                                        <textarea v-model="template.notes" class="form-control" rows="1"
                                                            :placeholder="$t('params.placeholder_instruction_field2')"></textarea>
                                                        <span class="input-group-text" data-bs-toggle="popover"
                                                            data-bs-placement="top"
                                                            :data-bs-content="$t('params.instruction_field2')">
                                                            <i class="fas fa-exclamation-circle"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="form-check form-switch mt-1">
                                                        <input class="form-check-input" :title="$t('params.state')" type="checkbox"
                                                            v-model="template.is_active" id="flexSwitchCheckChecked"
                                                            @click="setStatus(template.id)">
                                                        <label class="form-check-label"
                                                            for="flexSwitchCheckChecked">{{ $t('params.state') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1">
                                                    <div class="form-check form-switch mt-2">
                                                        <input class="form-check-input" :title="$t('params.state')" type="checkbox"
                                                            v-model="template.justify" id="flexSwitchCheckChecked"
                                                            @click="setJustity(template.id)">
                                                        <label class="form-check-label"
                                                            for="flexSwitchCheckChecked">{{ $t('params.justify') }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-start">
                                            <button @click="addEditTemplateSup(rebriqueIndex)" type="button"
                                                class="btn btn-primary">{{ $t('params.add_template') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="tab-pane fade show active" id="nouvelle" role="tabpanel">
                                <div class="container">
                                    <h3>{{ $t('params.add_rebriques_and_questions') }}</h3>
                                    <hr>
                                    <form @submit.prevent="submitForm">
                                        <div v-for="(rebrique, rebriqueIndex) in rebriqueFormData" :key="rebriqueIndex"
                                            class="card mb-3">
                                            <div class="chat-messages card-body">
                                                <h5 class="card-title">Rebrique {{ rebriqueIndex + 1 }}</h5>
                                                <div class="row">
                                                    <div class="col-sm-11">
                                                        <input v-model="rebrique.name" type="text" class="form-control"
                                                            :placeholder="$t('params.rebrique_name')" required />
                                                    </div>
                                                    <div class="col-sm-1">
                                                        <div class="text-end">
                                                            <button @click="removeRebrique(rebriqueIndex)" type="button"
                                                                class="btn btn-secondary" :title="$t('params.delete_rebrique')">
                                                                <i class="align-middle fas fa-fw fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-for="(template, templateIndex) in rebrique.templates"
                                                    :key="templateIndex" class="mb-3">
                                                    <hr>
                                                    <div class="row g-3">
                                                        <div class="col-sm-6">
                                                            <input v-model="template.question" type="text"
                                                                class="form-control" placeholder="Question" required />
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <select v-model="template.using_by" class="form-select"
                                                                required>
                                                                <option value="sup_and_col">{{ $t('params.supervisor_collaborator') }}
                                                                </option>
                                                                <option value="supervisor">{{ $t('params.supervisor_only') }}</option>
                                                                <option value="collaborator">{{ $t('params.collaborator_only') }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <select v-model="template.type_field" class="form-select"
                                                                required>
                                                                <option value="input">{{ $t('new.b') }}</option>
                                                                <option value="textarea">{{ $t('new.c') }}</option>
                                                                <option value="select">{{ $t('new.d') }}</option>
                                                                <option value="radio">{{ $t('new.e') }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="input-group">
                                                                <input v-model="template.data" type="text"
                                                                    class="form-control" :placeholder="$t('params.placeholder_instruction_field1')" />
                                                                <span class="input-group-text" data-bs-toggle="popover"
                                                                    data-bs-placement="top"
                                                                    :aria-autocomplete="$t('params.instruction_field1')">
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-5">
                                                            <div class="input-group">
                                                                <textarea v-model="template.notes" class="form-control"
                                                                    rows="1" :placeholder="$t('params.placeholder_instruction_field1')"></textarea>
                                                                <span class="input-group-text" data-bs-toggle="popover"
                                                                    data-bs-placement="top"
                                                                    :data-bs-content="$t('params.instruction_field2')">
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-1">
                                                            <div class="form-check form-switch mt-1">
                                                                <input class="form-check-input" :title="$t('params.state_title')"
                                                                    type="checkbox" v-model="template.justify"
                                                                    id="flexSwitchCheckChecked"
                                                                    @click="setStatus(template.id)">
                                                                <label class="form-check-label"
                                                                    for="flexSwitchCheckChecked">{{ $t('params.justify') }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <div class="text-end">
                                                                <button
                                                                    @click="removeTemplate(rebriqueIndex, templateIndex)"
                                                                    type="button" class="btn btn-outline-danger"
                                                                    :title="$t('params.delete_template')">
                                                                    <i class="align-middle fas fa-fw fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-start">
                                                    <button @click="addTemplate(rebriqueIndex)" type="button"
                                                        class="btn btn-primary">{{ $t('params.add_template') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-end">
                                            <CustomButton
                                                buttonType="button"
                                                buttonClass="btn-danger"
                                                :buttonText="$t('params.add_rebrique')"
                                                :isLoading="false"
                                                :loadingText="$t('general.loading') + '...'"
                                                @click="addLigneRebrique"
                                                class="me-2"
                                            />
                                            
                                            <CustomButton
                                                buttonType="button"
                                                buttonClass="btn-success"
                                                buttonSubmitType="submit"
                                                :buttonText="$t('params.submit')"
                                                :isLoading="isLoad"
                                                :loadingText="$t('general.loading') + '...'"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
    
                            <div class="tab-pane fade" id="configurations" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between mb-3">
                                            <div class="flex-grow-1 me-6">
                                                <h5 class="card-title">{{ $t('params.evaluation_parameter') }}</h5>
                                            </div>
                                        </div>
    
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('params.parameter') }}</th>
                                                    <th>{{ $t('params.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in updateEvaluationConfigFormData" :key="index">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <label class="form-check-label">
                                                                {{ $t('params.' + data.key) }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <input :type="data.type" v-if="data.type != 'select'"
                                                                class="form-control" placeholder="Nombre"
                                                                aria-label="Nombre" v-model="data.value">
    
                                                            <template v-else>
                                                                <select class="form-select"
                                                                    v-if="data.key == 'evaluation_frequency'"
                                                                    v-model="data.value">
                                                                    <option value="Days">{{ $t('params.days') }}</option>
                                                                    <option value="Week">{{ $t('params.weeks') }}</option>
                                                                    <option value="Month">{{ $t('params.months') }}</option>
                                                                    <option value="Quarter">{{ $t('params.quarter') }}</option>
                                                                    <option value="Semester">{{ $t('params.semester') }}</option>
                                                                    <option value="Year">{{ $t('params.years') }}</option>
                                                                </select>
    
                                                                <select class="form-select w-full"
                                                                    v-if="data.key == 'evaluation_week_frequency_day'"
                                                                    v-model="data.value">
                                                                    <option value="1">{{ $t('params.monday') }}</option>
                                                                    <option value="2">{{ $t('params.tuesday') }}</option>
                                                                    <option value="3">{{ $t('params.wednesday') }}</option>
                                                                    <option value="4">{{ $t('params.thursday') }}</option>
                                                                    <option value="5">{{ $t('params.friday') }}</option>
                                                                    <option value="6">{{ $t('params.saturday') }}</option>
                                                                    <option value="7">{{ $t('params.sunday') }}</option>
                                                                </select>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="text-end">
                                            <button @click="_updateEvaluationConfig" type="button" class="btn btn-primary">
                                                <i class="align-middle fas fa-fw fa-save"></i>
                                                {{ $t('params.save') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="params-update-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('params.update_parameter') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form v-if="type == 'cron'" @submit.prevent="updateParamter" class="row g-3 needs-validation">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="validationCustom01" class="form-label">{{ $t('params.notification_title') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="text" v-model="updateCronFormData.notification_title"
                                            class="form-control" id="validationCustom01" :placeholder="$t('params.name')" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="validationCustom02" class="form-label">{{ $t('params.notification_content') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <textarea class="form-control" :name="$t('params.description')" rows="5"
                                            v-model="updateCronFormData.notification_content" id="validationCustom07"
                                            :placeholder="$t('params.enter_description')" required></textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">{{ $t('params.frequence') }} <i

                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" aria-label="Frequency" :value="updateCronFormData.frequency"
                                        v-model="updateCronFormData.frequency">
                                        <option v-for="(freq, freqIndex) in FREQUENCE_CHOICES" :key="freqIndex"
                                            :value="freq[0]">{{ freq[1] }}</option>
                                    </select>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">{{ $t('params.time') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="time" class="form-control" :placeholder="$t('params.hour')" aria-label="Heure"
                                        v-model="updateCronFormData.time">
                                </div>
                                <div class="col-12 text-end">
                                    <CustomButton
                                        buttonType="button"
                                        buttonClass="btn-primary"
                                        buttonSubmitType="submit"
                                        :buttonText="$t('params.save')"
                                        :isLoading="isLoad"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                            <form v-else @submit.prevent="updateParamter" class="row g-3 needs-validation">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="validationCustom01" class="form-label">{{ $t('params.notification_title') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="text" v-model="updateFormData.notification_title" class="form-control"
                                            id="validationCustom01" :placeholder="$t('params.name')" required>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="validationCustom02" class="form-label">{{ $t('params.notification_content') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <textarea class="form-control" name="$t('params.description')" rows="5"
                                            v-model="updateFormData.notification_content" id="validationCustom07"
                                            :placeholder="$t('params.enter_description')" required></textarea>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <CustomButton
                                        buttonType="button"
                                        buttonClass="btn-primary"
                                        buttonSubmitType="submit"
                                        :buttonText="$t('params.save')"
                                        :isLoading="isLoad"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
const userHostname = window.location;
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'SettingsPage',
    components: {
        EmptyPageComponent,
        CustomButton
    },
    data() {
        return {
            params: [],
            showForm: false,
            criticalities: [],
            FREQUENCE_CHOICES: [
                ['Days', 'Days'],
                ['Week', 'Week'],
                ['Month', 'Month'],
                ['Quarter', 'Quarter'],
                ['Semester', 'Semester'],
                ['Year', 'Year'],
            ],
            isLoad: false,
            isDispo: false,
            type: 'sipmle',
            updateFormData: {
                id: null,
                notification_title: '',
                notification_content: ''
            },
            updateCronFormData: {
                id: null,
                notification_title: '',
                notification_content: '',
                frequency: '',
                time: ''
            },
            updateEvaluationConfigFormData: [],
            collaboratorDatas: [],
            supervisorDatas: [],
            currentPage: 1,
            templates: [],
            rebriqueFormData: [
                {
                    name: 'General',
                    templates: [
                        {
                            using_by: 'sup_and_col',
                            question: null,
                            data: null,
                            notes: null,
                            justify: false,
                            type_field: 'input'
                        }
                    ]
                }
            ]
        };
    },
    async mounted() {
        this.isDispo = false
        await this.initAppParams()
        await this.initEvaluationsTemplate()
        this.criticalities = await this.getCriticalities()
        // Enable Bootstrap Popover
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
        this.isDispo = true
    },
    methods: {
        ...mapActions('auth', ['getCriticalities', 'updateCriticalitySettings']),
        ...mapActions('parameter', ['getAppParams', 'checkedParams', 'updateParamsContent', 'setCronParams', 'getParam']),
        ...mapActions('evaluation', ['addRebrique', 'getEvaluationsTemplateByUsingByRebrique', 'updateRebrique', 'setTemplateEvaluationStat', 'setTemplateEvaluationJustify', 'updateEvaluationConfig']),
        async initAppParams() {
            let response = await this.getAppParams()
            this.params = response
            if(this.params?.evaluation){
                this.updateEvaluationConfigFormData = (this.params?.evaluation.map((item) => {
                    return {
                        id: item.id,
                        key: item.param_key, 
                        title: item.notification_title,
                        value: item.type_filed == 'number' ? parseInt(item.notification_content) : item.notification_content,
                        type: item.type_filed
                    }
                }))
            }
        },
        async _updateEvaluationConfig(){
            try {
                await this.updateEvaluationConfig(this.updateEvaluationConfigFormData)
                this.$successNotyf(this.$t('params.evaluation_configrate_successfully'));
            } catch (error) {
                this.$errorNotyf(this.$t('params.evaluation_configrate_error'));
            }
        },
        changePage(number) {
            this.currentPage = number
        },
        async initEvaluationsTemplate() {
            let response = await this.getEvaluationsTemplateByUsingByRebrique()
            this.supervisorDatas = response.supervisor_evaluation_templates
            this.collaboratorDatas = response.collaborator_evaluation_templates
        },
        async saveSettings() {
            this.isLoad = true
            try {
                await this.updateCriticalitySettings(this.criticalities);
                this.$successNotyf(this.$t('params.criticality_configrate_successfully'));
                this.showForm = false;
                this.isLoad = false
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(this.$t('params.criticality_configrate_error'));
            }
        },
        addLigneRebrique() {
            this.rebriqueFormData.push({
                name: 'General',
                templates: [
                    {
                        using_by: 'sup_and_col',
                        question: null,
                        data: null,
                        notes: null,
                        justify: false,
                        type_field: 'input',
                    },
                ],
            });
        },
        setStatus(id){
            this.isLoad = true
            try {
                if(id) this.setTemplateEvaluationStat(id)
                this.successResponse(this.$t('params.question_state_set_successful'))
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        setJustity(id){
            this.isLoad = true
            try {
                if(id) this.setTemplateEvaluationJustify(id)
                this.successResponse(this.$t('params.question_justification_set_successful'))
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        async editRebrique(rebriqueData){
            this.isLoad = true
            try {
                await this.updateRebrique(rebriqueData)
                this.successResponse(this.$t('params.rebrique_and_question_edit_successful'))
            } catch (error) {
                this.isLoad = false
                this.errorResponse(error)
            }
            this.isLoad = false
        },
        errorResponse(error){
            this.$errorNotyf(error)
        },
        successResponse(message){
            this.$successNotyf(message)
        },
        addTemplate(rebriqueIndex) {
            this.rebriqueFormData[rebriqueIndex].templates.push(this.formatData('sup_and_col'));
        },
        addEditTemplateCol(rebriqueIndex) {
            this.collaboratorDatas[rebriqueIndex].templates.push(this.formatData('collaborator'));
        },
        addEditTemplateSup(rebriqueIndex) {
            this.supervisorDatas[rebriqueIndex].templates.push(this.formatData('supervisor'));
        },
        formatData(default_type){
            return {
                id: null,
                using_by: default_type,
                question: null,
                data: null,
                is_active: true,
                justify: false,
                notes: null,
                type_field: 'input',
            }
        },
        removeTemplate(rebriqueIndex, templateIndex) {
            if (this.rebriqueFormData[rebriqueIndex].templates.length > 1) {
                this.rebriqueFormData[rebriqueIndex].templates.splice(templateIndex, 1);
            }
        },
        removeRebrique(rebriqueIndex) {
            if (this.rebriqueFormData.length > 1) {
                this.rebriqueFormData.splice(rebriqueIndex, 1);
            }
        },
        async submitForm() {
            this.isLoad = true
            try {
                await this.addRebrique(this.rebriqueFormData)
                this.$successNotyf(this.$t('params.rebrique_and_question_add_successful'))
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
        async initFormData(id, type) {
            this.type = type
            let response = await this.getParam(id)
            this.updateFormData.id = this.updateCronFormData.id = response.id
            this.updateFormData.notification_title = this.updateCronFormData.notification_title = response.notification_title
            this.updateFormData.notification_content = this.updateCronFormData.notification_content = response.notification_content
            if (this.type == 'cron') {
                this.updateCronFormData.frequency = response.frequency
                this.updateCronFormData.time = response.time
            }
        },
        async setcheckedParams(id) {
            this.isLoad = true
            try {
                await this.checkedParams(id)
                this.$successNotyf(this.$t('params.parameter_set_successful'))
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
        async updateParamter() {
            this.isLoad = true
            try {
                if (this.type == 'simple') {
                    await this.updateParamsContent(this.updateFormData)
                } else {
                    await this.setCronParams(this.updateCronFormData)
                    this.updateCronFormData.frequency = ''
                    this.updateCronFormData.time = ''
                }
                await this.initAppParams()
                this.updateFormData.id = this.updateCronFormData.id = null
                this.updateFormData.notification_title = this.updateCronFormData.notification_title = ''
                this.updateFormData.notification_content = this.updateCronFormData.notification_content = ''
                this.$successNotyf(this.$t('params.parameter_update_successful'))
                this.$closeModal("params-update-modal")
            } catch (error) {
                this.isLoad = false
                this.$errorNotyf(error)
            }
            this.isLoad = false
        },
    }
}
</script>