<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.users.user_type') }}</strong></h3>
                </div>
            </div>
            <div class="row card">
                <div class="card-body">
                    <DatatableComponent 
                        :headers="tableHeaders" 
                        :data="userTypes" 
                        :paginable="true" 
                        :currentPage="currentPage"
                        :totalPage="totalPage"
                        :isSearchable="false"
                        :filters="tableFilters"
                        :reloadFunction="initPage"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>
        <div v-show="!isDispo">
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import DatatableComponent from '@/components/General/DatatableComponent.vue';
export default {
    name: 'usersComponent',
    components: {
        EmptyPageComponent,
        DatatableComponent
    },
    data() {
        return {
            userTypes: [],
            isDispo: false,
            isLoading: false,
            currentPage: 1,
            totalPage: 1,
            tableHeaders: [
                { name: this.$t('page.users.user_type'), key: 'user_type', type: 'key' },
                { name: this.$t('page.users.number'), key: 'count', type: 'key' },
                { name: this.$t('departments.created_at'), key: 'created_at', type: 'key', format: this.$formatDate },
            ],
            tableFilters: [],
        };
    },
    async mounted() {
        this.isDispo = false
        await this.initPage()
        this.isDispo = true
    },
    methods: {
        ...mapActions('user_type', ['userTypeList', 'addUserType']),
        async initPage(options = { search: '', filters: {}, perPage: 10, page: 1 }) {       
            this.isLoading = true     
            try {
                let response = await this.userTypeList(options)
                this.userTypes = response.data || [];
                this.currentPage = response.current_page || 1;
                this.totalPage = response.total_pages || 1;
                this.isLoading = false   
            } catch (error) {
                this.isLoading = false
                this.this.$errorNotyf(error)
            }
        },
        handleSuccess(message){
            return this.$successNotyf(message)
        },
    },
}
</script>
<style></style>
    