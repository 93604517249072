import axiosWithHeaders from '../services/api';

const state = {
  projects: [],
  projectDetails: null,
};

const getters = {
  getProjects: (state) => state.projects,
  getProjectDetails: (state) => state.projectDetails,
};

const actions = {
  createProject({ commit }, projectData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('create-project', projectData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjects({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('projects')
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  projects_reports({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('projects-reports')
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  projectByParents({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('project-by-parents')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`project/${projectId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`project-delete/${projectId}`)
        .then(response => {
          resolve(response.data); // Renvoie le message de suppression
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },  

  projectProgress({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`project-progress/${projectId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectDetails({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`project/details/${projectId}`)
        .then(response => {
          commit('setProjectDetails', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateProject({ commit }, projectData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-project/${projectData.project}`, projectData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateProjectState({ commit }, { projectId, newState }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.patch(`project/${projectId}/state/${newState}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateProjectStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`project/${data.project_id}/status/${data.new_status}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectFromAServices({ commit }, serviceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`projects/service/${serviceId}`)
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectFromAParteners({ commit }, partenerId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`projects/partener/${partenerId}`)
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectFromAManagers({ commit }, managerId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`projects/manager/${managerId}`)
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  listDocuments({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`list-doc/project/${projectId}`)
        .then(response => {
          commit('setProjects', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectListUsers({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`project-list-users/${projectId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updatePartnerState({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-state-project/${data.project_id}/user/${data.user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateOpenOrCloseDate({ commit }, { projectId, dateType, newDate }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-project/open-close/${projectId}`, { dateType: dateType, newDate: newDate })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateStartOrEndDate({ commit }, { projectId, dateType, newDate }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-project/start-end/${projectId}`, { dateType: dateType, newDate: newDate })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getProjectDuration({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`project/duration/${projectId}`)
        .then(response => {
          commit('setProject', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addDocToProject({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`add-file/project/${formData.get('project')}/service_doc/${formData.get('service_doc')}/partener/${formData.get('partener')}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  setDocDeadline({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`document-define-deadline/${formData.id}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getProjectReports({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-project-report-docs/${projectId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  addProjectReport({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`add-project-report-doc/${formData.get('project')}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  updateProjectReportStatus({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-project/${formData.id}/report-doc-type/${formData.type}/status`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateDocToProject({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`update-file/project/${formData.get('project')}/service_doc/${formData.get('service_doc')}/partener/${formData.get('partener')}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  updateStateDoc({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-doc-state/project/${formData.project}/service_doc/${formData.service_doc}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

const mutations = {
  setProjects(state, projects) {
    state.projects = projects;
  },

  setProjectDetails(state, projectDetails) {
    state.projectDetails = projectDetails;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
