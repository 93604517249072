import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {

  addGrade({ commit, dispatch, state }, gradeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-grade', gradeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateGrade({ commit, dispatch, state }, gradeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-grade/' + gradeData.id, gradeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  grades({ commit, dispatch, state }, payload = {}) {
    return new Promise((resolve, reject) => {
      // Déstructuration sécurisée des paramètres avec des valeurs par défaut
      const {
        search = '',
        filters = {},
        perPage = 10,
        page = 1
      } = payload;

      const params = {
        search,          // Recherche
        ...filters,      // Filtres supplémentaires
        per_page: perPage, // Nombre d'éléments par page
        page            // Page actuelle
      };

      // Envoyer la requête avec les paramètres
      axiosWithHeaders.get('grades', { params })
        .then(response => {
          resolve(response.data); // Retourner les données
        })
        .catch(error => {
          reject(error.response?.data || error); // Gérer les erreurs
        });
    });
  },

  allGrades({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('all/grades')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  grade({ commit, dispatch, state }, gradeId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('grade/' + gradeId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
