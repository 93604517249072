<template>
    <div class="container-fluid p-0">
        <div>
            <form @submit.prevent="saveServiceTask" class="needs-validation">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title mb-0 pb-0">
                            <div class="flex-grow-1">
                                <strong>{{ $t('forms.new_service_task') }}</strong>
                            </div>
                            <div class="col-auto ms-auto text-end mt-n4">
                                <CustomButton
                                    buttonType="button"
                                    buttonSubmitType="submit"
                                    buttonClass="btn-primary"
                                    :buttonText="$t('general.save')"
                                    :isLoading="isStart"
                                    :loadingText="$t('general.loading') + '...'"
                                    class="me-2"
                                />
                                <button @click="goBack" class="btn btn-secondary" title="Back"><i class="align-middle fas fa-fw fa-arrow-left"></i></button>
                            </div>
                        </h5>
                    </div>
                    <div class="card-body h-100">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="validationCustom01" class="form-label">{{ $t('forms.service_task_id') }}</label>
                                <input type="text" class="form-control" :value="'AB00' + my_service.id" disabled>
                            </div>
                            <div class="col-md-6">
                                <label for="validationCustom01" class="form-label">{{ $t('forms.service_name') }}</label>
                                <input type="text" class="form-control" :value="my_service.name" disabled>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" v-for="(serviceTask, key) in serviceTaskFormData" :key="key">
                    <div class="card-body">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="validationCustom01" class="form-label">{{ $t('forms.task_name') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <input type="text" class="form-control" v-model="serviceTask.name" id="validationCustom01"
                                    :placeholder="$t('forms.task_name')" required>
                                <div class="invalid-feedback">
                                    {{ $t('forms.task_name_description') }}
                                </div>
                            </div>
                            <div class="col-md-5">
                                <label for="validationCustom02" class="form-label">{{ $t('general.severity') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <select class="form-select" v-model="serviceTask.severity" id="validationCustom02" required>
                                    <option selected disabled value="">{{ $t('general.choice') }}</option>
                                    <option value="Low">{{ $t('general.low') }}</option>
                                    <option value="Medium">{{ $t('general.medium') }}</option>
                                    <option value="High">{{ $t('general.high') }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    {{ $t('forms.severity_description') }}
                                </div>
                            </div>
                            <div class="col-md-1 pt-4">
                                <span @click="dropStoreLine(key)" class="btn btn-sm btn-danger"><i
                                        class="align-middle fas fa-fw fa-trash"></i></span>
                            </div>
                            <div class="col-md-6">
                                <label for="validationCustom03" class="form-label">{{ $t('forms.min_et') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <div class="row">
                                    <div class="col-11"><input type="number" v-model="serviceTask.minimal_time" min="1"
                                            class="form-control" id="validationCustom03" required></div>
                                    <div class="col-1 p-0 m-0 mt-1"><i data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="{{ $t('forms.min_et_description') }}"
                                            class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="validationCustom04" class="form-label">{{ $t('forms.max_et') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <div class="row">
                                    <div class="col-11"><input type="number" v-model="serviceTask.maximal_time" min="1"
                                            class="form-control" id="validationCustom04" required></div>
                                    <div class="col-1 p-0 m-0 mt-1"><i data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="{{ $t('forms.max_et_description') }}"
                                            class="align-middle me-2 fas fa-fw fa-exclamation-circle"></i></div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label for="validationCustom04" class="form-label">{{ $t('forms.description') }}<i
                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                <textarea class="form-control" v-model="serviceTask.description" rows="3"
                                    id="validationCustom04" placeholder="Enter description" required></textarea>
                                <div class="invalid-feedback">
                                    {{ $t('forms.description_description') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span @click="addLigne" class="btn btn-outline-success"><i class="align-middle fas fa-fw fa-plus"></i>{{ $t('forms.add_task') }}</span>
            </form>
        </div>
    </div>
</template>
    
<script>
import { mapActions, mapGetters } from 'vuex'
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'AddServiceTask',
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    components: {
        CustomButton
    },
    data() {
        return {
            services: null,
            managers: null,
            parteners: null,
            serviceTaskFormData: [
                {
                    service: this.$route.params.id,
                    creator: null,
                    name: null,
                    description: null,
                    severity: this.$t('forms.low'),
                    minimal_time: 1,
                    maximal_time: 2
                }
            ],
            isStart: false,
            my_service: {}
        };
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initPage()
    },
    methods: {
        ...mapActions('service', ['getService']),
        ...mapActions('auth', ['_currentUser']),
        ...mapActions('service_task', ['addServiceTask']),
        initJs() {
            var forms = document.querySelectorAll('.needs-validation')
            Array.prototype.slice.call(forms)
                .forEach(function (form) {
                    form.addEventListener('submit', function (event) {
                        if (!form.checkValidity()) {
                            event.preventDefault()
                            event.stopPropagation()
                        }
                        form.classList.add('was-validated')
                    }, false)
                })
        },
        async initPage() {
            let serviceId = this.$route.params.id
            this.my_service = await this.getService(serviceId)
        },
        async saveServiceTask() {
            this.isStart = true
            for (let i = 0; i < this.serviceTaskFormData.length; i++) {
                this.serviceTaskFormData[i].creator = this.getCurrentUser.id;
            }
            try {
                await this.addServiceTask(this.serviceTaskFormData)
                this.$successNotyf(this.$t('success.j'))
                this.$router.push({ name: 'service-detail', params: { id: this.$route.params.id } });
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        goBack() {
            window.history.go(-1);
        },
        addLigne() {
            this.serviceTaskFormData.push(
                {
                    service: this.$route.params.id,
                    creator: null,
                    name: null,
                    description: null,
                    severity: this.$t('forms.low'),
                    minimal_time: 1,
                    maximal_time: 2
                }
            )
        },
        dropStoreLine(key) {
            if (this.serviceTaskFormData.length >= 2) {
                this.serviceTaskFormData.splice(key, 1)
            }
        },
    },
}
</script>
<style></style>
    