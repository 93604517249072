<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="card">
                <div class="row mt-2 mb-1 m-1">
                    <h3 class="col-md-10">{{ $t('evaluation.resultat_evaluation') }} <span class="text-muted" v-if="evaluation_delail"> - {{ evaluation_delail.month_evaluation }}</span></h3>
                    <div v-if="evaluation_delail" class="col-md-2 d-flex align-items-center justify-content-end">
                        <button @click="closeEvaluationData" class="btn btn-outline-danger">
                            <i class="fas fa-times"></i> {{  $t('evaluation.back') }}
                        </button>
                    </div>
                </div>
            </div>
            <MonthStat 
                v-if="dataReport.length" 
                :dataReport="dataReport"
                :reportStartDate="reportStartDate"
                :reportEndDate="reportEndDate"
                :reportFrequency="reportFrequency"
            />
            <div v-if="listEvaluations.length">
                <div v-show="(!evaluationContents.evaluation) && (!rubriqueData.length)" class="row">
                    <div class="col-md-12 " v-for="(my_evaluation, index) in listEvaluations" :key="index">
                        <div class="card">
                            <div class="card-body">
                                <div class="row  pt-0 pb-0 mt-0 mb-0">
                                    <div class="col-md-5">
                                        <h5 class="card-title">{{ my_evaluation.name }}</h5>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="card-text">{{ $t('evaluation.month_evaluation') }} {{ my_evaluation.month_evaluation }}</p>
                                    </div>
                                    <div class="col-md-2">
                                        <p class="card-text"><span class="badge bg-info">{{ $t('evaluation.type') }} {{
                                            my_evaluation.type?.toUpperCase() }}</span></p>
                                    </div>
                                    <div class="col-md-2 d-flex align-items-center justify-content-end">
                                        <button @click="changeEvaluationData(my_evaluation)" v-if="!my_evaluation.is_validate_by_collaborator"
                                            class="btn btn-primary">{{ $t('evaluation.evaluer') }}</button>
                                        <button v-else @click="changeEvaluationData(my_evaluation)" class="btn btn-primary">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="evaluationContents.evaluation" class="row">
                    <div class="col-sm-12">
                        <div class="alert alert-danger alert-dismissible" role="alert">
                            <div class="alert-icon">
                                <i class="far fa-fw fa-bell"></i>
                            </div>
                            <div class="alert-message">
                                {{ $t('evaluation.verification') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <h2>{{ $t('evaluation.formulaire_evaluation') }}</h2>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="submitForm">
                        <hr>
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <div class="row">
                                    <div class="col-sm-12" v-for="rubrique in collaboratorDatas" :key="rubrique.id">
                                        <div class="card mb-3">
                                            <div class="card-header mt-0 mb-0 pb-0">
                                                <h3 class="card-title">{{ rubrique.name }}</h3>
                                            </div>
                                            <div class="card-body pt-0">
                                                <div v-for="template in rubrique.templates" :key="template.id">
                                                    <div class="row g-3">
                                                        <div
                                                            v-if="template.type_field === 'input' || template.type_field === 'textarea'">
                                                            <label>{{ template.question }}</label>
                                                            <div v-if="template.type_field === 'input'">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control"
                                                                        v-model="template.inputValue"
                                                                        placeholder="Saisissez votre réponse" required>
                                                                    <span v-show="template.notes" class="input-group-text"
                                                                        data-bs-toggle="popover" data-bs-placement="top"
                                                                        :data-bs-content="template.notes">
                                                                        <i class="fas fa-exclamation-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="template.type_field === 'textarea'">
                                                                <div class="input-group">
                                                                    <textarea class="form-control" v-model="template.textareaValue"
                                                                        rows="1" placeholder="Saisissez votre réponse"
                                                                        required></textarea>
                                                                    <span v-show="template.notes" class="input-group-text"
                                                                        data-bs-toggle="popover" data-bs-placement="top"
                                                                        :data-bs-content="template.notes">
                                                                        <i class="fas fa-exclamation-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else-if="template.type_field === 'select'">
                                                            <label>{{ template.question }}
                                                                <span v-show="template.notes" class="text-muted"
                                                                    data-bs-toggle="popover" data-bs-placement="top"
                                                                    :data-bs-content="template.notes">
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                </span>
                                                            </label>
                                                            <select class="form-select" v-model="template.selectValue" required>
                                                                <option v-for="(option, index) in template.data.split(',')"
                                                                    :key="index">
                                                                    {{ option }}</option>
                                                            </select>
                                                        </div>
                                                        <div v-else-if="template.type_field === 'radio'">
                                                            <label>{{ template.question }}
                                                                <span v-show="template.notes" class="text-muted"
                                                                    data-bs-toggle="popover" data-bs-placement="top"
                                                                    :data-bs-content="template.notes">
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                </span>
                                                            </label>
                                                            <div v-for="(option, index) in template.data.split(',')" :key="index"
                                                                class="form-check">
                                                                <input class="form-check-input" type="radio" required
                                                                    :name="'radio-' + template.id" :id="'radio-' + index"
                                                                    :value="option" v-model="template.radioValue">
                                                                <label class="form-check-label" :for="'radio-' + index">{{ option
                                                                }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                                <CustomButton
                                    buttonType="button"
                                    buttonSubmitType="submit"
                                    buttonClass="btn-primary"
                                    :buttonText="$t('evaluation.enregistrer')"
                                    :isLoading="isLoading"
                                    :loadingText="$t('general.loading') + '...'"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div v-show="rubriqueData.length" class="row">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body m-sm-3 m-md-5">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <strong>{{ evaluation_delail?.name }}</strong><br>
                                            <strong>{{ $t('evaluation.collaborateur') }} : </strong> {{ evaluation_delail?.user.name }}
                                                <br><em>{{ $t('evaluation.collaborateur') }}</em>
                                        </div>
                                    </div>
                                    <hr>

                                    <div v-for="rubrique in rubriqueData" :key="rubrique.id" class="rubrique-section mb-4">
                                        <!-- Afficher la rubrique -->
                                        <div class="row mb-3">
                                            <div class="col-12">
                                                <div class="text-primary">
                                                    <strong>{{ $t('evaluation.rubrique') }} : {{ rubrique.rubrique }}</strong>
                                                </div>
                                                <hr class="mt-1 mb-2" />
                                            </div>
                                        </div>

                                        <!-- Afficher les questions/réponses -->
                                        <div class="row" v-for="template in rubrique.contents" :key="template.id">
                                            <div class="col-md-12 d-flex mb-3">
                                                <!-- Question -->
                                                <div class="col-md-6 text-muted">
                                                    <strong>[{{ $t('evaluation.question') }}]</strong> {{ template.question }}
                                                </div>
                                                <!-- Réponse et justification -->
                                                <div class="col-md-6 text-md-end">
                                                    <strong>[{{ $t('evaluation.reponse') }}]</strong> {{ template.reponse || $t('evaluation.no_response') }}
                                                    <div v-if="template.justification" class="text-muted mt-1">
                                                        <small>[{{ $t('evaluation.justification') }}]</small> {{ template.justification }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="loader">
                <h4>{{ $t('evaluation.not_evaluation') }}</h4>
                <span>{{ $t('evaluation.aucune_evaluation') }}</span>
            </div>
        </div>
        <div v-else class="loader">
            <span>{{ $t('evaluation.chargement') }}...</span>
            <div class="mt-4 mb-4">
                <div class="spinner-grow text-primary me-2" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
            </div>
        </div>
        <Pagination v-if="isDispo && listEvaluations.length && ((!evaluationContents.evaluation) && (!rubriqueData.length))"
            :currentPage="paginateOptions.current_page"
            :totalPages="paginateOptions.total_pages"
            :previousLabel="$t('pagination.previous')"
            :nextLabel="$t('pagination.next')"
            @page-changed="fetchPage"
        />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MonthStat from '@/components/General/user_month_stat.vue'
import Pagination from '@/components/General/Pagination.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'EvaluationPage',
    data() {
        return {
            isDispo: false,
            collaboratorDatas: [],
            supervisorDatas: [],
            isLoad: false,
            listEvaluations: [],
            evaluationContents: {
                evaluation: null,
                type: 'collaborator',
                contents: []
            },
            evaluation_delail: null,
            rubriqueData: [],
            dataReport: [],
            filterOptions: {
                userId: null,
                page: 1,
                per_page: 10,
            },
            paginateOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 10,
            },
            reportStartDate: null,
            reportEndDate: null,
            reportType: 'month',
            isLoading: false
        };
    },
    components: {
        MonthStat,
        Pagination,
        CustomButton
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initEvaluationsTemplate()
        const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl); // eslint-disable-line no-undef
        });
    },
    methods: {
        ...mapActions('evaluation', [
            'getUserListEvaluation',
            'addEvaluationContent',
            'getUserStaticMonth'
        ]),
        ...mapActions('auth', ['_currentUser']),
        async changeEvaluationData(my_eval) {
            if(!my_eval.is_validate_by_collaborator){
                this.evaluationContents.evaluation = my_eval.id
            }else{
                this.rubriqueData = my_eval.rubriques
            }
            this.evaluation_delail = my_eval
            let month_evaluation = my_eval.month_evaluation
            let user_id = this.getCurrentUser.id
            let response = await this.getUserStaticMonth({ id: user_id, date: month_evaluation })
            this.dataReport = response.data
            this.reportFrequency = response.frequency
            this.reportStartDate = response.start_date
            this.reportEndDate = response.end_date
        },
        closeEvaluationData() {
            this.evaluationContents.evaluation = null
            this.evaluationContents.contents = []
            this.rubriqueData = []
            this.evaluation_delail = null
            this.dataReport = []
        },
        async fetchPage(page) {
            this.isDispo = true
            this.paginateOptions.current_page = page; 
            this.filterOptions.page = page;
            await this.initEvaluationsTemplate();
        },
        async initEvaluationsTemplate() {
            if (this.getCurrentUser) {
                this.filterOptions.userId = this.getCurrentUser.id;
                let output = await this.getUserListEvaluation(this.filterOptions);
                this.listEvaluations = output.evaluation_list;
                this.supervisorDatas = output.template.supervisor_evaluation_templates;
                this.collaboratorDatas = output.template.collaborator_evaluation_templates;
                this.paginateOptions = output.pagination || {
                    current_page: 1,
                    total_pages: 1,
                    total_items: 0,
                    per_page: 10,
                };
            }
            this.isDispo = true;
        },
        formatFormData() {
            for (const rubrique of this.collaboratorDatas) {
                for (const template of rubrique.templates) {
                    const evaluationContent = {
                        template: template.id,
                        reponse: '',
                        justification: ''
                    };

                    if (template.type_field === 'input') {
                        evaluationContent.reponse = template.inputValue;
                    } else if (template.type_field === 'textarea') {
                        evaluationContent.reponse = template.textareaValue;
                    } else if (template.type_field === 'select') {
                        evaluationContent.reponse = template.selectValue;
                    } else if (template.type_field === 'radio') {
                        evaluationContent.reponse = template.radioValue;
                    }
                    if (template.justify) {
                        evaluationContent.justification = template.textareaValue;
                    }
                    this.evaluationContents.contents.push(evaluationContent);
                }
            }
            return this.evaluationContents
        },
        async submitForm() {
            this.isLoading = true
            let data = this.formatFormData()
            if (data) {
                try {
                    await this.addEvaluationContent(data)
                    await this.initEvaluationsTemplate()
                    this.evaluationContents.evaluation = null
                    this.evaluationContents.contents = []
                    this.successResponse(this.$t('success.e'))
                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    this.errorResponse(error)
                }
            } else {
                this.errorResponse({ message: this.$t('success.f')+ ' ' +this.$t('success.g') })
            }
        },
        errorResponse(error) {
            this.$errorNotyf(error)
        },
        successResponse(message) {
            this.$successNotyf(message)
        },
    },
}
</script>