import axiosWithHeaders from '../services/api';


const state = {
  userTypes: [],
};

const getters = {
  getUserTypes: (state) => state.userTypes,
};

const mutations = {
  setUserTypes: (state, userTypes) => {
    state.userTypes = userTypes;
  },
};

const actions = {
  userTypeList({ commit, dispatch, state }, payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        search = '',
        filters = {},
        perPage = 10,
        page = 1
      } = payload;

      const params = {
        search,
        ...filters,
        per_page: perPage,
        page
      };

      axiosWithHeaders.get('user_types', { params })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response?.data || error);
        });
    });
  },
  allUserTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('all/user_types')
        .then(response => {
          commit('setUserTypes', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUserType({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user_type/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUserTypeByName({ commit }, name) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user_type-name/${name}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addUserType({ commit }, userTypeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('create/user-type', userTypeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateUserType({ commit }, userTypeData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/user-type/${userTypeData.id}`, userTypeData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteUserType({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete/user-type/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
