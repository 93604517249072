import axiosWithHeaders from '../services/api';


const state = {
  services: [],
  selectedService: null,
  serviceArchives: [],
  serviceUnarchives: [],
};

const getters = {
  services: state => state.services,
  selectedService: state => state.selectedService,
  serviceArchives: state => state.serviceArchives,
  serviceUnarchives: state => state.serviceUnarchives,
};

const mutations = {
  SET_SERVICES(state, services) {
    state.services = services;
  },
  SET_SELECTED_SERVICE(state, service) {
    state.selectedService = service;
  },
  SET_SERVICE_ARCHIVES(state, archives) {
    state.serviceArchives = archives;
  },
  SET_SERVICE_UNARCHIVES(state, unarchives) {
    state.serviceUnarchives = unarchives;
  },
};


const actions = {
  createService({ commit }, serviceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('create-service', serviceData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getService({ commit }, serviceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`service/${serviceId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  services({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('services')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  serviceLists({ commit }, options) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`services/list?search=${options.search}&per_page=${options.per_page}&page=${options.page}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateService({ commit }, serviceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-service/${serviceData.id}`, serviceData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getServicesCreatedByUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`services/created-by-user/${userId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getServiceArchives({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('services/archives')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getServiceUnarchives({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('services/unarchives')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  updateServiceStatus({ commit }, serviceId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-service-status/${serviceId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  importService({ commit }, serviceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('import-services', serviceData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
