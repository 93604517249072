<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-sm-block">
                    <h3><strong>{{ $t('page.tasks.my_tasks') }}</strong></h3>
                </div>
                <div class="col-auto ms-auto text-end">
                    <input type="text" class="form-control" :placeholder="$t('general.recherche')" v-model="filterOptions.search" @input="initUserTasks">
                </div>
                <div class="col-auto d-none d-sm-block p-0 m-0 me-2">
                    <select class="form-select" v-model="filterOptions.status" @change="initUserTasks">
                        <option value="all">{{ $t('page.tasks.all_tasks') }}</option>
                        <option value="In progress">{{ $t('general.in_progress') }}</option>
                        <option value="Pending">{{ $t('general.pending') }}</option>
                        <option value="Completed">{{ $t('general.completed') }}</option>
                        <option value="On pause">{{ $t('general.on_pause') }}</option>
                        <option value="Rejected">{{ $t('general.rejected') }}</option>
                        <option value="Aborted">{{ $t('general.aborded') }}</option>
                    </select>
                </div>
                <div class="col-auto d-none d-sm-block p-0 m-0 me-2">
                    <router-link class="btn btn-primary" title="View" :to="{ name: 'user-calendar', params: { id: getCurrentUser.id }}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-calendar align-middle">
                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="16" y1="2" x2="16" y2="6"></line>
                            <line x1="8" y1="2" x2="8" y2="6"></line>
                            <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg><span class="align-middle"> {{ $t('new.Calendar') }}</span>
                    </router-link>
                </div>
            </div>
            <div v-if="tasks.length" class="row">
                <div class="card">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{ $t('page.tasks.name') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('page.tasks.due_date') }}</th>
                                <th>{{ $t('page.tasks.status') }}</th>
                                <th class="d-none d-xl-table-cell">{{ $t('page.tasks.action') }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="!isLoading">
                            <tr v-for="task in tasks" :key="task.id">
                                <td>
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <strong>{{ task.title }}</strong>
                                            <div class="text-muted">
                                                {{ $t('page.tasks.duration') }} : {{ task.duration ? $formatDuration(task.duration) : '-' }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell">
                                    <strong>{{ $t('page.tasks.end') }} {{ task.end_date ? $formatDate(task.end_date) : '-'}}</strong>
                                    <div class="text-muted">
                                        {{ $t('page.tasks.start') }} {{ task.start_date ? $formatDate(task.start_date) : '-' }}
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex flex-column">
                                        <span class="badge bg-success" v-show="task.status == 'Completed'">{{
                                            task.status }}</span>
                                        <span class="badge bg-danger" v-show="task.status == 'Pending'">{{
                                            task.status }}</span>
                                        <span class="badge bg-warning" v-show="task.status == 'In progress'">{{
                                            task.status }}</span>
                                        <span class="badge bg-warning" v-show="task.status == 'On pause'">{{
                                            task.status }}</span>
                                        <span class="badge bg-danger" v-show="task.status == 'Rejected'">{{
                                            task.status }}</span>
                                        <span class="badge bg-dark" v-show="task.status === 'Aborted'">{{ 
                                            task.status }}</span>
                                    </div>
                                </td>
                                <td class="d-none d-xl-table-cell text-end">
                                    <span @click="startTask(task.id)" class="btn btn-primary me-1" title="Start"
                                        v-show="task.status == 'Pending'">{{ $t('page.tasks.start_task') }}</span>
                                    <span @click="restartTask(task.id)" class="btn btn-warning me-1" title="Start"
                                        v-show="task.status == 'On pause'">{{ $t('page.tasks.continious_task') }}</span>
                                    <span @click="restartTask(task.id)" class="btn btn-primary me-1" title="Start"
                                        v-show="task.status == 'Rejected'">{{ $t('page.tasks.back_work') }}</span>
                                    <span v-if="getCurrentUser.user_type.name == 'Head'" @click="pauseTask(task.id)" class="btn btn-danger me-1" title="On pause"
                                        v-show="task.status == 'In progress'">{{ $t('page.tasks.pause') }}</span>
                                    <span @click="CompletedTask(task.id)" title="Finish" class="me-1 btn btn-success"
                                        v-show="task.status == 'In progress'">{{ $t('page.tasks.completed') }}</span>
                                    <span class="me-1">
                                        <router-link class="btn btn-secondary" title="View"
                                            :to="{ name: 'task-detail', params: { id: task.id } }">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-eye align-middle btn-link">
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                        </router-link>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td :colspan="4" class="text-center">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                                    </div>
                                    <p>{{ $t('general.loading') }}...</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Pagination v-if="tasks.length"
                    :currentPage="paginateOptions.current_page"
                    :totalPages="paginateOptions.total_pages"
                    :previousLabel="$t('pagination.previous')"
                    :nextLabel="$t('pagination.next')"
                    @page-changed="fetchPage"
                />
            </div>
            <div v-else class="loader">
                <h4>{{ $t('page.tasks.no_task_found') }}</h4>
            </div>
        </div>
        <div v-else>
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import Pagination from '@/components/General/Pagination.vue';

export default {
    name: 'tasksComponent',
    components: {
        EmptyPageComponent,
        Pagination,
    },
    data() {
        return {
            isDispo: false,
            tasks: [],
            filterOptions: {
                page: 1,
                per_page: 10,
                search: '',
                user_id: null,
                status: 'all',
            },
            paginateOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 4,
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    async mounted() {
        this.isDispo = false;
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initUserTasks()
        this.isDispo = true;
    },
    methods: {
        ...mapActions('auth', ['_currentUser', 'getUsersByUserTypeName']),
        ...mapActions('task_assignment', ['getUserTasks', 'setTaskStatus', 'addStartOrEndTime']),
        async initUserTasks() {
            this.isLoading = true;
            this.filterOptions.user_id = this.getCurrentUser.id;
            let response = await this.getUserTasks(this.filterOptions)
            this.tasks = response.data;
            this.paginateOptions = response.pagination || {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 10,
            };
            this.isLoading = false;
        },
        async fetchPage(page) {
            this.paginateOptions.current_page = page;
            this.filterOptions.page = page;
            await this.initUserTasks(); 
        },
        async startTask(taskId) {
            this.isLoading = true;
            try {
                await this.executeTaskAction(taskId, { start_time: this.todayDate() }, this.$t('messages.9'));
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error)
            }
        },

        async restartTask(taskId) {
            this.isLoading = true;
            try {
                await this.executeTaskAction(taskId, { end_time: null }, this.$t('messages.8'));
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error)
            }
        },

        executeTaskAction(taskId, actionData, successMessage) {
            this.setTaskStatus({ task_id: taskId, status: 'In progress' })
                .then(() => {
                    return this.addStartOrEndTime({ task_id: taskId, ...actionData })
                        .then(() => {
                            this.handleSuccess(successMessage);
                        })
                        .catch(error => {
                            this.$errorNotyf(error)
                        });
                })
                .catch(error => {
                    this.$errorNotyf(error)
                });
        },

        handleSuccess(message) {
            this.$successNotyf(message)
            this.initUserTasks();
        },
        async pauseTask(taskId) {
            this.isLoading = true;
            try {
                await this.setTaskStatus({ task_id: taskId, status: 'On pause' })
                this.isLoading = false;
                this.handleSuccess(this.$t('messages.7'));
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error)
            }
        },
        async CompletedTask(taskId) {
            this.isLoading = true;
            try {
                await this.setTaskStatus({ task_id: taskId, status: 'Completed' })
                await this.addStartOrEndTime({ task_id: taskId, end_time: this.todayDate() })
                this.isLoading = false;
                this.handleSuccess(this.$t('messages.6'));
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error)
            }
        },
        todayDate() {
            const currentDate = new Date();
            return format(currentDate, 'yyyy-MM-dd');
        }
    },

}
</script>
<style>
.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: #f8f8f8;
}

.loader h1 {
    font-size: 36px;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7986cb;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
</style>
    