import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {
  getEvaluation({ commit, dispatch, state }, evaluationId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-evaluation/${evaluationId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  addEvaluationContent({ commit, dispatch, state }, contentData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-evaluation-content', contentData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateEvaluationConfig({ commit, dispatch, state }, configData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-evaluation-config`, configData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUserListEvaluation({ commit, dispatch, state }, options) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-user-list-evaluation/${options.userId}?page=${options.page}&per_page=${options.per_page}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getManagerSubordinatesEvaluation({ commit, dispatch, state }, options) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-manager-subordinates-evaluation/${options.managerId}?page=${options.page}&per_page=${options.per_page}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getManagerSubordinatesEvaluationContents({ commit, dispatch, state }, evaluationId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-manager-subordinates-evaluation-contents/${evaluationId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getUsersEvaluations({ commit, dispatch, state }, options) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`get-head-evaluation-contents?page=${options.page}&per_page=${options.per_page}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getListEvaluations({ commit, dispatch, state }, evaluationId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get-list-evaluations')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  addRebrique({ commit, dispatch, state }, rebriqueData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-rebrique', rebriqueData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  addEvaluationTemplate({ commit, dispatch, state }, templateData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-evaluation-template', templateData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  updateRebrique({ commit, dispatch, state }, rebriqueData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-rebrique/${rebriqueData.id}`, rebriqueData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  updateEvaluationTemplate({ commit, dispatch, state }, templateData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-evaluation-template/${templateData.id}`, templateData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setTemplateEvaluationStat({ commit, dispatch, state }, templateId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-active-template/${templateId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setTemplateEvaluationJustify({ commit, dispatch, state }, templateId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-justify-template/${templateId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  deleteRebrique({ commit, dispatch, state }, rebriqueId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete-rebrique/${rebriqueId}`)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  deleteEvaluationTemplate({ commit, dispatch, state }, templateId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete-evaluation-template/${templateId}`)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getEvaluationsTemplateByUsing({ commit, dispatch, state }, usingBy) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`evaluation-templates/${usingBy}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getEvaluationsTemplateByUsingByRebrique({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('get/evaluation-templates/rebrique')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  
  getUserStaticMonth({ commit, dispatch, state }, statData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`get-user-stat-month/${statData.id}`, statData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
