import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};


const actions = {
  notifications({ commit, dispatch, state }, payload = {}) {
    return new Promise((resolve, reject) => {
      const {
        search = '',
        filters = {},
        perPage = 10,
        page = 1
      } = payload;

      const params = {
        search,         
        ...filters,      
        per_page: perPage, 
        page            
      };

      // Envoyer la requête avec les paramètres
      axiosWithHeaders.get(`notifications/${payload?.filters?.user_id}`, { params })
        .then(response => {
          resolve(response.data); // Retourner les données
        })
        .catch(error => {
          reject(error.response?.data || error); // Gérer les erreurs
        });
    });
  },

  notification({ commit, dispatch, state }, user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`notification/${user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteNotifications({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('notification-delete', data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  lastNotifications({ commit, dispatch, state }, user_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`last-notification/${user_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  setIsSee({ commit, dispatch, state }, notification_id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-is-see/${notification_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
