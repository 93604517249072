<template>
  <div class="container-md-12">
    <div class="row" v-if="isDispo">
      <!-- Bouton Retour -->
      <div class="mb-2">
        <a href="/tasks" class="btn btn-close fs-3 btn-white bg-primary" :title="$t('general.back')"></a>
      </div>

      <!-- Barre latérale -->
      <div class="col-md-3 bg-light border">
        <div class="mt-2">
          <h5>{{ $t('calendar.year') }}</h5>
          <!-- Sélection de l'année -->
          <div class="mb-2">
            <select id="year-select" v-model="selectedYear" class="form-select" @change="updateCalendarView">
              <option v-for="year in yearsRange" :key="year" :value="year">{{ year }}</option>
            </select>
          </div>
          <!-- Sélection du mois -->
          <h5>{{ $t('calendar.month') }}</h5>
          <div>
            <select id="month-select" v-model="selectedMonth" class="form-select" @change="updateCalendarView">
              <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ $t('calendar.' + month) }}</option>
            </select>
          </div>
          <!-- Sélection du statut -->
          <h5>{{ $t('calendar.status') }}</h5>
          <div>
            <select class="form-select" v-model="filterOptions.status" @change="updateCalendarView">
              <option value="all">{{ $t('page.tasks.all_tasks') }}</option>
              <option value="In progress">{{ $t('general.in_progress') }}</option>
              <option value="Pending">{{ $t('general.pending') }}</option>
              <option value="Completed">{{ $t('general.completed') }}</option>
              <option value="On pause">{{ $t('general.on_pause') }}</option>
              <option value="Rejected">{{ $t('general.rejected') }}</option>
              <option value="Aborted">{{ $t('general.aborded') }}</option>
            </select>
          </div>
        </div>

        <!-- Toggle pour afficher/masquer les week-ends -->
        <div class="mt-4">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" :checked="calendarOptions.weekends" @change="handleWeekendsToggle" />
            {{ $t('calendar.toggle_week') }}
          </label>
        </div>

        <!-- Liste des tâches filtrées -->
        <div class="mt-4">
          <h5>{{ $t('calendar.list_tasks') }} ({{ filteredEvents.length }})</h5>
          <div style="max-height: 500px; overflow-y: auto;" v-if="filteredEvents.length">
            <ul class="list-group">
              <li v-for="event in filteredEvents" :key="event.id" class="list-group-item">
                <div>
                  <router-link :to="{ name: 'task-detail', params: { id: event.id } }">
                    <span class="fw-bold text-decoration-underline">{{ event.title }}</span>
                  </router-link>
                  <br>
                  <small class="text-muted">D: {{ $formatDateWithTime(event.start) }}</small> <br>
                  <small class="text-muted">F: {{ $formatDateWithTime(event.end) }}</small> <br>
                  <span v-if="event.extendedProps?.status">
                    <small>
                      {{ $t('calendar.status') }}: 
                      <span :class="statusClass(event.extendedProps?.status)">
                        {{ $t('calendar.' + event.extendedProps?.status) }}
                      </span>
                    </small>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="text-center mt-2 text-danger">
            {{ $t('general.no_data') }}
          </div>
        </div>
      </div>

      <!-- Calendrier -->
      <div class="col-md-9 card" v-if="filteredEvents.length > 0">
        <FullCalendar ref="calendarRef" class="p-2" :options="calendarOptions" v-if="!isLoading">
          <template v-slot:eventContent="arg">
            <router-link class="bg-primary mb-2" :to="{ name: 'task-detail', params: {id: arg.event.id} }">
              <div class="text-white">
                <span>{{ $t('general.name') }}: <b>{{ arg.event.title }}</b></span><br>
                <span>{{ $t('general.start') }}: <i>{{ arg.event.start }}</i></span><br>
                <span>{{ $t('general.end') }}: <i>{{ arg.event.end }}</i></span><br>
                <span>{{ $t('general.status') }}: <i>{{ arg.event.extendedProps.status }}</i></span>
              </div>
            </router-link>
          </template>
        </FullCalendar>

        <div class="text-center mt-4" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ $t('general.loading') }}...</span>
          </div>
          <p>{{ $t('general.loading') }}...</p>
        </div>
      </div>

      <!-- Message explicite si aucun événement -->
      <div v-else class="col-md-9 card">
        <div class="text-center mt-4">
          <p v-if="filterOptions.status === 'all'">
            {{ $t('general.no_events_for_this_period') }}
          </p>
          <p v-else>
            {{ $t('general.no_tasks_for_status') }}
          </p>
        </div>
      </div>
    </div>

    <!-- Page vide si indisponible -->
    <div v-else>
      <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

// États et références
const currentEvents = ref([]);
const selectedYear = ref(new Date().getFullYear());
const selectedMonth = ref(new Date().getMonth() + 1);
const calendarRef = ref(null);
const isDispo = ref(false);
const isLoading = ref(false);
const filterOptions = ref({ status: 'all' });

const calendarOptions = ref({
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  headerToolbar: { left: 'prev,next today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' },
  initialView: 'dayGridMonth',
  initialEvents: [],
  editable: true,
  weekends: true,
  eventsSet: (events) => currentEvents.value = events,
});

const store = useStore();
const route = useRoute();

const yearsRange = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i);
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const filteredEvents = computed(() => {
  return currentEvents.value.filter(event => {
    const eventStart = new Date(event.start);
    const eventEnd = new Date(event.end);
    
    // Vérifiez si l'événement est dans l'année et le mois sélectionnés
    const matchesYear = eventStart.getFullYear() === selectedYear.value;
    const matchesMonth = (eventStart.getMonth() + 1 === selectedMonth.value) || (eventEnd.getMonth() + 1 === selectedMonth.value);
    
    // Vérifiez si le statut correspond
    const matchesStatus = filterOptions.value.status === 'all' || event.extendedProps?.status.toLowerCase() === filterOptions.value.status.toLowerCase();

    return matchesYear && matchesMonth && matchesStatus;
  });
});

const statusClass = (status) => {
  const statusClasses = {
    'Completed': 'badge bg-success',
    'Pending': 'badge bg-info',
    'In progress': 'badge bg-warning',
    'On pause': 'badge bg-warning',
    'Rejected': 'badge bg-danger',
    'Aborted': 'badge bg-dark',
  };
  return statusClasses[status] || 'badge bg-secondary';
};

onMounted(async () => {
  isDispo.value = false;
  await fetchEvents();
  isDispo.value = true;
});

const fetchEvents = async () => {
  isLoading.value = true;
  try {
    const params = { year: selectedYear.value, month: selectedMonth.value, status: filterOptions.value.status, user_id: route.params.id };
    const resp = await store.dispatch('task_assignment/getUserCalendar', params);
    currentEvents.value = resp;
    calendarOptions.value.initialEvents = resp;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const updateCalendarView = () => {
  const calendarApi = calendarRef.value?.getApi();
  if (calendarApi) {
    // Récupérer tous les événements existants
    const allEvents = calendarApi.getEvents();

    // Masquer tous les événements qui ne sont pas dans filteredEvents
    allEvents.forEach(event => {
      const isVisible = filteredEvents.value.some(filteredEvent => filteredEvent.id === event.id);
      if (!isVisible) {
        event.setProp('display', 'none'); // Masquer l'événement
      } else {
        event.setProp('display', 'auto'); // Afficher l'événement
      }
    });

    // Aller à la date sélectionnée
    calendarApi.gotoDate(new Date(selectedYear.value, selectedMonth.value - 1, 1));
  }
};

const handleWeekendsToggle = (event) => {
  nextTick(() => {
    const calendarApi = calendarRef.value?.getApi();
    if (calendarApi) {
      calendarOptions.value.weekends = event.target.checked;
      calendarApi.setOption('weekends', calendarOptions.value.weekends);
    }
  });
};

// Watch pour observer les changements de filterOptions
watch([selectedYear, selectedMonth, () => filterOptions.value.status], updateCalendarView);
</script>

<style scoped>
h4 {
	font-size: 1.25rem;
}

.list-group-item {
	font-size: 0.9rem;
}
</style>
