<template>
    <div>
        <div class="container-fluid p-0" v-if="isDispo">
            <form @submit.prevent="saveProject" novalidate>
                <div class="card">
                    <div class="row mt-2 mb-1 m-1">
                        <div class="col-auto d-sm-block">
                            <h3><strong>{{ $t('forms.new_project') }}</strong></h3>
                        </div>
                        <div class="col-auto ms-auto text-end mt-n1">
                            <span v-show="section === 2">
                                <CustomButton
                                    buttonType="button"
                                    buttonSubmitType="submit"
                                    buttonClass="btn-primary"
                                    :buttonText="$t('forms.submit_project')"
                                    :isLoading="isStart"
                                    :loadingText="$t('general.loading') + '...'"
                                    class="me-2"
                                />
                            </span>
                            <button @click="goBack" class="btn btn-secondary" title="Back"><i
                                    class="align-middle fas fa-fw fa-arrow-left"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div style="height: 20px; text-align: center;" class="progress text-end">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" :class="section === 1 ? 'bg-warning' : 'bg-success'" role="progressbar"
                            :style="{ width: section === 1 ? '50%' : '100%', height: '100%' }"
                            aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    <div class="card-header bg-warning text-center p-0 progress-bar progress-bar-striped"></div>
                    <div v-show="section === 1" class="card-body h-100">
                        <h4>{{ $t('forms.step') }} 1</h4>
                        <hr>
                        <div class="row">
                            <div class="row mt-2">
                                <div class="col-md-6">
                                    <label for="validationCustom01" class="form-label">{{ $t('forms.project_name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" class="form-control" name="name" v-model="newProjectFormData.name"
                                        id="validationCustom01" :placeholder="$t('forms.project_name')" required>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.project_name_description') }}
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label for="validationCustom02" class="form-label">{{ $t('forms.service_categorie') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select @change="initServiceSelect()" class="form-select" name="categorie" v-model="category"
                                        id="validationCustom02" required>
                                        <option value="all">{{ $t('forms.all_categorie') }}</option>
                                        <option v-for="(categorie, index) in allCategories" :key="index" :value="categorie.id">{{
                                            categorie.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.service_categorie_description') }}
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label for="validationCustom12" class="form-label">{{ $t('forms.service_type') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                            <select @change="getServiceType" class="form-select" name="service_type" v-model="newProjectFormData.service" id="validationCustom12" required>
                                                <option value="">{{ $t('general.choice') }}</option>
                                                <option v-for="(service, key) in services" :key="key" :value="service.id">{{ service.name }} ({{ service.cyclique_number }})</option>
                                            </select>
                                    <div class="invalid-feedback">
                                        {{ $t('forms.service_type_description') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-3">
                                    <label class="form-label">{{ $t('general.start_date') }}</label>
                                    <input type="datetime-local" class="form-control" name="start_date"
                                        v-model="newProjectFormData.date_start" :placeholder="$t('general.start_date')" >
                                </div>
                                <div class="col-md-3">
                                    <label class="form-label">{{ $t('general.end_date') }}</label>
                                    <input type="datetime-local" class="form-control" name="end_date" 
                                    v-model="newProjectFormData.date_end" :placeholder="$t('general.end_date')" required>
                                </div>
                                <div class="col-md-4">
                                    <label class="form-label">{{ $t('page.parteners.select_partenaire') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <div class="d-flex">
                                        <label class="form-check me-2">
                                            <input class="form-check-input" v-model="newProjectFormData.project_type" type="radio"
                                                name="inline-radios-example" value="Individual">
                                            <span class="form-check-label">
                                                {{ $t('forms.individual') }}
                                            </span>
                                        </label>
                                        <label class="form-check">
                                            <input class="form-check-input" v-model="newProjectFormData.project_type" type="radio"
                                                name="inline-radios-example" value="Company">
                                            <span class="form-check-label">
                                                {{ $t('forms.company') }}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row mt-4">
                                <div v-show="newProjectFormData.project_type == 'Individual'" class="col-md-12 pt-0">
                                    <label class="form-label">{{ $t('page.parteners.select_partenaire') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="leader" v-model="newProjectFormData.leader"
                                        required>
                                        <option selected value="">{{ $t('general.choice') }}</option>
                                        <option v-for="partener in parteners" :key="partener.id" :value="partener.id">{{
                                            partener.name }}</option>
                                    </select>
                                </div>
                                <div v-show="newProjectFormData.project_type == 'Company'" class="row mt-4">
                                    <div class="col-md-6">
                                        <label class="form-label">{{ $t('forms.select_entreprise') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <select class="form-select" name="leader" v-model="newProjectFormData.entreprise"
                                            required @change="updatePartnersList">
                                            <option selected value="">{{ $t('general.choice') }}</option>
                                            <option v-for="(entreprise, index) in entreprises" :key="index" :value="entreprise.id">{{
                                                entreprise.name }} </option>
                                        </select>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">{{ $t('forms.select_lead') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <select class="form-select" name="leader" v-model="newProjectFormData.leader"
                                            required>
                                            <option selected value="">{{ $t('general.choice') }}</option>
                                            <option v-for="(userEntreprise, key) in userEntreprises" :key="key"
                                                :value="userEntreprise.id">
                                                {{ userEntreprise.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="section === 2" class="card-body h-100">
                        <h4>{{ $t('landingPage.terminer_configuration') }}</h4>
                        <hr>
                        <div class="row g-3">
                            <div class="card" v-for="(projectDetail, key) in newProjectFormData.projetDetail" :key="key">
                                <div class="row p-4">
                                    <div class="col-md-12">
                                        <label class="form-label">{{ $t('forms.project_name') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input readonly type="text" class="form-control" name="name" v-model="projectDetail.name"
                                           :placeholder="$t('forms.project_name')" required>
                                        <div class="invalid-feedback">
                                            {{ $t('forms.project_name_description') }}
                                        </div>
                                    </div>
                                    <div v-if="getCurrentUser?.user_type?.name === 'Head'" class="col-md-12">
                                        <label class="form-label">{{ $t('forms.project_manager') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <select class="form-select choices-single-manager" name="service_type"
                                            v-model="projectDetail.manager" required>
                                            <option selected disabled value="">{{ $t('general.choice') }}</option>
                                            <option v-for="(manager, key) in managers" :key="key" :value="manager.id">{{
                                                manager.name }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">{{ $t('forms.description') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <textarea class="form-control" name="description" rows="5" v-model="projectDetail.description"
                                            :placeholder="$t('forms.enter_description')" required></textarea>
                                        <div class="invalid-feedback">
                                            {{ $t('forms.description_description') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-2 pb-2 text-end">
                        <button :disabled="section === 1" @click="previousSection" class="btn btn-secondary me-2">
                            {{ $t('landingPage.precedent') }}
                        </button>
                        <button v-show="section === 1" @click="changeSection" class="btn btn-primary me-2">
                            {{ $t('landingPage.suivant') }}
                        </button>
                        <span v-show="section === 2">
                            <CustomButton
                                buttonType="button"
                                buttonSubmitType="submit"
                                buttonClass="btn-primary"
                                :buttonText="$t('forms.submit_project')"
                                :isLoading="isStart"
                                :loadingText="$t('general.loading') + '...'"
                            />
                        </span>
                    </div>
                </div>
            </form>
        </div>
        <div v-else>
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>
    
<script>
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
import CustomButton from '@/components/CustomButton.vue';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'

export default {
    name: 'AddProjectComponent',
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    components: {
        CustomButton,
        EmptyPageComponent
    },
    data() {
        return {
            services: [],
            managers: [],
            entreprises: [],
            parteners: [],
            isDispo: false,
            category: 'all',
            allCategories: [],
            projectFormData: {
                name: '',
                service: null,
                manager: null,
                description: null,
                status: this.$t('forms.pending'),
                start_date: null,
                end_date: null,
                date_assigned: null,
                leader: null,
                validator: null,
                project_type: 'Individual',
                open_date: null,
                entreprise: null,
                country: ''
            },
            newProjectFormData:{
                name: '',
                service: null,
                project_type: 'Individual',
                date_start: this.getMinDate(),
                date_end: null,  
                open_date: null,
                date_assigned: null,
                entreprise: null,
                leader: '',
                validator: null,
                cyclique_number: 1,
                projetDetail:[
                    {
                        name: '',
                        manager: null,
                        description: null,
                        start_date: null,
                        end_date: null
                    }
                ]
            },
            isStart: false,
            userEntreprises: [],
            permissions: [],
            selectedService: 'standard',
            section: 1
        };
    },
    async mounted() {
        this.isDispo = false
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initPage()
        await this.initSelects()
        await this.initServiceSelect()
        this.isDispo = true
    },
    methods: {
        ...mapActions('service', ['getServiceUnarchives']),
        ...mapActions('auth', ['_currentUser', 'getUsersByUserTypeName', 'getUserCompanyOrIndividual', 'getUsersByEntreprise', 'userRoles', 'getCountries']),
        ...mapActions('project', ['createProject']),
        ...mapActions('entreprise', ['fetchEntreprises']),
        ...mapActions('categorie', ['categories', 'getListeServiceCategories']),
        goBack() {
            window.history.go(-1);
        },
        getServiceType() {
            this.selectedService = this.services.find(service => service.id === this.newProjectFormData.service);
            this.newProjectFormData.cyclique_number = this.selectedService.cyclique_number
        },
        async initServiceSelect() {
            if (this.category == 'all') {
                this.services = await this.getServiceUnarchives()
            } else {
                this.services = await this.getListeServiceCategories(this.category)
            }
        },
        async initPage() {
            if(this.getCurrentUser){
                this.permissions = await this.userRoles(this.getCurrentUser.id)
            }
        },
        previousSection(){
            this.section = 1
           this.previous = false
        },
        formatProjetDetail() {
            const monthIndex = new Date(this.newProjectFormData.date_start).getMonth();
            let nbDuplicates = 0;
            if (this.newProjectFormData.cyclique_number > 1) {
                nbDuplicates = Math.min(this.newProjectFormData.cyclique_number - 1, 12 - monthIndex - 1);
            }

            // Création des projets
            const projetDetail = [];
            const projetName = this.newProjectFormData.name;
            for (let i = 0; i < nbDuplicates + 1; i++) {
                const monthNameIndex = (monthIndex + i) % 12;
                const monthNameToAdd = [
                    this.$t('months.january'), 
                    this.$t('months.february'),
                    this.$t('months.march'),
                    this.$t('months.april'),
                    this.$t('months.may'),
                    this.$t('months.june'),
                    this.$t('months.july'),
                    this.$t('months.august'),
                    this.$t('months.september'),
                    this.$t('months.october'),
                    this.$t('months.november'),
                    this.$t('months.december')
                ][monthNameIndex];
                const projetNameToAdd = this.newProjectFormData.cyclique_number > 1 ? `${projetName} (${monthNameToAdd})` : projetName;
                const start_date = new Date(new Date(this.newProjectFormData.date_start)).toISOString().substring(0, 16);
                const end_date = new Date(new Date(this.newProjectFormData.date_end)).toISOString().substring(0, 16);
                const clone = {...this.newProjectFormData.projetDetail[0], name: `${projetNameToAdd}`, start_date, end_date};
                projetDetail.push(clone);
            }
            
            this.newProjectFormData.projetDetail = projetDetail;
        },
        changeSection(){
            if(this.section === 1){
                if (this.newProjectFormData.name == '') {
                    this.$successNotyf(this.$t('errors.f'), this.$t('general.warning'))
                    return;
                }
                const now = new Date();
                const selectedStartDate = new Date(this.newProjectFormData.start_date);
                const selectedEndDate = new Date(this.newProjectFormData.date_end);
                
                if (selectedStartDate < now) {
                    this.$successNotyf(this.$t('errors.g'), this.$t('general.warning'))
                    return;
                }

                if (selectedEndDate <= selectedStartDate) {
                    this.$successNotyf(this.$t('errors.h'), this.$t('general.warning'))
                    return;
                }

                if (!this.newProjectFormData.service) {
                    this.$successNotyf(this.$t('errors.i'), this.$t('general.warning'))
                    return;
                }
                if (this.newProjectFormData.leader == '') {
                    this.$successNotyf(this.$t('errors.j'), this.$t('general.warning'))
                    return;
                }
                this.section = 2
                this.formatProjetDetail()
            }
        },
        async updatePartnersList() {
            console.log("Entreprise sélectionnée :", this.newProjectFormData.entreprise);
            if (this.newProjectFormData.entreprise) {
                let response = await this.getUsersByEntreprise(this.newProjectFormData.entreprise);
                this.userEntreprises = response;
                console.log("Liste des utilisateurs de l'entreprise :", this.userEntreprises);
            } else {
                this.userEntreprises = [];
            }
        },
        async loadCountries() {
            let response = await this.getCountries()
            this.countries = response
        },
        async initSelects() {
            try {
                this.managers = await this.getUsersByUserTypeName('Supervisor')
                this.parteners = await this.getUserCompanyOrIndividual('Individual')
                this.entreprises = await this.fetchEntreprises()
                this.allCategories = await this.categories()
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        getMinDate() {
            const now = new Date();
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1);
            const year = yesterday.getFullYear();
            const month = String(yesterday.getMonth() + 1).padStart(2, '0');
            const day = String(yesterday.getDate()).padStart(2, '0');
            const hours = String(yesterday.getHours()).padStart(2, '0');
            const minutes = String(yesterday.getMinutes()).padStart(2, '0');
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },
        async saveProject() {
            this.isStart = true
            const currentDate = new Date();
            const formattedDate = format(currentDate, 'yyyy-MM-dd');
            this.newProjectFormData.date_assigned = formattedDate

            this.newProjectFormData.validator = this.getCurrentUser.id
            this.newProjectFormData.open_date = this.newProjectFormData.date_start
            try {
                let response = await this.createProject(this.newProjectFormData)
                this.$successNotyf(this.$t('success.i'))
                this.$router.push({ name: 'project-detail', params: { id: response.id } });
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
        }
    }
}
</script>
<style></style>
    