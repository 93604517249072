<template>
    <div class="container-fluid p-0">
        <div v-show="isDispo">
            <!-- Header -->
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.users.grade') }}</strong></h3>
                </div>
                <div class="col-auto ms-auto text-end mt-n1">
                    <button 
                        v-if="$can('grades_create')"
                        class="btn btn-primary border my-1 me-2"  
                        @click="changeSubmitType('create')" 
                        data-bs-toggle="modal" 
                        data-bs-target="#grade-modal">
                        {{ $t('page.users.add_grade') }}
                    </button>
                </div>
            </div>

            <div class="modal fade" id="grade-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div 
                    class="modal-dialog modal-dialog-centered modal-lg" 
                    role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.users.grade') }} </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addOrUpdateGrade" class="row g-3 needs-validation">
                                <div v-if="typeSubmitGrade == 'create'">
                                    <div v-for="(grade, key) in gradeFormCreateData" :key="key">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}  <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="grade.name" class="form-control"
                                                    id="validationCustom01" placeholder="Name" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('page.users.please_enter_grade_name') }} 
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label class="form-label">{{ $t('page.users.cost') }}  <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="number" min="0" v-model="grade.cost" class="form-control"
                                                   placeholder="Cost" required>
                                                <div class="invalid-feedback">
                                                     {{ $t('page.users.please_enter_grade_cost') }} 
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <label class="form-label">{{ $t('page.users.currency') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <select v-model="grade.currency_id" class="form-control">
                                                    <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                                    <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                                        {{ currency.name }} ({{ currency.code }})
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-1 mt-4 pt-1">
                                                <span @click="dropStoreLine(key)" class="btn btn-sm btn-outline-danger"><i
                                                        class="align-middle fas fa-fw fa-trash"></i></span>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <span @click="addLigne" class="btn btn-outline-success"><i
                                            class="align-middle fas fa-fw fa-plus"></i></span>
                                </div>
                                <div v-else class="row">
                                    <div class="col-md-6">
                                        <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}  <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="text" v-model="gradeFormUpdateData.name" class="form-control"
                                            id="validationCustom01" placeholder="Name" required>
                                        <div class="invalid-feedback">
                                                    {{ $t('page.users.please_enter_grade_name') }} 
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="form-label">{{ $t('page.users.cost') }}<i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <input type="number" min="0" v-model="gradeFormUpdateData.cost" class="form-control"
                                           placeholder="Cost" required>
                                        <div class="invalid-feedback">
                                            {{ $t('page.users.please_enter_grade_cost') }} 
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="form-label">{{ $t('page.users.currency') }} <i
                                                class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                        <select v-model="gradeFormUpdateData.currency_id" class="form-control">
                                            <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                            <option v-for="currency in currencies" :key="currency.id" :value="currency.id">
                                                {{ currency.name }} ({{ currency.code }})
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <CustomButton
                                        buttonType="button"
                                        buttonClass="btn-primary"
                                        buttonSubmitType="submit"
                                        :buttonText="$t('page.users.save')"
                                        :isLoading="isLoading"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Datatable -->
            <div class="row card">
                <div class="card-body">
                    <DatatableComponent 
                        :headers="tableHeaders" 
                        :data="listGrades" 
                        :paginable="true" 
                        :currentPage="currentPage"
                        :totalPage="totalPage"
                        :isSearchable="true"
                        :filters="tableFilters"
                        :reloadFunction="initGrades"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>

        <!-- Chargement en attente -->
        <div v-show="!isDispo">
            <empty-page-component code="" :message="$t('general.loading') +'...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue';
import DatatableComponent from '@/components/General/DatatableComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import moment from 'moment'
import { nextTick } from 'vue';

export default {
    name: 'GradesComponent',
    components: {
        EmptyPageComponent,
        DatatableComponent,
        CustomButton
    },
    data() {
        return {
            isDispo: false,
            gradeFormCreateData: [
                {name: null, cost: 0, currency_id: '' }
            ],
            isLoading: false,
            gradeFormUpdateData: {
                id: null,
                name: null,
                cost: 0,
                currency_id: ''
            },
            typeSubmitGrade: 'create',
            user_id: null,
            listGrades: [],
            currentPage: 1,
            totalPage: 1,
            currencies: [],
            tableHeaders: [
                { name: this.$t('page.users.name'), key: 'grade.name', type: 'key' },
                { name: this.$t('page.users.cost'), key: 'grade.cost', type: 'key' },
                { name: this.$t('page.users.total_cost'), key: 'cost_total', type: 'key' },
                { name: this.$t('page.users.user'), key: 'user_count', type: 'key' },
                { name: this.$t('page.users.currency'), key: 'grade.currency.code', type: 'key' },
                { name: this.$t('page.users.action'), 
                    type: 'action', 
                    actions: [
                        { type: 'modal_edit', text: 'Modifier', function: this.editGrade, show_text: false, target: '#grade-modal', check_permission: () => this.$can('grades_update')  },
                    ] 
                },
            ],
            tableFilters: [
                { key: 'currency', options: [], label: this.$t('page.users.currency'), type: 'select', value: '' },
            ],
        };
    },
    async mounted() {
        this.isDispo = false
        await this.initGrades();
        await this.loadCurrencies();
        this.isDispo = true
    },
    methods: {
        ...mapActions('auth', ['getCurrencies']),
        ...mapActions('grade', ['addGrade', 'updateGrade', 'grades', 'grade']),
        async initGrades(options = { search: '', filters: {}, perPage: 10, page: 1 }) {
            this.isLoading = true;
            try {
                let response = await this.grades(options);
                this.listGrades = response.data || [];
                this.currentPage = response.current_page || 1;
                this.totalPage = response.total_pages || 1;
            } catch (error) {
                this.isLoading = false;
                console.error(error);
            }
            this.isLoading = false;
        },
        async loadCurrencies(){
            let response = await this.getCurrencies();
            this.tableFilters[0].options = response.map(c => ({ id: c.id, name: c.code }));
            this.currencies = response
        },
        editGrade(grade){
            this.changeSubmitType('update', grade.grade.id)
        },
        async changeSubmitType(type, gradeId = null) {
            this.typeSubmitGrade = type;
            if (gradeId) {
                let response = await this.grade(gradeId);
                if (response) {
                    this.gradeFormUpdateData = response;
                    this.gradeFormUpdateData.currency_id = response?.currency?.id;
                }
            }
        },
        formatDate(date) {
            return date ? moment(date).format("DD-MM-YYYY") : null
        },
        dropStoreLine(key) {
            if (this.gradeFormCreateData.length >= 2) {
                this.gradeFormCreateData.splice(key, 1)
            }
        },
        addLigne() {
            this.gradeFormCreateData.push(
                {
                    name: null,
                    cost: 0,
                    currency_id: ''
                }
            )
        },
        async addOrUpdateGrade() {
            try {
                this.isLoading = true;
                if (this.typeSubmitGrade == 'create') {
                    await this.addGrade(this.gradeFormCreateData);
                    this.initGrades();
                    this.gradeFormCreateData = [{ name: null, cost: 0, currency_id: '' }];
                } else {
                    if (this.gradeFormUpdateData.id) { // Ajout de cette vérification
                        await this.updateGrade(this.gradeFormUpdateData);
                        this.initGrades();
                        this.gradeFormUpdateData = { name: null, cost: 0, currency_id: '' };
                    } else {
                        console.error('Grade ID not found');
                    }
                }
                this.handleSuccess(this.$t('success.0'));
                
                nextTick(() => {
                    jQuery('#grade-modal').modal('toggle'); // eslint-disable-line no-undef
                });
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error);
            }
            this.isLoading = false;
        },
        handleSuccess(message){
            return this.$successNotyf(message)
        },
    }
};
</script>

<style scoped>
.table-actions {
    display: flex;
    gap: 0.5rem;
}
</style>
