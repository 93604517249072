<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <h3><strong>{{ $t('page.services.service') }}</strong></h3>
                        <input type="text" v-model="search" class="form-control" :placeholder="$t('general.recherche')" @input="loadSearch">
                    </div>
                </div>
                <div class="col-sm-6 text-end">
                    <button v-if="$can('services_create')" @click="changeSubmitType()" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#new-service-modal">{{ $t('page.services.new_service') }}</button>
                    <button v-if="$can('services_create')" class="btn btn-warning me-2" data-bs-toggle="modal" data-bs-target="#import-service-modal">{{ $t('page.services.import_service') }}</button>
                    <button v-if="$can('categories_create')" class="btn btn-success" @click="changeSubmitTypeCategorie()" data-bs-toggle="modal" data-bs-target="#new-categorie-modal">{{ $t('page.services.categorie') }}</button>
                </div>
            </div>
            <div class="modal fade" id="new-categorie-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.services.categorie') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addCategorie" class="row g-3 needs-validation">
                                <div class="col-md-12">
                                    <label class="form-label">{{ $t('page.services.categorie_name') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="categorieFormData.name" class="form-control"
                                        placeholder="Category name" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_name') }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.services.add_categorie')"
                                        :isLoading="isStart"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="import-service-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.services.import') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="handleImport" class="row g-3 needs-validation">
                                <div class="col-md-12">
                                    <label for="fileInput" class="form-label">{{ $t('page.services.select_file') }} <i class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        class="form-control"
                                        accept=".csv, .xlsx"
                                        @change="handleFileSelection"
                                    />
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.services.import_button')"
                                        :isDisabled="!selectedFile || isStart"
                                        :isLoading="isStart"
                                        :loadingText="$t('page.services.importing') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="new-service-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.services.service') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <form @submit.prevent="addService" class="row g-3 needs-validation">
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">{{ $t('page.services.categorie') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="categorie" :value="serviceFormData.category"
                                        v-model="serviceFormData.category" id="validationCustom02" required>
                                        <option v-for="(categorie, key) in allCategories" :key="key" :value="categorie.id">
                                            {{ categorie.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_categorie') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">  {{ $t('page.services.service_name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="serviceFormData.name" class="form-control"
                                        id="validationCustom01" placeholder="Service name" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_name') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label"> {{ $t('page.services.type_service') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" name="type_service" :value="serviceFormData.type"
                                        v-model="serviceFormData.type" required>
                                        <option value="standard"> {{ $t('page.services.standard') }}</option>
                                        <option value="cyclique"> {{ $t('page.services.cyclique') }}</option>
                                    </select>
                                </div>
                                <div v-show="serviceFormData.type == 'cyclique'" class="col-md-12">
                                    <label class="form-label">{{ $t('page.services.nb_cycle') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="number" min="1" v-model="serviceFormData.cyclique_number" class="form-control"
                                        placeholder="Nombre de cycle au cours de l'année" required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_select_name') }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="validationCustom02" class="form-label">Description <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <textarea v-model="serviceFormData.description" class="form-control" rows="5"
                                        id="validationCustom02" placeholder="Enter description" required></textarea>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_description') }}
                                      
                                    </div>
                                </div>
                                <div v-if="type == 'update'" class="col-md-12">
                                    <label for="validationCustom05" class="form-label">Status<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" id="validationCustom05" name="status"
                                        v-model="serviceFormData.status" required>
                                        <option value="unarchive">{{ $t('general.active') }}</option>
                                        <option value="archive">{{ $t('general.inactive') }}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.services.please_enter_type') }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <CustomButton v-if="type == 'create'"
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.services.add_new_service')"
                                        :isLoading="isStart"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                    <CustomButton v-else
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.services.update_service')"
                                        :isLoading="isStart"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div v-if=!allServices.length>
                        <h4> {{ $t('page.services.no_service_found') }}</h4>
                    </div>
                    <div v-else>
                        <div v-if="!isLoading" class="row">
                            <div class="col-sm-6" v-for="service in allServices" :key="service.id">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-title mb-0">
                                            <div class="flex-grow-1">
                                                <span class="float-end text-navy">
                                                    <router-link v-if="$can('services_view')" class="btn btn-primary btn-sm rounded"
                                                        :to="{ name: 'service-detail', params: { id: service.id } }">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-eye align-middle">
                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                            <circle cx="12" cy="12" r="3"></circle>
                                                        </svg>
                                                        {{ $t('general.view') }}
                                                    </router-link>
                                                </span>
                                                <strong>{{ service.name }} (AB00{{ service.id }})
                                                    <a href="#" v-if="$can('services_update')" data-bs-toggle="modal"
                                                        data-bs-target="#new-service-modal"
                                                        @click="changeSubmitType(service.id)"><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-edit-2 align-middle">
                                                            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                            </path>
                                                        </svg>
                                                    </a>
                                                </strong>
                                            </div>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-light">
                                        <div class="d-flex align-items-start">
                                            <div class="flex-grow-1">
                                                <strong class="text-muted text-sm"> {{ $t('page.services.status') }}</strong>
                                                <strong class="float-end text-navy">
                                                    <span v-show="service.status == 'archive'"
                                                    class="badge bg-danger">{{ $t('page.services.close') }}</span>
                                                    <span v-show="service.status == 'unarchive'"
                                                    class="badge bg-success">{{ $t('page.services.open') }}</span>
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-start">
                                            <div class="flex-grow-1">
                                                <strong class="text-muted text-sm">{{ $t('page.services.categorie') }}</strong>
                                                <strong class="float-end text-navy">
                                                    <span class="badge bg-info">{{ service.category?.name }}</span>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="loader">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                            </div>
                            <p>{{ $t('general.loading') }}...</p>
                        </div>
                    </div>
                    <Pagination v-if="allServices.length"
                        :currentPage="paginateServiceOptions.current_page"
                        :totalPages="paginateServiceOptions.total_pages"
                        :previousLabel="$t('pagination.previous')"
                        :nextLabel="$t('pagination.next')"
                        @page-changed="fetchServicePage"
                    />
                </div>
                <div class="col-md-4 card">
                    <div class="card-header">
                        <h6 class="card-subtitle text-muted">{{ $t('page.services.categorie') }}</h6>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.services.categorie') }}</th>
                                    <th>{{ $t('page.services.action') }}</th>
                                </tr>
                            </thead>
                            <tbody v-if="!isLoading">
                                <tr v-for="categorie in allCategories" :key="categorie.id">
                                    <td>
                                        <div class="text-muted">
                                            <strong>{{ categorie.name }}</strong>
                                        </div>
                                    </td>
                                    <td v-if="$can('categories_update')">
                                        <div class="text-end">
                                            <button @click="changeSubmitTypeCategorie(categorie.id)" data-bs-toggle="modal" data-bs-target="#new-categorie-modal" class="btn btn-sm btn-primary rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-edit-2 align-middle">
                                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td :colspan="2" class="text-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                                        </div>
                                        <p>{{ $t('general.loading') }}...</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <Pagination v-if="allCategories.length"
                            :currentPage="paginateCategorieOptions.current_page"
                            :totalPages="paginateCategorieOptions.total_pages"
                            :previousLabel="''"
                            :nextLabel="''"
                            @page-changed="fetchCategoriePage"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import CustomButton from '@/components/CustomButton.vue';
import Pagination from '@/components/General/Pagination.vue';

export default {
    name: 'servicesComponent',
    components: {
        EmptyPageComponent,
        Pagination,
        CustomButton
    },
    data() {
        return {
            isDispo: false,
            allServices: [],
            allCategories: [],
            search: null,
            isLoading: false,
            serviceFormData: {
                name: null,
                description: null,
                creator: null,
                cyclique_number: 1,
                type: 'standard',
                category: null
            },
            categorieFormData: {
                name: null
            },
            isStart: false,
            type: 'create',
            type_cat: 'create',
            service_: null,
            categorie_: null,
            permissions: [],
            selectedFile: null,
            filterServiceOptions: {
                page: 1,
                per_page: 4,
                search: null,
            },
            filterCategorieOptions: {
                page: 1,
                per_page: 3,
                search: null,
            },
            paginateServiceOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 4,
            },
            paginateCategorieOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 3,
            },
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        this.isDispo = false;
        if(! this.getCurrentUser){
            await this._currentUser()
        }
        await this.initCategories()
        await this.initServices()
        this.isDispo = true;
    },
    methods: {
        ...mapActions('service', ['serviceLists', 'createService', 'updateService', 'importService']),
        ...mapActions('categorie', ['categorieLists', 'createCategorie', 'updateCategorie']),
        ...mapActions('auth', ['_currentUser', 'userRoles']),
        async loadSearch(){
            this.filterServiceOptions.search = this.search
            this.filterCategorieOptions.search = this.search
            await this.initCategories(); 
            await this.initServices(); 
        },
        handleFileSelection(event) {
            this.selectedFile = event.target.files[0];
        },
        async handleImport() {
            if (!this.selectedFile) {
                this.$errorNotyf(this.$t("page.services.no_file_selected"));
                return;
            }

            // Préparer les données pour l'import
            const formData = new FormData();
            formData.append("file", this.selectedFile);

            this.isStart = true;

            try {
                await this.importService(formData);
                this.$successNotyf(this.$t("page.services.import_successful"))
                jQuery('#import-service-modal').modal('toggle'); // eslint-disable-line no-undef
                this.initCategories()
                this.initServices();
                this.isStart = false;
            } catch (error) {
                this.$errorNotyf(error)
            } finally {
                this.isStart = false;
                this.selectedFile = null; 
            }
        },
        changeSubmitType(serviceId = null) {
            if (serviceId != null) {
                this.service_ = this.allServices.find(element => element.id === serviceId);
                this.type = 'update'
                this.serviceFormData.name = this.service_.name
                this.serviceFormData.description = this.service_.description
                this.serviceFormData.creator = this.service_.creator.id
                this.serviceFormData.status = this.service_.status
                this.serviceFormData.category = this.service_.category ? this.service_.category.id : null
                this.serviceFormData.type = this.service_.type ? this.service_.type : 'standard'
                this.serviceFormData.cyclique_number = this.service_.cyclique_number ? this.service_.cyclique_number : 1
                this.serviceFormData.id = serviceId
            } else {
                this.service_ = null
                this.type = 'create'
                this.serviceFormData = {
                    name: null,
                    description: null,
                    creator: null,
                    category: null,
                    cyclique_number: 1,
                    type: 'standard',
                }
            }
        },
        changeSubmitTypeCategorie(categorieId = null) {
            if (categorieId != null) {
                this.categorie_ = this.allCategories.find(element => element.id === categorieId);
                this.type_cat = 'update'
                this.categorieFormData.name = this.categorie_.name
                this.categorieFormData.id = categorieId
            } else {
                this.categorie_ = null
                this.type_cat = 'create'
                this.categorieFormData = {
                    name: null
                }
            }
        },
        async fetchCategoriePage(page) {
            this.paginateCategorieOptions.current_page = page;
            this.filterCategorieOptions.page = page;
            await this.initCategories(); 
        },
        async fetchServicePage(page) {
            this.paginateServiceOptions.current_page = page;
            this.filterServiceOptions.page = page;
            await this.initServices(); 
        },
        async initServices() {
            this.isLoading = true;
            try {
                let response = await this.serviceLists(this.filterServiceOptions);
                this.allServices = response.data;
                this.paginateServiceOptions = response.pagination || {
                    current_page: 1,
                    total_pages: 1,
                    total_items: 0,
                    per_page: 10,
                };
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
                this.$errorNotyf(error)
            }
        },
        async initCategories() {
            this.isLoading = true;
            try {
                let response = await this.categorieLists(this.filterCategorieOptions)
                this.allCategories = response.data;
                this.paginateCategorieOptions = response.pagination || {
                    current_page: 1,
                    total_pages: 1,
                    total_items: 0,
                    per_page: 10,
                };
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.$errorNotyf(error)
            }
        },

        async addService() {
            this.isStart = true

            let response, message
            try {
                if (this.type == 'create') {
                    this.serviceFormData.creator = this.getCurrentUser.id
                    response = await this.createService(this.serviceFormData)
                    message = this.$t('success.9')
                } else {
                    response = await this.updateService(this.serviceFormData)
                    message = this.$t('success.b')
                }
                this.$successNotyf(message)
                this.serviceFormData.name = null,
                this.serviceFormData.description = null
                this.serviceFormData.type = 'standard'
                this.serviceFormData.cyclique_number = 1
                jQuery('#new-service-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initServices()
        },
        async addCategorie() {
            this.isStart = true

            let response, message
            try {
                if (this.type_cat == 'create') {
                    response = await this.createCategorie(this.categorieFormData)
                    message = this.$t('success.a')
                } else {
                    response = await this.updateCategorie(this.categorieFormData)
                    message = this.$t('success.c')
                }
                this.$successNotyf(message)
                this.categorieFormData.name = null
                jQuery('#new-categorie-modal').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initCategories()
        }
    },
}
</script>
<style></style>
    