import axiosWithHeaders from '../services/api';

const state = {
    entreprises: [],
    entreprise: null,
};

const getters = {
    getEntreprises: (state) => state.entreprises,
    getEntreprise: (state) => state.entreprise,
};

const mutations = {
    setEntreprises: (state, entreprises) => state.entreprises = entreprises,
    setEntreprise: (state, entreprise) => state.entreprise = entreprise,
};

const actions = {
    fetchEntreprises({
        commit
    }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('entreprises')
                .then(response => {
                    commit('setEntreprises', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    entrepriseList({ commit, dispatch, state }, payload = {}) {
        return new Promise((resolve, reject) => {
            const {
                search = '',
                filters = {},
                perPage = 10,
                page = 1
            } = payload;

            const params = {
                search,          
                ...filters,      
                per_page: perPage, 
                page           
            };

            axiosWithHeaders.get('entreprises/list', { params })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response?.data || error);
                });
        });
    },

    fetchEntreprise({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('entreprise/' + id)
                .then(response => {
                    commit('setEntreprise', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    addEntreprise({
        commit
    }, entrepriseData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post('create/entreprise', entrepriseData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    updateEntreprise({ commit }, entrepriseData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put('update/entreprise/' + entrepriseData.id, entrepriseData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    deleteEntreprise({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.delete('delete/entreprise/' + id)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};