import axiosWithHeaders from '../services/api';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  addDepartment({ commit, dispatch, state }, departmentData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('add-department', departmentData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  updateDepartment({ commit, dispatch, state }, departmentData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-department/'+departmentData.id, departmentData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  getDepartments({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('all/departments')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  departmentList({ commit, dispatch, state }, payload = {}) {
    return new Promise((resolve, reject) => {
      // Déstructuration sécurisée des paramètres avec des valeurs par défaut
      const {
        search = '',
        filters = {},
        perPage = 10,
        page = 1
      } = payload;

      const params = {
        search,          // Recherche
        ...filters,      // Filtres supplémentaires
        per_page: perPage, // Nombre d'éléments par page
        page            // Page actuelle
      };

      // Envoyer la requête avec les paramètres
      axiosWithHeaders.get('departments', { params })
        .then(response => {
          resolve(response.data); // Retourner les données
        })
        .catch(error => {
          reject(error.response?.data || error); // Gérer les erreurs
        });
    });
  },

  getDepartment({ commit, dispatch, state }, departmentId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('department/' + departmentId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },

  deleteDepartment({ commit, dispatch, state }, departmentId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete('delete-department/' + departmentId)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
