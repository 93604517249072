<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div v-if="getCurrentUser.user_type.name != 'Partener'">
                <div class="card">
                    <div class="row mt-2 mb-1 m-1">
                        <div class="col-auto d-none d-sm-block">
                            <h3><strong>{{ project.name }}</strong></h3>
                        </div>
                        <div class="col-auto ms-auto text-end mt-n1">
                            <span class="btn btn-info me-2" data-bs-toggle="modal" data-bs-target="#add-manager-modal"
                                v-show="(project.status == 'Pending') && $can('can_validate_project') && project.stats.total_tasks > 0 && project.stats.num_users > 0">{{
                                    $t('page.project_detail.validate_project') }}</span>
                            <router-link
                                v-if="((project.stats.total_tasks <= 0) && (project.status == 'Pending') && ($can('can_configurate_project_task')))"
                                class="btn btn-primary border rounded me-2"
                                :to="{ name: 'edit-task-config', params: { id: project.id } }">
                                {{ $t('page.project_detail.add_a_new_task') }}
                            </router-link>
                            <button data-bs-toggle="modal" data-bs-target="#new-task-project-modal"
                                v-if="((project.stats.total_tasks > 0) && (project.status != 'Pending') && ($can('can_add_task_to_project_task')))"
                                class="btn btn-primary me-2">
                                {{ $t('page.project_detail.add_a_new_task') }}
                            </button>
                            <button v-if="$can('can_chat_on_project_with_customer')" class="btn btn-light bg-white me-2"
                                type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft"
                                aria-controls="offcanvasLeft">{{ $t('page.project_detail.comments_with_customer')
                                }}</button>
                            <button v-if="$can('can_chat_on_project_with_collaborator')"
                                class="btn btn-outline-info my-1 me-2" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">{{
                                    $t('page.project_detail.comments_with_user') }}</button>
                            <button @click="goBack" class="btn btn-secondary" :title="$t('general.back')"><i
                                    class="align-middle fas fa-fw fa-arrow-left"></i></button>
                        </div>
                    </div>
                </div>
                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeft"
                    aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasRightLabel">{{ $t('page.project_detail.activities_comments') }}</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div class="card-body h-100">
                            <div v-if="partener_comments.length" class="chat-messages p-1">
                                <div v-for="partener_comment in partener_comments" :key="partener_comment.id">
                                    <div v-if="partener_comment.user.id != getCurrentUser.id"
                                        class="chat-message-right pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                                            <div class="font-weight-bold mb-1"><strong>{{ partener_comment.user.name }}
                                                </strong></div>
                                            {{ partener_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(partener_comment.created_at) }}</div>
                                        </div>
                                    </div>

                                    <div v-else class="chat-message-left pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                                            <div class="font-weight-bold mb-1"><strong>{{ $t('general.you') }}</strong>
                                            </div>
                                            {{ partener_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(partener_comment.created_at) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-messages p-4" v-else>
                                <div class="alert alert-warning alert-dismissible" role="alert">
                                    <div class="alert-icon">
                                        <i class="far fa-fw fa-bell"></i>
                                    </div>
                                    <div class="alert-message">
                                        <strong>{{ $t('page.project_detail.no_comment') }}</strong> {{
                                            $t('page.project_detail.add_comment') }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-0 py-3 px-4 border-top">
                                <div class="input-group">
                                    <textarea v-model="commentFormDataPartener.comment" class="form-control" rows="2"
                                        :placeholder="$t('page.project_detail.enter_message')"></textarea>
                                </div>
                                <div class="mb-3">
                                    <button @click="sendCommentTaskPartener" class="btn btn-primary">
                                        {{ $t('general.send') }}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-send align-middle">
                                            <line x1="22" y1="2" x2="11" y2="13"></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasRightLabel">{{ $t('page.project_detail.activities_comments_user') }}</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div class="card-body h-100">
                            <div v-if="user_comments.length" class="chat-messages p-1">
                                <div v-for="user_comment in user_comments" :key="user_comment.id">
                                    <div v-if="user_comment.user.id != getCurrentUser.id"
                                        class="chat-message-right pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                                            <div class="font-weight-bold mb-1"><strong>{{ user_comment.user.name
                                                    }}</strong></div>
                                            {{ user_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(user_comment.created_at) }}</div>
                                        </div>
                                    </div>
                                    <div v-else class="chat-message-left pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                                            <div class="font-weight-bold mb-1"><strong>{{ $t('general.you') }}</strong>
                                            </div>
                                            {{ user_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(user_comment.created_at) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-messages p-4" v-else>
                                <div class="alert alert-warning alert-dismissible" role="alert">
                                    <div class="alert-icon">
                                        <i class="far fa-fw fa-bell"></i>
                                    </div>
                                    <div class="alert-message">
                                        <strong>{{ $t('page.project_detail.no_comment') }}</strong> {{
                                            $t('page.project_detail.add_comment') }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-0 py-3 px-4 border-top">
                                <div class="input-group">
                                    <textarea v-model="commentFormDataUser.comment" class="form-control" rows="2"
                                        :placeholder="$t('page.project_detail.enter_message')"></textarea>
                                </div>
                                <div class="mb-3">
                                    <button @click="sendCommentTaskUser" class="btn btn-primary">
                                        {{ $t('general.send') }}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-send align-middle">
                                            <line x1="22" y1="2" x2="11" y2="13"></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3 col-xxl d-flex">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col mt-0">
                                        <h5 class="card-title">{{ $t('general.duration') }}</h5>
                                    </div>
                                    <div class="col-auto">
                                        <div class="stat" style="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"
                                                fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16">
                                                <path
                                                    d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                                <path
                                                    d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="mt-0 mb-1">{{ $formatDuration(project.stats.total_hours) }} </h4>
                                <div class="mb-0">
                                    <span class="text-muted">{{ $t('general.status') }} : </span>
                                    <span class="text-muted">
                                        <span v-if="project.status == 'Completed'" class="badge bg-success">{{
                                            project.status
                                        }}</span>
                                        <span v-else class="badge bg-warning">{{ project.status }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 col-xxl d-flex">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col mt-0">
                                        <h5 class="card-title">{{ $t('general.remaining_duration') }}</h5>
                                    </div>
                                    <div class="col-auto">
                                        <div class="stat" style="background: #F7931A; color: white;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                fill="currentColor" class="bi bi-train-freight-front"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M5.065.158A1.5 1.5 0 0 1 5.736 0h4.528a1.5 1.5 0 0 1 .67.158l3.237 1.618a1.5 1.5 0 0 1 .83 1.342V13.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V3.118a1.5 1.5 0 0 1 .828-1.342L5.065.158ZM2 9.372V13.5A1.5 1.5 0 0 0 3.5 15h4V8h-.853a.5.5 0 0 0-.144.021L2 9.372ZM8.5 15h4a1.5 1.5 0 0 0 1.5-1.5V9.372l-4.503-1.35A.5.5 0 0 0 9.353 8H8.5v7ZM14 8.328v-5.21a.5.5 0 0 0-.276-.447l-3.236-1.618A.5.5 0 0 0 10.264 1H5.736a.5.5 0 0 0-.223.053L2.277 2.67A.5.5 0 0 0 2 3.118v5.21l1-.3V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3.028l1 .3Zm-2-.6V5H8.5v2h.853a1.5 1.5 0 0 1 .431.063L12 7.728ZM7.5 7V5H4v2.728l2.216-.665A1.5 1.5 0 0 1 6.646 7H7.5Zm-1-5a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3Zm-3 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm9 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM5 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm7 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="mt-0 mb-1">{{ $formatDuration(project.stats.remaining_hours) }}</h4>

                                <div class="mb-0">
                                    <span class="text-muted text-primary">{{ project.stats.progress }}% {{
                                        $t('general.time') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 col-xxl d-flex">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col mt-0">
                                        <h5 class="card-title">{{ $t('general.date') }}</h5>
                                    </div>
                                    <div class="col-auto">
                                        <div class="stat" style="background: #345D9D; color: white;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                                                <path
                                                    d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                                <path
                                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="mt-0 mb-1">{{ $t('general.end') }}: {{ project.end_date ?
                                    $formatDate(project.end_date) : '-- -- ----- ' }}</h4>

                                <div class="mb-0">
                                    <span class="text-muted">{{ $t('general.start') }}: {{ project.start_date ?
                                        $formatDate(project.start_date) : '-- -- ----' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 col-xxl d-flex">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col mt-0">
                                        <h5 class="card-title">{{ $t('general.collaborators') }}</h5>
                                    </div>

                                    <div class="col-auto">
                                        <div class="stat" style="background: #627EEA; color: white;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="mt-0 mb-1">{{ project.stats.num_users }} {{ $t('general.collaborator') }}
                                </h4>

                                <div class="mb-0">
                                    <span class="text-muted">
                                        <span class="badge bg-success">
                                            {{ project.stats.completed_task }}
                                            {{ $t('page.project_detail.task_finished') }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="project.stats.is_delayed" class="alert alert-danger alert-dismissible" role="alert">
                    <div class="alert-icon">
                        <i class="far fa-fw fa-bell"></i>
                    </div>
                    <div class="alert-message">
                        <strong>{{ $t('page.project_detail.warning') }}</strong>
                        {{ $t('page.project_detail.project_critical') }}
                    </div>
                </div>
                <div class="modal fade" id="new-task-project-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content bg-light">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    <CustomButton buttonType="button"
                                        :buttonClass="isAdditional ? 'btn-primary' : 'btn-light'"
                                        :buttonText="$t('page.project_detail.new_task')"
                                        @click="changeServiceType('new')" class="me-2" />
                                    <CustomButton buttonType="button"
                                        :buttonClass="!isAdditional ? 'btn-primary' : 'btn-light'"
                                        :buttonText="$t('forms.additional_task')"
                                        @click="changeServiceType('additional')" class="me-2" />
                                </h5>
                                <button type="button" class="btn-close btn btn-danger" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="card p-4 bg-ligth">
                                    <div class="card-header">
                                        <h4>
                                            <span v-if="!isAdditional">{{ $t('page.project_detail.new_task') }}</span>
                                            <span v-if="isAdditional">{{ $t('forms.additional_task') }}</span>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <form @submit.prevent="saveTaskProject">
                                            <!-- Section 1 : Sélection de tâche si isAdditional est false -->
                                            <div v-if="!isAdditional" class="row">
                                                <div class="col-md-12 mb-3">
                                                    <label class="form-label">{{ $t('page.project_detail.select_task')
                                                        }}</label>
                                                    <select v-model="serviceFormData.service_task"
                                                        class="form-control choices-single-depends" required>
                                                        <option selected value="" class="text-muted">
                                                            {{ $t('page.project_detail.add_dependance') }}
                                                        </option>
                                                        <option v-for="_task in service_tasks" :key="_task.id"
                                                            :value="_task.id">{{ _task.name }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-12 mb-3">
                                                    <label class="form-label">{{ $t('page.project_detail.dependance')
                                                        }}</label>
                                                    <select v-model="serviceFormData.depend"
                                                        class="form-control choices-single-depends">
                                                        <option selected value="" class="text-muted">
                                                            {{ $t('page.project_detail.add_dependance') }}
                                                        </option>
                                                        <option v-for="s_task in tasks" :key="s_task.id"
                                                            :value="s_task.id">
                                                            {{ s_task.service_task.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <!-- Section 2 : Ajouter des tâches si isAdditional est true -->
                                            <div v-else class="row">
                                                <div class="col-md-12">
                                                    <div class="row mb-4" v-for="(serviceTask, key) in serviceTaskFormData" :key="key">
                                                        <!-- Nom de la tâche -->
                                                        <div class="col-md-6 mb-3">
                                                            <label class="form-label">
                                                                {{ $t('page.project_detail.task_name') }}
                                                                <i
                                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i>
                                                            </label>
                                                            <input type="text" class="form-control"
                                                                v-model="serviceTask.name" placeholder="Task name"
                                                                required />
                                                            <div class="invalid-feedback">
                                                                {{ $t('page.project_detail.task_name') }}
                                                            </div>
                                                        </div>

                                                        <!-- Sévérité -->
                                                        <div class="col-md-6 mb-3">
                                                            <label class="form-label">
                                                                {{ $t('general.severity') }}
                                                                <i
                                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i>
                                                            </label>
                                                            <select class="form-select" v-model="serviceTask.severity"
                                                                required>
                                                                <option selected disabled value="">{{
                                                                    $t('general.choice') }}...</option>
                                                                <option value="Low">{{ $t('general.low') }}</option>
                                                                <option value="Medium">{{ $t('general.medium') }}
                                                                </option>
                                                                <option value="High">{{ $t('general.high') }}</option>
                                                            </select>
                                                            <div class="invalid-feedback">
                                                                {{ $t('page.project_detail.valid_severity') }}
                                                            </div>
                                                        </div>

                                                        <!-- Temps minimal -->
                                                        <div class="col-md-6 mb-3">
                                                            <label class="form-label">
                                                                {{ $t('forms.min_et') }}
                                                                <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    :title="$t('forms.min_et_description')"
                                                                    class="align-middle me-2 fas fa-fw fa-exclamation-circle">
                                                                </i>
                                                            </label>
                                                            <input type="number" v-model="serviceTask.minimal_time"
                                                                min="1" class="form-control" required />
                                                            <div class="invalid-feedback">
                                                                {{ $t('forms.min_et_description') }}
                                                            </div>
                                                        </div>

                                                        <!-- Temps maximal -->
                                                        <div class="col-md-6 mb-3">
                                                            <label class="form-label">
                                                                {{ $t('forms.max_et') }}
                                                                <i data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    :title="$t('forms.max_et_description')"
                                                                    class="align-middle me-2 fas fa-fw fa-exclamation-circle">
                                                                </i>
                                                            </label>
                                                            <input type="number" v-model="serviceTask.maximal_time"
                                                                min="1" class="form-control" required />
                                                            <div class="invalid-feedback">
                                                                {{ $t('forms.max_et_description') }}
                                                            </div>
                                                        </div>

                                                        <!-- Description -->
                                                        <div class="col-md-12 mb-3">
                                                            <label class="form-label">
                                                                {{ $t('general.description') }}
                                                                <i
                                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i>
                                                            </label>
                                                            <textarea class="form-control"
                                                                v-model="serviceTask.description" rows="3"
                                                                :placeholder="$t('general.description')" required>
                                                            </textarea>
                                                            <div class="invalid-feedback">
                                                                {{ $t('general.description') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Section 3 : Bouton Enregistrer -->
                                            <div class="row">
                                                <div class="col-sm-12 text-end">
                                                    <CustomButton buttonType="button" buttonSubmitType="submit"
                                                        buttonClass="btn-primary" :buttonText="$t('general.save')"
                                                        :isLoading="isStart"
                                                        :loadingText="$t('general.loading') + '...'" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="document-deadline-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content bg-light">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ $t('page.project_detail.document_deadline') }}</h5>
                                <button type="button" class="btn-close btn btn-danger me-4" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body m-3">
                                <form @submit.prevent="setDocumentDeadline" class="row g-3 needs-validation">
                                    <div class=" m-1">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="form-label">{{ $t('page.project_detail.deadline_date')
                                                        }}</label>
                                                    <input type="datetime-local"
                                                        v-model="documentDeadlineForm.date_deadline"
                                                        class="form-control" placeholder="Start date" required
                                                        :min="getMinDate()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 text-end">
                                        <CustomButton buttonType="button" buttonSubmitType="submit"
                                            buttonClass="btn-primary" :buttonText="$t('general.save')"
                                            :isLoading="isStart" :loadingText="$t('general.loading') + '...'" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" href="#tab-1" data-bs-toggle="tab" role="tab">{{
                                        $t('page.project_detail.list_tasks') }}
                                    </a>
                                </li>
                                <li class="nav-item"><a class="nav-link" href="#tab-2" data-bs-toggle="tab"
                                        role="tab">{{ $t('page.project_detail.list_documents') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#tab-3" data-bs-toggle="tab"
                                        role="tab">{{ $t('page.project_detail.project_details') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#tab-4" data-bs-toggle="tab"
                                        role="tab">{{ $t('page.project_detail.end_of_project') }}</a></li>
                            </ul>
                            <hr>
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab-1" role="tabpanel">
                                    <div v-if="project.stats.total_tasks <= 0" class="mb-3">
                                        <div class="alert alert-primary alert-outline alert-dismissible" role="alert">
                                            <div v-if="project.status != 'Pending'" class="alert-message">
                                                <h4 class="alert-heading">{{ $t('page.project_detail.information') }}
                                                </h4>
                                                <p>{{ $t('page.project_detail.project_launched') }}</p>
                                                <hr>
                                                <div class="btn-list">
                                                    <router-link class="btn btn-success border rounded"
                                                        :to="{ name: 'edit-task-config', params: { id: project.id } }">
                                                        {{ $t('page.project_detail.click') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div v-else class="alert-message">
                                                <h4 class="alert-heading">{{ $t('page.project_detail.information') }}
                                                </h4>
                                                <p>{{ $t('page.project_detail.configure') }}</p>
                                                <hr>
                                                <div class="btn-list">
                                                    <button class="btn btn-primary border rounded"
                                                        data-bs-toggle="modal" data-bs-target="#add-manager-modal"
                                                        :disabled="project.stats.total_tasks === 0">{{
                                                            $t('page.project_detail.click_validate') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="card-header">
                                            <h5 class="card-title mb-0">{{ $t('page.project_detail.list_of_tasks') }}
                                            </h5>
                                        </div>
                                        <table class="table table-hover my-0" style="width: 100%"
                                        >
                                            <thead>
                                                <tr>
                                                    <th class="d-md-table-cell">#</th>
                                                    <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                                    <th class="d-xl-table-cell">{{ $t('general.assignee') }}</th>
                                                    <th class="d-xl-table-cell"
                                                        :title="$t('page.project_detail.title_nber_rejet')">{{
                                                            $t('page.project_detail.nber_rejet') }}</th>
                                                    <th class="d-xl-table-cell">{{
                                                        $t('page.project_detail.validated_date') }}/{{
                                                            $t('general.supervisor') }}</th>
                                                    <th>{{ $t('general.status') }}</th>
                                                    <th>{{ $t('general.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="task in tasks" :key="task.id">
                                                    <td>
                                                        <div class="form-check form-switch">
                                                            <input
                                                                title="Do not perform this task for this project anymore"
                                                                v-if="task.status != 'Remove'"
                                                                @click="removeOrUseTask(task.id, 'Remove')"
                                                                class="form-check-input" type="checkbox" checked>
                                                            <input
                                                                title="Add this task back to the task list for execution"
                                                                v-else @click="removeOrUseTask(task.id, 'Pending')"
                                                                class="form-check-input" type="checkbox">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h4>{{ task.service_task.name }}</h4>
                                                        <small>
                                                            <strong>E: {{ task.end_date ?
                                                                $formatDateWithTime(task.end_date) : '-- -- ----'
                                                                }}</strong>
                                                            <div class="text-muted">S: {{ task.start_date ?
                                                                $formatDateWithTime(task.start_date) : '-- -- ----' }}
                                                            </div>
                                                        </small>
                                                    </td>
                                                    <td class="d-xl-table-cell">
                                                        {{ task.assigne ? task.assigne?.name : 'Not assigne' }}<br>
                                                        {{ $formatDuration(task.estimated_duration) }}
                                                    </td>
                                                    <td class="d-md-table-cell">
                                                        <span class="badge bg-info">{{ task.number_rejected }}</span>
                                                    </td>
                                                    <td class="d-md-table-cell"><span class="badge bg-secondary">{{
                                                        task.validated_date ?
                                                            $formatDateWithTime(task.validated_date) : 'No validate'
                                                            }}</span><br>
                                                        {{ task.supervisor ? task.supervisor?.name : 'No defined' }}
                                                    </td>
                                                    <td>
                                                        <div v-if="task.status == 'Completed'">
                                                            <span class="badge bg-success">{{ task.status.toUpperCase()
                                                                }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <div v-if="task.is_late">
                                                                <span class="badge bg-danger">{{
                                                                    task.status.toUpperCase()
                                                                }}</span>
                                                            </div>
                                                            <div v-else>
                                                                <span v-if="task.status == 'Pending'"
                                                                    class="badge bg-info">{{
                                                                        task.status.toUpperCase() }}</span>
                                                                <span v-if="task.status == 'In progress'"
                                                                    class="badge bg-warning">{{
                                                                        task.status.toUpperCase()
                                                                    }}</span>
                                                                <span v-if="task.status == 'Rejected'"
                                                                    class="badge bg-danger">{{ task.status.toUpperCase()
                                                                    }}</span>
                                                                <span v-if="task.status == 'On pause'"
                                                                    class="badge bg-warning">{{
                                                                        task.status.toUpperCase()
                                                                    }}</span>
                                                                <span v-if="task.status == 'Completed'"
                                                                    class="badge bg-success">{{
                                                                        task.status.toUpperCase()
                                                                    }}</span>
                                                                <span v-if="task.status == 'Remove'"
                                                                    class="badge bg-danger">{{ task.status.toUpperCase()
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="d-md-table-cell">
                                                        <router-link class="text-hover" title="View details"
                                                            :to="{ name: 'task-detail', params: { id: task.id } }">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="feather feather-eye align-middle me-2 btn-link">
                                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z">
                                                                </path>
                                                                <circle cx="12" cy="12" r="3"></circle>
                                                            </svg>
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-2" role="tabpanel">
                                    <div v-if="project.stats.total_tasks <= 0" class="mb-3">
                                        <div class="alert alert-primary alert-outline alert-dismissible" role="alert">
                                            <div v-if="project.status != 'Pending'" class="alert-message">
                                                <h4 class="alert-heading">{{ $t('page.project_detail.information') }}
                                                </h4>
                                                <p>{{ $t('page.project_detail.project_launched') }}</p>
                                                <hr>
                                                <div class="btn-list">
                                                    <router-link class="btn btn-success border rounded"
                                                        :to="{ name: 'edit-task-config', params: { id: project.id } }">
                                                        {{ $t('page.project_detail.click') }}
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div v-else class="alert-message">
                                                <h4 class="alert-heading">{{ $t('page.project_detail.information') }}
                                                </h4>
                                                <p>{{ $t('page.project_detail.configure') }}</p>
                                                <hr>
                                                <div class="btn-list">
                                                    <button class="btn btn-primary border rounded"
                                                        data-bs-toggle="modal" data-bs-target="#add-manager-modal">{{
                                                            $t('page.project_detail.click_validate') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="card-header">
                                            <h5 class="card-title mb-0">{{ $t('page.project_detail.list_document') }}
                                            </h5>
                                        </div>
                                        <table class="table table-hover my-0" style="width: 100%"
                                        >
                                            <thead>
                                                <tr>
                                                    <th class="d-md-table-cell">{{ $t('general.id') }}</th>
                                                    <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                                    <th class="d-md-table-cell">{{
                                                        $t('page.project_detail.state_validation') }}</th>
                                                    <th class="d-md-table-cell"
                                                        title="Deadline for submission of documents">{{
                                                            $t('page.project_detail.deadline') }}</th>
                                                    <th class="d-xl-table-cell">{{ $t('general.action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="document in documents" :key="document.service_doc.id">
                                                    <td>
                                                        <i class="align-middle me-2 fas fa-fw fa-comment-alt"
                                                            @click="viewDocDescription(document.service_doc.id)"
                                                            data-bs-toggle="modal" data-bs-target="#decription-doc"></i>
                                                        AB0{{
                                                            document.service_doc.id }}
                                                    </td>
                                                    <td class="d-xxl-table-cell">
                                                        <div class="text-muted">
                                                            {{ document.service_doc.name }}
                                                        </div>
                                                        <span>
                                                            <span v-show="document.document != null"
                                                                class="badge bg-success">{{ $t('general.send') }}</span>
                                                            <span v-show="document.document == null"
                                                                class="badge bg-danger">{{ $t('general.not_send')
                                                                }}</span>
                                                        </span>
                                                    </td>
                                                    <td class="d-xxl-table-cell">
                                                        <div class="form-check form-switch">
                                                            <span v-show="document.document != null">
                                                                <input @click="changeDocState(document.service_doc.id)"
                                                                    v-show="document?.document?.state"
                                                                    class="form-check-input" type="checkbox"
                                                                    id="flexSwitchCheckChecked" checked="">
                                                                <input @click="changeDocState(document.service_doc.id)"
                                                                    v-show="!document?.document?.state"
                                                                    class="form-check-input" type="checkbox"
                                                                    id="flexSwitchCheckChecked">
                                                            </span>
                                                            <span v-show="document.document == null">
                                                                <input class="form-check-input" type="checkbox"
                                                                    disabled>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="d-xxl-table-cell">
                                                        <span
                                                            v-if="document.service_doc.documentdeadline.sending_deadline_date != null">
                                                            <span class="badge bg-primary me-2">
                                                                {{
                                                                    $formatDateWithTime(document.service_doc.documentdeadline.sending_deadline_date)
                                                                }}
                                                            </span>
                                                            <span v-if="$can('can_set_document_send_deadline')"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#document-deadline-modal"
                                                                class="btn-link updateDocDeadlineId"
                                                                :data-id="document.service_doc.documentdeadline.id"
                                                                :data-deadline="formatDateTimeLocal(document.service_doc.documentdeadline.sending_deadline_date)">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-edit-2 align-middle"
                                                                    :data-id="document.service_doc.documentdeadline.id"
                                                                    :data-deadline="formatDateTimeLocal(document.service_doc.documentdeadline.sending_deadline_date)">
                                                                    <path
                                                                        d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span v-else>
                                                            {{ $t('forms.not_defined') }}
                                                            <button v-if="$can('can_set_document_send_deadline')"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#document-deadline-modal"
                                                                :data-id="document.service_doc.documentdeadline.id"
                                                                class="badge btn btn-sm btn-secondary getDocDeadlineId">
                                                                <span
                                                                    :data-id="document.service_doc.documentdeadline.id"
                                                                    class="text-warning">({{ $t('forms.add') }})
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </td>
                                                    <td class="table-action">
                                                        <span @click="downloadFile_(document.document.id, 'document')"
                                                            v-show="document.document != null" class="btn btn-link"><i
                                                                class="align-middle me-2 fas fa-fw fa-download"></i>
                                                            <span class="align-middle">{{ $t('general.download')
                                                                }}</span></span>
                                                        <span v-show="document.document == null"><i
                                                                class="align-middle me-2 fas fa-fw fa-download"></i>
                                                            <span class="align-middle">{{ $t('general.download')
                                                                }}</span></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-3" role="tabpanel">
                                    <div class="container">
                                        <div class="row">
                                            <div class="card-header">
                                                <h5 class="card-title mb-0">
                                                    <div class="flex-grow-1">
                                                        <strong>{{ project.name }} ({{ project.service.name }})</strong>
                                                    </div>
                                                </h5>
                                            </div>
                                            <div class="p-2 h-100">
                                                <div class="d-flex pt-4 align-items-start">
                                                    <div class="flex-grow-1">
                                                        <strong>{{ $t('general.description') }}</strong><br />
                                                    </div>
                                                </div>
                                                <div class="card p-2 mt-1">
                                                    {{ project.description }}
                                                </div>
                                                <hr>
                                                <div class="d-flex align-items-start">
                                                    <div class="flex-grow-1">
                                                        <strong>{{ $t('general.manager') }}</strong>
                                                        <strong class="float-end text-navy">{{ project.manager?.name
                                                            }}</strong>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-start pt-2">
                                                    <div class="flex-grow-1">
                                                        <strong class="text-muted text-sm">{{
                                                            $t('page.project_detail.open_date') }}</strong>
                                                        <strong class="float-end text-navy"><span
                                                                class="badge bg-success">{{
                                                                    project.open_date ? project.open_date : '-- -- --'
                                                                }}</span></strong>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="row p-4 bg-light">
                                            <h5>{{ $t('page.project_detail.list_employe') }}</h5>
                                            <table class="table table-hover my-0" style="width: 100%"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                                        <th>{{ $t('general.state') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="projectUser in projectUsers" :key="projectUser.id">
                                                        <td>
                                                            {{ projectUser.name }}
                                                        </td>
                                                        <td>
                                                            <div class="form-check form-switch">
                                                                <input v-if="projectUser.state"
                                                                    :disabled="!$can('can_add_or_remove_partner_in_project')"
                                                                    @click="removeOrAddUserToProject(projectUser.id)"
                                                                    class="form-check-input" type="checkbox" checked>
                                                                <input
                                                                    :disabled="!$can('can_add_or_remove_partner_in_project')"
                                                                    v-else
                                                                    @click="removeOrAddUserToProject(projectUser.id)"
                                                                    class="form-check-input" type="checkbox">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-4" role="tabpanel">
                                    <div class="container">
                                        <div v-if="!project_report_docs.length" class="mb-3">
                                            <div class="alert alert-primary alert-outline alert-dismissible"
                                                role="alert">
                                                <div v-if="project.status != 'Pending'" class="alert-message">
                                                    <h4 class="alert-heading">{{ $t('page.project_detail.information')
                                                        }}</h4>
                                                    <p>{{ $t('page.project_detail.before_marking') }}</p>
                                                    <hr>
                                                    <div class="btn-list">
                                                        <button data-bs-toggle="modal" data-bs-target="#add-report-doc"
                                                            @click="changeTypeReportDoc('provisional')"
                                                            class="btn btn-sm btn-primary">{{
                                                                $t('page.project_detail.sending') }}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <h5>{{ $t('page.project_detail.report_list') }}</h5>
                                            <button v-if="getDocReportType('provisional').status == 'Rejected'"
                                                data-bs-toggle="modal" data-bs-target="#add-report-doc"
                                                @click="changeTypeReportDoc('provisional')"
                                                class="btn btn-sm btn-warning">{{ $t('page.project_detail.sending')
                                                }}</button>
                                            <button
                                                v-if="(getDocReportType('provisional').status == 'Validated') && ((getDocReportType('final')?.status != 'Rejected') || (getDocReportType('final')?.status != 'undefined'))"
                                                data-bs-toggle="modal" data-bs-target="#add-report-doc"
                                                @click="changeTypeReportDoc('final')" class="btn btn-sm btn-success">{{
                                                    $t('page.project_detail.rapport_final') }}</button>
                                            <button
                                                v-if="(getDocReportType('provisional').status == 'Validated') && ((getDocReportType('final')?.status == 'Validated'))"
                                                @click="closeProject()" class="btn btn-sm btn-success">{{
                                                    $t('page.project_detail.completed_project') }}</button>
                                            <table class="table table-hover my-0" style="width: 100%"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th class="d-md-table-cell">{{ $t('general.link') }}</th>
                                                        <th class="d-md-table-cell">{{ $t('general.type') }}</th>
                                                        <th class="d-md-table-cell">{{ $t('general.status') }}</th>
                                                        <th class="d-md-table-cell">{{ $t('general.action') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="project_report_doc in project_report_docs"
                                                        :key="project_report_doc.id">
                                                        <td>
                                                            {{ project_report_doc.permalink }}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-primary">{{ $t('page.project_detail.'
                                                                + project_report_doc.type) }}</span>
                                                        </td>
                                                        <td>
                                                            <span v-show="project_report_doc.status == 'Send'"
                                                                class="badge bg-info">{{
                                                                    $t('page.project_detail.' + project_report_doc.status)
                                                                }}</span>
                                                            <span v-show="project_report_doc.status == 'Validated'"
                                                                class="badge bg-success">{{
                                                                    $t('page.project_detail.' + project_report_doc.status)
                                                                }}</span>
                                                            <span v-show="project_report_doc.status == 'Rejected'"
                                                                class="badge bg-danger">{{
                                                                    $t('page.project_detail.' + project_report_doc.status)
                                                                }}</span>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm btn-primary"
                                                                data-bs-toggle="modal" data-bs-target="#add-report-doc"
                                                                @click="changeTypeReportDoc(project_report_doc.type)"
                                                                v-if="(project_report_doc.status != 'Validated') && ((project_report_doc.status != 'Rejected'))">
                                                                {{
                                                                    $t('general.edit') }}
                                                            </button>
                                                            <button v-else class="btn btn-sm btn-primary" disabled>
                                                                ...
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="add-manager-modal" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content bg-light">
                            <div class="modal-header">
                                <h5 class="modal-title">{{ $t('page.project_detail.validated_project') }}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body m-3">
                                <form @submit.prevent="validateProject" class="row g-3 needs-validation">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="form-label">
                                                <span>{{ $t('page.project_detail.select_manager') }}<i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></span>
                                            </label>
                                            <select v-model="supervisorFormData.manager"
                                                class="form-control choices-single-depends" required>
                                                <option selected value="" class="text-muted">{{
                                                    $t('page.project_detail.select_supervisor') }}
                                                </option>
                                                <option v-for="supervisor in supervisors" :key="supervisor.id"
                                                    :value="supervisor.id">{{ supervisor.name }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="form-label">
                                                <span>{{ $t('general.start_date') }}</span>
                                            </label>
                                            <input type="datetime-local" v-model="supervisorFormData.start_date"
                                                class="form-control" :placeholder="$t('general.start_date')" required
                                                :min="getMinDate()">
                                        </div>
                                    </div>
                                    <div class="col-sm-10 text-end">
                                        <CustomButton buttonType="button" buttonSubmitType="submit"
                                            buttonClass="btn-primary" :buttonText="$t('general.save')"
                                            :isLoading="isStart" :loadingText="$t('general.loading') + '...'" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row mb-2 mb-xl-3">
                    <div class="col-auto d-sm-block">
                        <h3><strong>{{ $t('general.projects') }}</strong></h3>
                    </div>

                    <div class="col-auto ms-auto text-end mt-n1">
                        <button class="btn btn-outline-info my-1 me-2" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">{{
                                $t('page.project_detail.comments') }}</button>
                        <button class="btn btn-primary my-1" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">{{
                                $t('page.project_detail.the_report') }}</button>
                    </div>
                </div>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasRightLabel">{{ $t('page.project_detail.activities_comment') }}</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div class="card-body h-100">
                            <div v-if="partener_comments.length" class="chat-messages p-1">
                                <div v-for="partener_comment in partener_comments" :key="partener_comment.id">
                                    <div v-if="partener_comment.user.id != getCurrentUser.id"
                                        class="chat-message-right pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                                            <div class="font-weight-bold mb-1"><strong>
                                                    {{ partener_comment.user.name }}</strong>
                                            </div>
                                            {{ partener_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(partener_comment.created_at) }}</div>
                                        </div>
                                    </div>

                                    <div v-else class="chat-message-left pb-4">
                                        <div class="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                                            <div class="font-weight-bold mb-1"><strong>You</strong></div>
                                            {{ partener_comment.comment }}
                                            <div class="text-end small text-primary mt-2">{{
                                                formatCommentDate(partener_comment.created_at) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-messages p-4" v-else>
                                <div class="alert alert-warning alert-dismissible" role="alert">
                                    <div class="alert-icon">
                                        <i class="far fa-fw fa-bell"></i>
                                    </div>
                                    <div class="alert-message">
                                        <strong>{{ $t('page.project_detail.no_comment') }}</strong> {{
                                            $t('page.project_detail.add_comment') }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-0 py-3 px-4 border-top">
                                <div class="input-group">
                                    <textarea v-model="commentFormDataPartener.comment" class="form-control" rows="2"
                                        placeholder="Enter your comment ..."></textarea>
                                </div>
                                <div class="mb-3">
                                    <button @click="sendCommentTaskPartener" class="btn btn-primary">
                                        {{ $t('general.send') }}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-send align-middle">
                                            <line x1="22" y1="2" x2="11" y2="13"></line>
                                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card">
                        <div class="card-header">

                            <h5 class="card-title mb-0">
                                <div class="flex-grow-1">
                                    <strong>{{ project.name }} ({{ project.service.name }})</strong>
                                </div>
                            </h5>
                        </div>
                        <div class="card-body h-100">
                            <div class="d-flex align-items-start">
                                <div class="flex-grow-1">
                                    <strong>{{ $t('general.description') }}</strong><br />
                                </div>
                            </div>
                            <div class="card p-2 mt-1">
                                {{ project.description }}
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div class="row" v-if="project.leader == getCurrentUser.id">
                        <div class="row mt-5">
                            <h5>{{ $t('page.project_detail.list_employe') }}</h5>
                            <table class="table table-hover my-0" style="width: 100%">
                                <thead>
                                    <tr>
                                        <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                        <th>{{ $t('general.state') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="projectUser in projectUsers" :key="projectUser.id">
                                        <td>
                                            {{ projectUser.name }}
                                        </td>
                                        <td>
                                            <div class="form-check form-switch">
                                                <input v-if="projectUser.state"
                                                    @click="removeOrAddUserToProject(projectUser.id)"
                                                    class="form-check-input" type="checkbox" checked>
                                                <input v-else @click="removeOrAddUserToProject(projectUser.id)"
                                                    class="form-check-input" type="checkbox">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="card flex-fill p-4">
                            <div class="card-header">

                                <h5 class="card-title mb-0">{{ $t('page.project_detail.documents') }}</h5>
                            </div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th class="d-md-table-cell">{{ $t('general.id') }}</th>
                                        <th class="d-md-table-cell">{{ $t('general.name') }}</th>
                                        <th class="d-md-table-cell">{{ $t('page.project_detail.state_validation') }}
                                        </th>
                                        <th class="d-xl-table-cell">{{ $t('general.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="document in documents" :key="document.id">
                                        <td><i class="align-middle me-2 fas fa-fw fa-comment-alt"
                                                @click="viewDocDescription(document.service_doc.id)"
                                                data-bs-toggle="modal" data-bs-target="#decription-doc"></i> AB0{{
                                                    document.service_doc.id }}</td>
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ document.service_doc.name }}
                                            </div>
                                            <span>
                                                <span v-show="document.document != null" class="badge bg-success">{{
                                                    $t('general.send') }}</span>
                                                <span v-show="document.document == null" class="badge bg-danger">{{
                                                    $t('general.not_send') }}</span>
                                            </span>
                                        </td>
                                        <td class="d-xxl-table-cell">
                                            <div class="form-check form-switch">
                                                <input v-show="document?.document?.state" class="form-check-input"
                                                    type="checkbox" disabled id="flexSwitchCheckChecked" checked="">
                                                <input v-show="!document?.document?.state" class="form-check-input"
                                                    type="checkbox" disabled id="flexSwitchCheckChecked">
                                            </div>
                                        </td>
                                        <td class="table-action">
                                            <span v-show="!document?.document?.state">
                                                <button v-if="document.document != null"
                                                    @click="viewDocDescription(document.service_doc.id, 'update')"
                                                    data-bs-toggle="modal" data-bs-target="#add-doc"
                                                    class="btn btn-sm btn-primary rounded" title="Add file"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-edit-2 align-middle">
                                                        <path
                                                            d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                        </path>
                                                    </svg>{{ $t('page.project_detail.update_file') }}</button>
                                                <button v-else @click="viewDocDescription(document.service_doc.id)"
                                                    data-bs-toggle="modal" data-bs-target="#add-doc"
                                                    class="btn btn-sm btn-primary rounded" title="Add file"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-edit-2 align-middle">
                                                        <path
                                                            d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                        </path>
                                                    </svg>{{ $t('page.project_detail.add_file') }}</button>
                                            </span>
                                            <span v-show="document?.document?.state">
                                                <button class="btn btn-sm btn-info">........</button>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom"
                    aria-labelledby="offcanvasBottomLabel">
                    <div class="offcanvas-header">
                        <h5 id="offcanvasBottomLabel">{{ $t('page.project_detail.the_report') }}</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div>
                            <table class="table table-hover my-0" style="width: 100%">
                                <thead>
                                    <tr>
                                        <th class="d-md-table-cell">{{ $t('general.link') }}</th>
                                        <th class="d-md-table-cell">{{ $t('general.type') }}</th>
                                        <th class="d-md-table-cell">{{ $t('general.status') }}</th>
                                        <th class="d-md-table-cell">{{ $t('general.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="project_report_doc in project_report_docs" :key="project_report_doc.id">
                                        <td>
                                            {{ project_report_doc.permalink }}
                                        </td>
                                        <td>
                                            <span class="badge bg-primary">{{ $t('page.project_detail.' +
                                                project_report_doc.type) }}</span>
                                        </td>
                                        <td>
                                            <span v-show="project_report_doc.status == 'Send'" class="badge bg-info">{{
                                                $t('page.project_detail.' + project_report_doc.status) }}</span>
                                            <span v-show="project_report_doc.status == 'Validated'"
                                                class="badge bg-success">{{ $t('page.project_detail.' +
                                                project_report_doc.status) }}</span>
                                            <span v-show="project_report_doc.status == 'Rejected'"
                                                class="badge bg-danger">{{ $t('page.project_detail.' +
                                                project_report_doc.status) }}</span>
                                        </td>
                                        <td>
                                            <span @click="downloadFile_(project_report_doc.id, 'report_doc')"
                                                class="btn btn-link"><i
                                                    class="align-middle fas fa-fw fa-download"></i></span>
                                            <span
                                                v-if="(project_report_doc.status == 'Send') && (project_report_doc.type == 'provisional')">
                                                <button class="btn btn-sm btn-primary me-2"
                                                    v-if="$can('can_set_final_report_status')"
                                                    @click="validateOrRejectedReportDoc(project_report_doc.type, 'Validated')">{{
                                                    $t('page.project_detail.confirm') }}</button>
                                                <button class="btn btn-sm btn-danger"
                                                    v-if="$can('can_set_final_report_status')"
                                                    @click="validateOrRejectedReportDoc(project_report_doc.type, 'Rejected')">{{
                                                    $t('page.project_detail.reject') }}</button>
                                            </span>
                                            <span
                                                v-else-if="(project_report_doc.status == 'Send') && (project_report_doc.type == 'final')">
                                                <button class="btn btn-sm btn-primary me-2" data-bs-toggle="modal"
                                                    data-bs-target="#completed-project-modal"
                                                    v-if="$can('can_set_final_report_status')"
                                                    @click="changeTypeReportDocCompleted(project_report_doc.type)">{{
                                                    $t('page.project_detail.confirm') }}</button>
                                                <button class="btn btn-sm btn-danger"
                                                    v-if="$can('can_set_final_report_status')"
                                                    @click="validateOrRejectedReportDoc(project_report_doc.type, 'Rejected')">{{
                                                    $t('page.project_detail.reject') }}</button>
                                            </span>
                                            <button v-else class="btn btn-sm btn-primary" disabled>...</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'Vide'" :message="$t('general.loading')"></empty-page-component>
        </div>
        <div class="modal fade" id="decription-doc" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-light">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('forms.document') }} : {{ doc_description?.service_doc?.name }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <form class="row g-3 needs-validation" novalidate>
                            <div class="card m-0">
                                <div class="card-body">
                                    <p>{{ doc_description?.service_doc?.description }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="add-doc" tabindex="-1" role="dialog" aria-hidden="true" ref="myModal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-light">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('forms.document') }} : {{ doc_description?.service_doc?.name }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <div class="card m-0">
                            <div class="card-body">
                                <form @submit.prevent="addDocument">
                                    <div class="mb-3">
                                        <label class="form-label w-100">{{ $t('page.project_detail.add_file') }}</label>
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg, .pdf, .docx, .xlsx, .xlsm, .xlsb, .xltx"
                                            ref="fileInput">
                                        <small class="form-text text-muted">{{ $t('page.project_detail.please')
                                            }}</small>
                                    </div>
                                    <div class="mb-3">
                                        <CustomButton buttonType="button" buttonSubmitType="submit"
                                            buttonClass="btn-primary" :buttonText="$t('general.submit')"
                                            :isLoading="isStart" :loadingText="$t('general.loading') + '...'" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="add-report-doc" tabindex="-1" role="dialog" aria-hidden="true" ref="myModal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-light">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('page.project_detail.project_report_document') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <div class="card m-0">
                            <div class="card-body">
                                <form @submit.prevent="addReportDocument">
                                    <div class="mb-3">
                                        <label class="form-label w-100">{{ $t('page.project_detail.add_file') }}</label>
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg, .pdf, .docx, .xlsx, .xlsm, .xlsb, .xltx"
                                            ref="fileInputReport">
                                        <small class="form-text text-muted">{{ $t('page.project_detail.please')
                                            }}</small>
                                    </div>
                                    <div class="mb-3">
                                        <CustomButton buttonType="button" buttonSubmitType="submit"
                                            buttonClass="btn-primary" :buttonText="$t('general.submit')"
                                            :isLoading="isStart" :loadingText="$t('general.loading') + '...'" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="completed-project-modal" tabindex="-1" role="dialog" aria-hidden="true"
            ref="myModal">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-light">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('page.project_detail.project_report_document') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body m-3">
                        <form @submit.prevent="completedProject">
                            <div class="text-info mb-4 pb-4">{{ $t('page.project_detail.be_sure') }}</div>
                            <div class="mb-3">
                                <input type="range" id="note" name="note" min="0" max="20"
                                    v-model="completedFormData.point">
                                <span id="selected-note">{{ completedFormData.point }}</span>
                            </div>
                            <div class="mb-3">
                                <CustomButton buttonType="button" buttonSubmitType="submit" buttonClass="btn-primary"
                                    :buttonText="$t('general.submit')" :isLoading="isStart"
                                    :loadingText="$t('general.loading') + '...'" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import CustomButton from '@/components/CustomButton.vue';
import $ from 'jquery'

export default {
    name: 'ProjectDetail',
    components: {
        EmptyPageComponent,
        CustomButton
    },
    data() {
        return {
            project: null,
            tasks: null,
            user: {},
            isDispo: false,
            documents: {},
            user_comments: [],
            partener_comments: [],
            doc_description: {},
            service_tasks: [],
            supervisors: [],
            addServiceFormData: {
                name: null,
                description: null
            },
            serviceFormData: {
                service_task: null,
                depend: null,
                project: null,
                is_validated: false
            },
            isStart: false,
            isAdditional: false,
            commentFormDataPartener: {
                user: null,
                activity_id: this.$route.params.id,
                type: 'p-partener',
                comment: null
            },
            commentFormDataUser: {
                user: null,
                activity_id: this.$route.params.id,
                type: 'p-user',
                comment: null
            },
            documentFormData: {
                service_doc: null,
                partener: null,
                project: null
            },
            serviceTaskFormData: [
                {
                    service: null,
                    creator: null,
                    name: null,
                    description: null,
                    severity: 'Low',
                    minimal_time: 1,
                    maximal_time: 2,
                    status: 'archive'
                }
            ],
            supervisorFormData: {
                manager: null,
                validator: null,
                project: this.$route.params.id,
                date_assigned: null,
                status: 'In progress',
                start_date: null,
                end_date: null,
                open_date: null
            },
            projectUsers: [],
            documentDeadlineForm: {
                id: null,
                date_deadline: null
            },
            project_report_docs: [],
            reportDocFormData: {
                project: this.$route.params.id,
                type: null
            },
            completedFormData: {
                id: this.$route.params.id,
                type: null,
                point: 0,
                status: 'Validated'
            }
        };
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser'])
    },
    async mounted() {
        if (!this.getCurrentUser) {
            await this._currentUser()
        }
        this.initProjectDetails(),
            this.initProjectReportDocs()
        $(document).on('click', '.updateDocDeadlineId', (e) => {
            let id = e.target.getAttribute('data-id')
            let date = e.target.getAttribute('data-deadline')
            this.documentDeadlineForm.id = id
            this.documentDeadlineForm.date_deadline = date
        })
        $(document).on('click', '.getDocDeadlineId', (e) => {
            let id = e.target.getAttribute('data-id')
            this.documentDeadlineForm.id = id
        })
    },
    methods: {
        ...mapActions('auth', ['_currentUser', 'getUsersByUserTypeName']),
        ...mapActions('project', [
            'getProject',
            'listDocuments',
            'addDocToProject',
            'updateDocToProject',
            'updateProject',
            'updateStateDoc',
            'getProjectListUsers',
            'updatePartnerState',
            'setDocDeadline',
            'getProjectReports',
            'addProjectReport',
            'updateProjectReportStatus',
            'updateProjectStatus'
        ]),
        ...mapActions('task_assignment', ['listProjectTasks', 'createTask', 'setTaskStatus']),
        ...mapActions('service_task', ['getServiceTasks', 'addServiceTask']),
        ...mapActions('service_doc', ['downloadFile']),
        ...mapActions('comment', ['getActivityComments', 'addComment']),
        goBack() {
            window.history.go(-1);
        },
        async closeProject() {
            Swal.fire({
                title: this.$t('page.projects.are_you_ready_to_close_project'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no')
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        project_id: this.$route.params.id,
                        new_status: 'Completed'
                    }

                    try {
                        this.updateProjectStatus(data)
                        this.$successNotyf(this.$t('page.projects.project_completed_success'))
                    } catch (error) {
                        this.$errorNotyf(error)
                    }
                }
            })
        },
        getMinDate() {
            const now = new Date()
            const year = now.getFullYear()
            const month = String(now.getMonth() + 1).padStart(2, '0')
            const day = String(now.getDate()).padStart(2, '0')
            const hours = String(now.getHours()).padStart(2, '0')
            const minutes = String(now.getMinutes()).padStart(2, '0')
            return `${year}-${month}-${day}T${hours}:${minutes}`
        },
        async addReportDocument() {
            this.isStart = true
            const fileInputReport = this.$refs.fileInputReport;
            if (fileInputReport.files.length === 0) {
                this.$errorNotyf({ 'message': this.$t('forms.please_select_file') })
                this.isStart = false
                return;
            }
            const file = fileInputReport.files[0];
            if (file.size > 100000000) {
                this.$errorNotyf({ 'message': this.$t('forms.file_size_accepted') })
                this.isStart = false
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            for (const key in this.reportDocFormData) {
                formData.append(key, this.reportDocFormData[key]);
            }
            try {
                let response = await this.addProjectReport(formData);
                this.$successNotyf(this.$t('forms.document_submit_success'))
                this.reportDocFormData.type = null;
                formData.delete('file');
                for (const key in this.reportDocFormData) {
                    formData.delete(key);
                }
                this.initProjectReportDocs();
                jQuery('#add-report-doc').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isStart = false;
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async validateOrRejectedReportDoc(type, status) {
            this.isStart = true
            let data = {
                id: this.$route.params.id,
                type: type,
                status: status
            }
            try {
                await this.updateProjectReportStatus(data)
                await this.initProjectReportDocs()
                this.$successNotyf(this.$t('forms.report_success') + ' ' + status)
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async completedProject() {
            this.isStart = true
            try {
                await this.updateProjectReportStatus(this.completedFormData)
                await this.initProjectReportDocs()
                jQuery('#completed-project-modal').modal('toggle'); // eslint-disable-line no-undef
                this.$successNotyf(this.$t('forms.report_successful_validated_and_completed'))
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        getDocReportType(type) {
            return this.project_report_docs.find(reportDoc => {
                return reportDoc.type === type;
            });
        },
        changeTypeReportDoc(type) {
            this.reportDocFormData.type = type
        },
        changeTypeReportDocCompleted(type) {
            this.completedFormData.type = type
        },
        formatDateTimeLocal(date) {
            return date ? moment(date).format("YYYY-MM-DD HH:mm") : null;
        },
        async initProjectReportDocs() {
            let response = await this.getProjectReports(this.$route.params.id)
            this.project_report_docs = response
        },
        async downloadFile_(id, type) {
            try {
                let data = {
                    doc_id: id,
                    type: type
                }
                await this.downloadFile(data)
                this.$successNotyf(this.$t('success.5'))
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async removeOrAddUserToProject(userId) {
            let data = {
                project_id: this.$route.params.id,
                user_id: userId
            }
            let response
            try {
                response = await this.updatePartnerState(data)
                this.$successNotyf(response.message)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async setDocumentDeadline() {
            this.isStart = true
            try {
                let response = await this.setDocDeadline(this.documentDeadlineForm)
                this.$successNotyf(response.message)
                this.documents = await this.listDocuments(this.$route.params.id)
                this.documentDeadlineForm.id = null
                this.documentDeadlineForm.date_deadline = null
                jQuery('#document-deadline-modal').modal('toggle'); // eslint-disable-line no-undef
                this.isStart = false
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
        },
        async validateProject() {
            this.isStart = true
            const currentDate = new Date();
            const formattedDate = this.formatDateTimeLocal(currentDate);
            this.supervisorFormData.open_date = this.supervisorFormData.start_date
            this.supervisorFormData.validator = this.getCurrentUser.id
            if (this.supervisorFormData.manager != null) {
                this.supervisorFormData.date_assigned = formattedDate
            }
            try {
                await this.updateProject(this.supervisorFormData)
                jQuery('#add-manager-modal').modal('toggle'); // eslint-disable-line no-undef
                this.project = await this.getProject(this.$route.params.id)
                this.supervisorFormData.manager = null
                this.supervisorFormData.validator = null
                this.supervisorFormData.date_assigned = null
                this.supervisorFormData.start_date = null
                this.supervisorFormData.end_date = null
                this.supervisorFormData.open_date = null
                this.$successNotyf(this.$t('success.4'))
            } catch (error) {
                this.isSatrt = false
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async changeDocState(service_doc) {
            let dataForm = {
                project: this.$route.params.id,
                service_doc: service_doc
            }
            try {
                let response = await this.updateStateDoc(dataForm)
                this.$successNotyf(response.message)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async initProjectDetails() {
            try {
                let project_id = this.$route.params.id
                this.project = await this.getProject(project_id)
                if (this.getCurrentUser.user_type.name != 'Partener') {
                    this.projectTasks(project_id)
                    await this.initComments(project_id)
                    this.supervisors = await this.getUsersByUserTypeName('Supervisor')
                    this.documents = await this.listDocuments(project_id)
                    this.projectUsers = await this.getProjectListUsers(project_id)
                } else {
                    if (this.project.leader == this.getCurrentUser.id) {
                        this.projectUsers = this.getProjectListUsers(project_id)
                    }
                    this.documents = await this.listDocuments(project_id)
                    let data1 = {
                        activity_id: project_id,
                        type: 'p-partener'
                    }
                    this.partener_comments = await this.getActivityComments(data1)
                }
                this.supervisorFormData.manager = this.project.manager?.id
                this.supervisorFormData.start_date = this.project.start_date ? this.formatDateTimeLocal(this.project.start_date) : null
                this.supervisorFormData.end_date = this.project.end_date ? this.formatDateTimeLocal(this.project.end_date) : null
                this.isDispo = true
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async formatServiceTasks(serviceId) {
            let response = await this.getServiceTasks(serviceId)
            this.service_tasks = response
            this.service_tasks = this.service_tasks.filter(task => {
                return !this.tasks.some(item => item.service_task.id === task.id);
            });

        },
        changeServiceType(type) {
            if (type == 'new') {
                this.isAdditional = false
            } else {
                this.isAdditional = true
            }
        },
        viewDocDescription(id) {
            this.doc_description = this.documents.find(element => element.service_doc.id === id);
        },
        async projectTasks(projectId) {
            this.tasks = await this.listProjectTasks(projectId)
            this.formatServiceTasks(this.project.service.id)
        },
        async initComments(projectId) {
            let data = {
                activity_id: projectId,
                type: 'p-user'
            }
            this.user_comments = await this.getActivityComments(data)
            let data1 = {
                activity_id: projectId,
                type: 'p-partener'
            }
            this.partener_comments = await this.getActivityComments(data1)
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        formatCommentDate(date) {
            let formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
            return formattedDate.format('DD MMM YYYY H:mm');
        },
        async sendCommentTaskUser() {
            this.commentFormDataUser.user = this.getCurrentUser.id
            try {
                await this.addComment(this.commentFormDataUser)
                this.commentFormDataUser.comment = null

                let data = {
                    activity_id: this.$route.params.id,
                    type: 'p-user'
                }
                this.user_comments = await this.getActivityComments(data)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async sendCommentTaskPartener() {
            this.commentFormDataPartener.user = this.getCurrentUser.id
            try {
                await this.addComment(this.commentFormDataPartener)
                this.commentFormDataPartener.comment = null

                let data1 = {
                    activity_id: this.$route.params.id,
                    type: 'p-partener'
                }
                this.partener_comments = await this.getActivityComments(data1)
            } catch (error) {
                this.$errorNotyf(error)
            }
        },
        async addDocument() {
            this.isStart = true
            const fileInput = this.$refs.fileInput;
            if (fileInput.files.length === 0) {
                this.$errorNotyf({ 'message': this.$t('errors.error1') })
                return;
            }
            const file = fileInput.files[0];
            if (file.size > 5000000) {
                this.$errorNotyf({ 'message': this.$t('success.3') })
                return;
            }
            const documentFormData = {
                partener: this.getCurrentUser.id,
                project: this.$route.params.id,
                service_doc: this.doc_description.service_doc.id
            };
            const formData = new FormData();
            formData.append('file', file);
            for (const key in documentFormData) {
                formData.append(key, documentFormData[key]);
            }
            try {
                let text = ''
                if (this.doc_description.document != null) {
                    await this.updateDocToProject(formData);
                    text = 'updated'
                } else {
                    await this.addDocToProject(formData);
                    text = 'added'
                }
                this.$successNotyf('File successfully ' + text + '.')
                documentFormData.partener = null;
                documentFormData.project = null;
                documentFormData.service_doc = null;
                formData.delete('file');
                for (const key in documentFormData) {
                    formData.delete(key);
                }


                this.initProjectDetails();
                jQuery('#add-doc').modal('toggle'); // eslint-disable-line no-undef
            } catch (error) {
                this.isStart = false;
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async saveTaskProject() {
            try {
                this.isStart = true
                this.serviceFormData.project = this.project.id
                if (!this.isAdditional) {
                    await this.createTask(this.serviceFormData)
                } else {
                    for (let i = 0; i < this.serviceTaskFormData.length; i++) {
                        this.serviceTaskFormData[i].creator = this.getCurrentUser.id;
                        this.serviceTaskFormData[i].service = this.project.service.id;
                    }
                    let response = await this.addServiceTask(this.serviceTaskFormData)
                    this.serviceFormData.service_task = response[0].id
                    for (let i = 0; i < this.serviceTaskFormData.length; i++) {
                        this.serviceTaskFormData[i].creator = null
                        this.serviceTaskFormData[i].service = null
                        this.serviceTaskFormData[i].name = null
                        this.serviceTaskFormData[i].description = null
                        this.serviceTaskFormData[i].severity = 'Low'
                        this.serviceTaskFormData[i].minimal_time = 1
                        this.serviceTaskFormData[i].maximal_time = 2
                    }
                    await this.createTask(this.serviceFormData)
                }
                this.serviceFormData.project = null
                this.serviceFormData.service_task = null
                this.serviceFormData.depend = null
                jQuery('#new-task-project-modal').modal('toggle'); // eslint-disable-line no-undef
                this.$successNotyf(this.$t('success.2'))
                this.projectTasks(this.project.id)
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async removeOrUseTask(taskId, type) {
            let data = {
                task_id: taskId,
                status: type
            }
            try {
                await this.setTaskStatus(data)
                this.projectTasks(this.project.id)
                this.$successNotyf(this.$t('success.1'))
            } catch (error) {
                this.$errorNotyf(error)
            }
        }
    }
}
</script>
<style>
input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background-color: #ddd;
    border-radius: 10px;
    outline: none;
    margin-bottom: 10px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    height: 30px;
    width: 30px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

#selected-note {
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
}
</style>