<template>
  <div class="container mt-5">
    <div v-if="isDispo">
      <div class="card">
        <div class="row mt-2 mb-1 m-1">
          <div class="col-auto d-sm-block">
            <h1>{{ $t('page.statistiques.notification') }}</h1>
          </div>
          <div class="col-auto ms-auto text-end mt-n1">
            <button @click="goBack" class="btn btn-secondary" title="Back"><i
                class="align-middle fas fa-fw fa-arrow-left"></i></button>
          </div>
        </div>
      </div>
      <div>
        <div v-if="selectedNotificationIds.length">
          <button class="btn btn-outline-danger" @click="deleteSelectedNotifications">
            <i class="align-middle fas fa-fw fa-trash"></i> {{ $t('general.delete') }}
          </button>
          <hr>
        </div>
        <div class="card">
          <div class="card-body">
            <DatatableComponent :headers="tableHeaders" :data="notification_lists" :paginable="true"
              :currentPage="currentPage" :totalPage="totalPage" :isSearchable="true" :filters="tableFilters"
              :reloadFunction="initUserNotifications" :isLoading="isLoading" :multipleActions="multipleActions" />
          </div>
        </div>
        <div v-if="showDetailsModal" class="modal" tabindex="-1" role="dialog"
          style="display: block; background-color: rgba(0,0,0,0.5);">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <!-- Header de la modal -->
              <div class="modal-header bg-primary text-white">
                <h5 class="modal-title">
                  <!-- Titre dynamique basé sur la notification -->
                  {{ selectedNotification.title || $t('page.statistiques.details_de_la_notification') }}
                </h5>
                <button type="button" class="btn-close" @click="closeDetailsModal" aria-label="Close"></button>
              </div>

              <!-- Contenu principal de la modal -->
              <div class="modal-body">
                <!-- Date de la notification -->
                <div class="mb-3">
                  <p>
                    <strong>{{ $t('page.statistiques.date') }} :</strong>
                    <span class="text-muted">{{ $formatDate(selectedNotification.created_at) }}</span>
                  </p>
                </div>

                <!-- Type de notification -->
                <div class="mb-3">
                  <p>
                    <strong>{{ $t('page.statistiques.type') }} :</strong>
                    <span class="badge bg-info text-dark">{{ (selectedNotification.type?.replace('_', ' '))?.toUpperCase() }}</span>
                  </p>
                </div>

                <!-- Corps de la notification -->
                <div class="bg-light p-3 rounded">
                  <p class="mb-0 text-break">{{ selectedNotification.notification }}</p>
                </div>
              </div>

              <!-- Footer de la modal -->
              <div class="modal-footer d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" @click="closeDetailsModal">
                  {{ $t('page.statistiques.fermer') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader">
      <h3>{{ $t('evaluation.chargement') }}...</h3>
      <div class="mt-4 mb-4">
        <div class="spinner-grow text-primary me-2" role="status">
          <span class="visually-hidden">{{ $t('general.loading') }}...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import DatatableComponent from '@/components/General/DatatableComponent.vue';

export default {
  name: 'NotificationsPage',
  components: {
    DatatableComponent,
  },
  data() {
    return {
      notification_lists: [],
      showDetailsModal: false,
      selectedNotification: {},
      user_id: this.$route.params.id,
      selectedNotificationIds: [],
      isDispo: false,
      isLoading: false,
      isStart: false,
      currentPage: 1,
      totalPage: 1,
      tableHeaders: [
        { name: this.$t('page.statistiques.title'), key: 'title', type: 'key' },
        { name: this.$t('page.statistiques.type'), key: 'type', type: 'key', format: ((value) => (value.replace('_', ' ')).toUpperCase()), badge: 'bg-primary' },
        { name: this.$t('page.statistiques.status'), key: 'is_see', type: 'key', format: ((value) => value ? 'Lu' : 'Non lu'), badge: 'bg-info' },
        { name: this.$t('page.statistiques.date'), key: 'created_at', type: 'key', format: this.$formatDate },
        {
          name: this.$t('general.action'),
          type: 'action',
          actions: [
            { type: 'view', text: 'Modifier', function: this.showDetails, show_text: false, check_permission: () => true },
            { type: 'delete', text: 'Supprimer', function: this.loadDeleteData, show_text: false, check_permission: () => true },
          ]
        },
      ],
      tableFilters: [
      ],
      multipleActions: [
        {
          title: this.$t('page.notification.delete_selected'),
          type: 'delete',
          function: this.deleteSelectedNotifications,
          color: 'danger',
          is_loading: this.isStart
        },
      ],
    }
  },
  async mounted() {
    this.isDispo = false
    await this.initUserNotifications()
    this.isDispo = true
  },
  methods: {
    ...mapActions('notification', ['notifications', 'setIsSee', 'deleteNotifications']),

    async initUserNotifications(options = { search: '', filters: {}, perPage: 10, page: 1 }) {
      this.isLoading = true
      options.filters = { ...options.filters, user_id: this.user_id };
      let response = await this.notifications(options)
      this.notification_lists = response.data || []
      this.currentPage = response.current_page || 1;
      this.totalPage = response.total_pages || 1;
      this.isLoading = false
    },
    updateSelectedNotifications(notificationId) {
      if (this.selectedNotificationIds.includes(notificationId)) {
        // Si l'ID est déjà dans la liste, le supprimer
        this.selectedNotificationIds = this.selectedNotificationIds.filter(id => id !== notificationId);
      } else {
        // Sinon, l'ajouter à la liste
        this.selectedNotificationIds.push(notificationId);
      }
    },
    loadDeleteData(notification) {
      this.deleteNotification({ notification_ids: [notification.id] })
    },
    deleteSelectedNotifications(selectedNotificationIds) {
      if (selectedNotificationIds.length === 0) {
        return;
      }
      const payload = { notification_ids: selectedNotificationIds };
      this.deleteNotification(payload)
    },
    async showDetails(notification) {
      this.selectedNotification = notification
      this.showDetailsModal = true
      if (!notification.is_see) {
        await this.setIsSee(notification.id)
        await this.initUserNotifications()
      }
    },
    async deleteNotification(data) {
      this.isStart = true
      Swal.fire({
        text: this.$t('general.delete_confirm'),
        title: this.$t('general.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.deleteNotifications(data);
            this.$successNotyf(this.$t('messages.n'));
            this.initUserNotifications();
            this.isStart = false;
          } catch (error) {
            this.isStart = false;
            this.$errorNotyf(error);
          }
        }
      });
    },

    goBack() {
      window.history.go(-1);
    },
    closeDetailsModal() {
      this.selectedNotification = {}
      this.showDetailsModal = false
    },
  }
}
</script>
<style>
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: #f8f8f8;
}

.loader h1 {
  font-size: 36px;
  margin-bottom: 5px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 7s steps(30), blink 0.5s step-end 3s forwards;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7986cb;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
</style>