<template>
	<main class="d-flex w-100 h-100">
		<div class="container d-flex flex-column">
			<div class="row vh-100">
				<div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
					<div class="d-table-cell align-middle">

						<div v-if="!isSend" class="text-center mt-4">
							<h1 class="h2">{{ $t('auth.reset_password') }}</h1>
							<p class="lead">
								{{ $t('auth.enter_email') }}
							</p>
						</div>

						<div class="card">
							<div class="card-body">
								<div v-if="!isSend" class="m-sm-4">
									<form @submit.prevent="resetMyPassword">
										<div class="mb-3">
											<label class="form-label">{{ $t('auth.email') }}</label>
											<input class="form-control form-control-lg" v-model="email" type="email" name="email" placeholder="Enter your email" />
										</div>
										<small>
											<router-link class="text-primary" :to="{ name: 'login'}">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left-circle align-middle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
												{{ $t('auth.back') }}
											</router-link>
										</small>
										<div class="text-center mt-3">
											<button class="btn btn-lg btn-primary">{{ $t('auth.reset_password') }}</button>
											<!-- <button type="submit" class="btn btn-lg btn-primary">Reset password</button> -->
										</div>
									</form>
								</div>
								<div v-else class="m-sm-4">
									<div class="alert alert-success alert-dismissible" role="alert">
										<div class="alert-icon">
											<i class="far fa-fw fa-bell"></i>
										</div>
										<div class="alert-message">
											<strong>{{ $t('auth.password_reset_successfully') }}</strong>
										</div>
									</div>
									<router-link class="text-primary" :to="{ name: 'login'}">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left-circle align-middle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
										{{ $t('auth.login') }}
									</router-link>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script>
import { mapActions } from 'vuex'
export default {
	name: 'PageResetPassword',
	data() {
		return {
			email: null,
			isStart: false,
			isSend: false
		};
	},
	methods: {
		...mapActions('auth', ['resetPassword']),
		async resetMyPassword() {
			this.isStart = true
			try {
                await this.resetPassword(this.email)
				this.$successNotyf(this.$t('errors.c') + ' ' + this.$t('errors.e'))
				this.isSend = true
            } catch (error) {
                this.$errorNotyf(error)
            }
			this.isStart = false
		},
	},
};
</script>