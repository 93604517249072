import axiosWithHeaders from '../services/api';

const state = {
    tickets: [],
    ticket: null,
    currentUser: null,
};

const getters = {
    getTickets: (state) => state.tickets,
    getTicket: (state) => state.ticket,
};

const mutations = {
    setCurrentUser(state, user) {
        state.currentUser = user
    },
    setTickets: (state, tickets) => {
        state.tickets = tickets;
    },
    setTicket: (state, ticket) => {
        state.ticket = ticket;
    },
    addTicket: (state, ticket) => {
        state.tickets.push(ticket);
    },
    updateTicket: (state, updatedTicket) => {
        const index = state.tickets.findIndex(ticket => ticket.id === updatedTicket.id);
        if (index !== -1) {
            state.tickets.splice(index, 1, updatedTicket);
        }
    },
};

const actions = {
    _currentUser({ commit }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('get-current-user')
                .then(response => {
                    commit('setCurrentUser', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },


    fetchTickets({ commit }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('tickets')
                .then(response => {
                    commit('setTickets', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },


    fetchTicket({ commit }, { id }) {
        return axiosWithHeaders.get(`ticket/${id}`);
    },


    getUserTickets({ commit }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('user-tickets')
                .then(response => {
                    commit('setTickets', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },


    createTicket({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.post('add-ticket', ticketData)
                .then(response => {
                    commit('addTicket', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },


    updateTicket({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-ticket/${ticketData.ticket_id}`, ticketData)
                .then(response => {
                    commit('updateTicket', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },


    assignTicketToUser({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`assign-ticket-to-user/${ticketData.ticket_id}/${ticketData.user_id}`, ticketData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    // assignTicketToUserType({ commit }, ticketData) {
    //     return new Promise((resolve, reject) => {
    //         axiosWithHeaders.put(`assign-ticket-to-user-type/${ticketData.ticket_id}/${ticketData.user_type_id}`, ticketData)
    //             .then(response => {
    //                 resolve(response.data);
    //             })
    //             .catch(error => {
    //                 reject(error.response.data);
    //             });
    //     });
    // },

    assignTicketToDepartment({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`assign-ticket-to-user-department/${ticketData.ticket_id}/${ticketData.department_id}`, ticketData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    getDepartments({ commit, dispatch, state }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('departments')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
    getAllDepartments({ commit, dispatch, state }) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get('all/departments')
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    reassignTicketToUser({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`reassign-ticket-to-user/${ticketData.ticket_id}/${ticketData.user_id}`, ticketData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },

    updateTicketStatus({ commit }, ticketData) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.put(`update-ticket-status/${ticketData.ticket_id}`, ticketData)
                .then(response => {
                    commit('updateTicket', response.data);
                    resolve(response.data);
                })
                .catch(error => reject(error.response.data));
        });
    },
    getTicketsByStatus({ commit }, status) {
        return new Promise((resolve, reject) => {
            axiosWithHeaders.get(`tickets-by-status/${status}`)
                .then(response => {
                    commit('setTickets', response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.response.data);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
