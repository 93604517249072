<template>
    <div class="container-fluid p-0">
        <h1 class="h3 mb-3">{{ $t('auth.profil') }}</h1>
        <div class="row">
            <div class="col-md-3 col-xl-2">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title mb-0">{{ $t('auth.profil_setting') }}</h5>
                    </div>
                    <div class="list-group list-group-flush" role="tablist">
                        <a class="list-group-item list-group-item-action active" data-bs-toggle="list" href="#account"
                            role="tab">
                            {{ $t('auth.account') }}
                        </a>
                        <a class="list-group-item list-group-item-action" data-bs-toggle="list" href="#password" role="tab">
                            {{ $t('auth.password') }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-xl-10">
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="account" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title mb-0">{{ $t('auth.private_info') }} ({{ userFormData.username }})</h5>
                            </div>
                            <div class="card-body">
                                <form @submit.prevent="updateUserProfile">
                                    <div class="row">
                                        <div class="mb-3 col-md-12">
                                            <label class="form-label" for="inputFirstName">{{ $t('auth.name') }}</label>
                                            <input type="text" v-model="userFormData.name" class="form-control" id="inputName"
                                                placeholder="Name">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" for="inputEmail4">{{ $t('auth.email') }}</label>
                                            <input type="email" v-model="userFormData.email" class="form-control" id="inputEmail4" placeholder="Email">
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" for="inputAddress">{{ $t('auth.phone') }}</label>
                                            <input type="text" v-model="userFormData.phone" class="form-control" id="inputAddress"
                                                placeholder="1234 Main St">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label" for="inputAddress2">{{ $t('auth.address') }}</label>
                                            <input type="text" v-model="userFormData.address" class="form-control" id="inputAddress2"
                                                placeholder="Apartment, studio, or floor">
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label for="validationCustom05" class="form-label">{{ $t('auth.country') }} <i
                                                    class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                            <select class="form-select choices-single-country" v-model="userFormData.country" id="validationCustom05" required>
                                                <option selected disabled value="">{{ $t('auth.choose') }}</option>
                                                <option v-for="country in countries" :key="country.id" :value="country.id" >{{ country.name }} ({{ country.code }})</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                {{ $t('auth.valid_country') }}
                                            </div>
                                        </div>
                                    </div>
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" type="submit" class="btn btn-primary">{{ $t('auth.save_changes') }}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="password" role="tabpanel">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">{{ $t('auth.password') }}</h5>
                                <form @submit.prevent="submitForm">
                                    <div class="mb-3">
                                        <label class="form-label" for="inputPasswordCurrent">{{ $t('auth.current_password') }}</label>
                                        <input type="password" v-model="passwordFormData.old_password" class="form-control" id="inputPasswordCurrent">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label" for="inputPasswordNew">{{ $t('auth.new_password') }}</label>
                                        <input type="password" v-model="passwordFormData.new_password" class="form-control" id="inputPasswordNew">
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label" for="inputPasswordNew2">{{ $t('auth.verify_password') }}</label>
                                        <input type="password" v-model="passwordFormData.confirm_password" class="form-control" id="inputPasswordNew2">
                                        <small v-if="passwordError" class="text-danger">{{ $t('auth.incorrect_password') }}</small>
                                    </div>
                                    <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                        <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                        <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                    </button>
                                    <button v-show="!isStart" type="submit" class="btn btn-primary">{{ $t('auth.save_changes') }}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment'
import { format } from 'date-fns';
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'UserProfile',
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
        passwordError() {
            return this.passwordFormData.new_password !== this.passwordFormData.confirm_password;
        }
    },
    data() {
        return {
            passwordFormData: {
                id: null,
                old_password: null,
                new_password: null,
                confirm_password: null
            },
            userFormData:{},
            isStart: false,
            countries:[]
        };
    },
    async mounted() {
        await this.initUser()
        await this.loadCountries()
        new Choices(document.querySelector(".choices-single-country")); // eslint-disable-line no-undef
    },
    methods: {
        ...mapActions('auth', ['_currentUser', 'updateUser', 'updatePassword', 'getCountries']),
        async initUser(){
            if(this.getCurrentUser){
                const { id, name, address, email, phone, username } = this.getCurrentUser
                this.userFormData = { id, name, address, email, phone, username, country: this.getCurrentUser?.country?.id }
            }
        },
        async loadCountries(){
            let response = await this.getCountries()
            this.countries = response
        },
        async updateUserProfile(){
            try {
                this.isStart = true
                await this.updateUser(this.userFormData)
                this.$successNotyf(this.$t('errors.b'))
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.isStart = false
        },
        async submitForm() {
            if (this.passwordFormData.new_password === this.passwordFormData.confirm_password) {
                this.isStart = true
                try {
                    this.passwordFormData.id = this.getCurrentUser.id
                    await this.updatePassword(this.passwordFormData)
                    this.$successNotyf(this.$t('errors.c'))
                    this.passwordFormData.new_password = null
                    this.passwordFormData.confirm_password = null
                    this.passwordFormData.old_password = null
                } catch (error) {
                    this.$errorNotyf(error)
                }
                this.isStart = false
            } else {
                this.$successNotyf(this.$t('errors.d'), this.$t('general.warning'))
            }
        }
    }
}
</script>
<style></style>
    