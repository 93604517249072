<template>
    <div>
        <div v-if="isDispo">
            <div class="row text-center container">
                <div class="alert" role="alert">
                    <div class="alert-message">
                        <h5>{{ this_today }}</h5>
                        <h1>Hello, {{ getCurrentUser.name }}</h1>
                        {{ $t('component.AuthPageComponent.UserPageComponent.manage_your') }}
                        <div class="row mt-4 rounded">
                            <div class="col-sm-3"></div>
                            <div class="card col-sm-6 pt-2 pb-2">
                                <div class="btn-group row">
                                    <div class="col-6">
                                        <select class="form-select" v-model="filterOptions.status" @change="initUserTasks">
                                            <option value="all">{{ $t('page.tasks.all_tasks') }}</option>
                                            <option value="In progress">{{ $t('general.in_progress') }}</option>
                                            <option value="Pending">{{ $t('general.pending') }}</option>
                                            <option value="Completed">{{ $t('general.completed') }}</option>
                                            <option value="On pause">{{ $t('general.on_pause') }}</option>
                                            <option value="Rejected">{{ $t('general.rejected') }}</option>
                                            <option value="Aborted">{{ $t('general.aborded') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 pt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-check2-all" viewBox="0 0 16 16">
                                            <path
                                                d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                        </svg>
                                        {{ countCompletedTasks() }}    {{ $t('component.AuthPageComponent.UserPageComponent.task_completed') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xxl-12 d-flex">
                    <div class="card flex-fill p-4">
                        <div class="card-header">
                            <h5 class="card-title mb-0">   {{ $t('component.AuthPageComponent.UserPageComponent.my_performances') }}</h5>
                        </div>
                        <div>
                            <table class="table table-hover my-0">
                                <thead>
                                    <tr>
                                        <th>   {{ $t('component.AuthPageComponent.UserPageComponent.task') }}</th>
                                        <th>   {{ $t('component.AuthPageComponent.UserPageComponent.project') }}</th>
                                        <th class="d-xl-table-cell">   {{ $t('component.AuthPageComponent.UserPageComponent.date') }}</th>
                                        <th class="d-xl-table-cell">{{ $t('general.duration') }}</th>
                                        <th>{{ $t('general.status') }}</th>
                                        <th>{{ $t('general.action') }}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="tasks.length && !isLoading">
                                    <tr v-for="task in tasks" :key="task.id">
                                        <td>{{ task.title }}</td>
                                        <td>{{ task?.project_name || '-' }}</td>
                                        <td class="d-xxl-table-cell">
                                            <div class="text-muted">
                                                {{ $t('general.end') }}: {{ task.end_date ? $formatDate(task.end_date) : '-' }}
                                            </div>
                                            <strong>{{ $t('general.start') }}: {{ task.start_date ? $formatDate(task.start_date) : '-' }}</strong>
                                        </td>
                                        <td class="d-xl-table-cell">{{ task.duration ? $formatDuration(task.duration) : '-' }}</td>
                                        <td>
                                            <span v-show="task.is_validated" class="badge bg-success"> {{ $t('component.AuthPageComponent.UserPageComponent.validated') }}</span>
                                            <span v-show="!task.is_validated" class="badge bg-warning"> {{ $t('component.AuthPageComponent.UserPageComponent.pending') }}</span>
                                        </td>
                                        <td class="d-md-table-cell">
                                            <router-link class="btn btn-link" title="View" :to="{ name: 'task-detail', params: { id: task.id }}">
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-eye align-middle btn-link">
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="!tasks.length && !isLoading">
                                    <tr>
                                        <td :colspan="6" class="text-center">
                                            <p>{{ $t('general.no_data') }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td :colspan="6" class="text-center">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                                            </div>
                                            <p>{{ $t('general.loading') }}...</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination v-if="tasks.length" class="mt-4"
                            :currentPage="paginateOptions.current_page"
                            :totalPages="paginateOptions.total_pages"
                            :previousLabel="$t('pagination.previous')"
                            :nextLabel="$t('pagination.next')"
                            @page-changed="fetchPage"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <empty-page-component :code="'...'" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>
    
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex'
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'
import Pagination from '@/components/General/Pagination.vue';

export default {
    name: 'UserPageComponent',
    data() {
        return {
            this_today: null,
            tasks: [],
            period: 'week',
            filterOptions: {
                page: 1,
                per_page: 10,
                search: '',
                user_id: null,
                status: 'all',
            },
            paginateOptions: {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 4,
            },
            isLoading: false,
            isDispo: false
        }
    },
    components: {
        EmptyPageComponent,
        Pagination
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUser']),
    },
    async mounted() {
        this.isDispo = false
        let date = moment();
        let formattedDate = date.format('dddd D MMMM YYYY');
        this.this_today = formattedDate
        await this.initUserTasks()
        this.isDispo = true
    },
    methods: {
        ...mapActions('task_assignment', ['getUserTasks']),
        async initUserTasks() {
            this.isLoading = true;
            this.filterOptions.user_id = this.getCurrentUser.id;
            let response = await this.getUserTasks(this.filterOptions)
            this.tasks = response.data;
            this.paginateOptions = response.pagination || {
                current_page: 1,
                total_pages: 1,
                total_items: 0,
                per_page: 10,
            };
            this.isLoading = false;
        },
        async fetchPage(page) {
            this.paginateOptions.current_page = page;
            this.filterOptions.page = page;
            await this.initUserTasks(); 
        },
        countCompletedTasks() {
            if (!Array.isArray(this.tasks)) {
                return 0;
            }
            const completedTasks = this.tasks.filter(task => task.status === 'Completed');
            return completedTasks.length;
        }
    }
}
</script>
<style></style>
    