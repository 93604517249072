<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div></div>
                            <div>
                                <div class="btn-group" role="group" aria-label="Navigation">
                                    <button type="button" :class="2 == currentPage ? 'active' : ''" class="btn btn-primary"
                                        @click="changePage(2)">{{ $t('label.8') }}</button>
                                    <button type="button" :class="1 == currentPage ? 'active' : ''" class="btn btn-primary me-2"
                                        @click="changePage(1)">{{ $t('label.9') }}</button>
                                    <button @click="goBack" class="btn btn-secondary" title="Back"><i class="align-middle fas fa-fw fa-arrow-left"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 1">
            <div class="row gy-5 g-xl-10 mb-5">
                <div class="col-lg-6 col-xl-6 col-xxl-6 mb-5 mb-xl-0">
                    <div class="card card-flush overflow-hidden h-md-100">
                    <div class="card-body d-flex text-center justify-content-center flex-column px-0">
                        <div class="chart-container card-body align-items-center justify-content-between justify-content-center" style="height: 450px; padding: 20px">
                        <canvas id="user-employe-chart"></canvas>
                        </div>
                        <div class="card-footer pt-0 mt-0 text-center">
                            <h3>{{ $t('page.statistiques.performance') }}</h3>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="currentPage === 2">
            <div class="row p-3">
                <div class="card p-3" v-show="show_tasks">
                    <div class="card-body">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <h5 class="card-title">{{ $t('page.statistiques.liste_tache') }}</h5>
                            <button type="button" class="btn btn-sm btn-danger"
                                @click="toggleTasks()">
                                -
                            </button>
                        </div>
                        <table v-show="tasks.length" class="table table-striped">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.statistiques.name') }}</th>
                                    <th>{{ $t('page.statistiques.assigne') }}</th>
                                    <th>{{ $t('page.statistiques.status') }}</th>
                                    <th title="Validated date">{{ $t('page.statistiques.validated') }}</th>
                                    <th title="Supervisor note">{{ $t('page.statistiques.note') }}</th>
                                    <th>{{ $t('page.statistiques.cost') }}</th>
                                    <th title="Estimated duration">{{ $t('page.statistiques.duration') }}</th>
                                    <th>{{ $t('page.statistiques.start_date') }}</th>
                                    <th>{{ $t('page.statistiques.end_date') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="task in tasks" :key="task.id">
                                    <td>{{ task.task_name }}</td>
                                    <td>{{ task.assigne ? task.assigne.name : 'No assigne' }}</td>
                                    <td>
                                        <span v-if="task.status == 'Completed'" class="badge bg-success">{{ task.status }}</span>
                                        <span v-else-if="task.status == 'In progress'" class="badge bg-primary">{{ task.status }}</span>
                                        <span v-else class="badge bg-warning">{{ task.status }}</span>
                                    </td>
                                    <td><span class="badge bg-secondary">{{ task.validated_date ? $formatDateDDMMYYYYHHMM(task.validated_date) : 'No validated' }}</span></td>
                                    <td>{{ task.point }}/20</td>
                                    <td>{{ formatNumber(task.task_cost) }}</td>
                                    <td>{{ task.estimated_duration }}   {{ $t('page.statistiques.days') }}</td>
                                    <td>{{ $formatDateWithTime(task.start_date) }}</td>
                                    <td>{{ $formatDateWithTime(task.end_date) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-show="!tasks.length">
                            <div class="alert alert-info alert-dismissible" role="alert">
                                <div class="alert-icon">
                                    <i class="far fa-fw fa-bell"></i>
                                </div>
                                <div class="alert-message">    
                                    <strong>{{ $t('page.statistiques.no_task') }}</strong>{{ $t('page.statistiques.project_no_task') }}
                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="!show_tasks" class="card p-4">
                    <h5 class="card-title">Liste des projets</h5>
                    <div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>{{ $t('page.statistiques.name') }}</th>
                                    <th>{{ $t('page.statistiques.status') }}</th>
                                    <th>{{ $t('page.statistiques.cost') }}</th>
                                    <th title="Customer note">{{ $t('page.statistiques.note') }}</th>
                                    <th>{{ $t('page.statistiques.start_date') }}</th>
                                    <th>{{ $t('page.statistiques.end_date') }}</th>
                                    <th>{{ $t('page.statistiques.nombre_tache') }}</th>
                                    <th>{{ $t('page.statistiques.action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in projects" :key="project.id">
                                    <td>{{ project.project.name }}</td>
                                    <td>
                                        <span v-if="project.project.status == 'Completed'" class="badge bg-success">{{ project.project.status }}</span>
                                        <span v-else-if="project.project.status == 'In progress'" class="badge bg-primary">{{ project.project.status }}</span>
                                        <span v-else class="badge bg-warning">{{ project.project.status }}</span>
                                    </td>
                                    <td>{{ formatNumber(project.total_cost) }}</td>
                                    <td>{{ project.project.point }}/20</td>
                                    <td>{{ $formatDateWithTime(project.project.start_date) }}</td>
                                    <td>{{ $formatDateWithTime(project.project.end_date) }}</td>
                                    <td>
                                        <button v-if="show_tasks" type="button" class="btn btn-sm btn-danger"
                                            @click="toggleTasks(project)">
                                            <span >-</span>
                                        </button>
                                        <button v-else type="button" class="btn btn-sm btn-primary"
                                            @click="toggleTasks(project)">
                                            +
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Chart from 'chart.js/auto';
import 'flatpickr/dist/flatpickr.min.css';
import { reactive } from "vue";
export default {
    name: 'UserDetailStatistique',
    data() {
        return {
            statistiques: {},
            project_stats: {},
            user_select: 'all',
            top_user: 3,
            users: [],
            nb_tasks: null,
            cost_total: null,
            tasks_by_project: null,
            general: [],
            currentPage: 2,
            projects: [],
            tasks: [],
            show_tasks: false,
            topUser:[],
            topSupervisor: []
        }
    },
    async mounted() {
        await this.loadData()
    },
    methods: {
        ...mapActions('statistique', ['usersStats', 'topUserStats', 'topSupervisorStats']),
        async loadData() {
            let userStateResponse = await this.usersStats(this.$route.params.id)
            this.projects = userStateResponse.projects
            this.userDetail = userStateResponse
            this.drawCharts()
        },
        goBack() {
            window.history.go(-1);
        },
        toggleTasks(project=null) {
            this.show_tasks = !this.show_tasks;
            if (this.show_tasks) {
                this.tasks = project.tasks;
            } else {
                this.tasks = [];
            }
        },
        drawCharts() {
            this.drawUserTopChart()
        },
        drawUserTopChart() {
            if (this.userStaticChart !== undefined) {
                this.userStaticChart.destroy();
            }

            const includedKeys = ['total_points', 'total_projects', 'total_tasks', 'average_points'];
            const excludedKeys = ['projects', 'stats_by_status', 'user'];

            const data = Object.keys(this.userDetail)
                .filter(key => includedKeys.includes(key) && !excludedKeys.includes(key))
                .map(key => this.userDetail[key]);

            const labels = includedKeys.map(key => key.replace('_', ' '));

            const chartData = {
                labels: labels,
                datasets: [
                    {
                        type: 'line',
                        label: this.$t('label.1'),
                        borderColor: this.getRandomColor(),
                        fill: true,
                        data: data,
                    }
                ]
            }

            const chartOptions = {
                scales: {
                    y: {
                        beginAtZero: true,
                    }
                }
            }

            this.userStaticChart = new Chart(document.getElementById('user-employe-chart'), {
                type: 'line',
                data: chartData,
                options: chartOptions
            })
        },
        changePage(number) {
            this.currentPage = number
        },
        formatNumber(number) {
            const suffixes = ["", "K", "M", "B", "T"];
            const suffixNum = Math.floor(("" + number).length / 3);
            const shortNumber = parseFloat((suffixNum != 0 ? (number / Math.pow(1000, suffixNum)) : number).toPrecision(3));
            const suffix = suffixes[suffixNum];
            return shortNumber + suffix;
        },
        calculAvg(min, max) {
            if (max > 0) {
                let amount = ((min * this.statistiques.total_cost) / max)
                return ((amount * 100) / this.statistiques.total_cost)
            }
            return 0;
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
}
</script>
<style>
.div-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div-item {
    width: 100%;
}

.chart-container {
    height: 300px;
}
</style>