<template>
<div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 my-5">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h1 class="mt-4 mb-3">{{ $t('landingPage.bienvenu') }}</h1>
              <p>{{ $t('landingPage.configuration') }}</p>
            </div>
            <hr>
            <div class="progress my-3">
              <div class="progress-bar progress-bar-striped" role="progressbar"
                :style="{ width: currentStep === 1 ? '50%' : '100%' }"
                :aria-valuenow="currentStep === 1 ? 50 : 100"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <form v-if="currentStep === 1" @submit.prevent="nextStep">
              <div class="form-group">
                <label for="appName">{{ $t('landingPage.nom_application') }}</label>
                <input type="text" class="form-control" id="appName" v-model="appName" required>
              </div>
              <div class="form-group">
                <label for="email">{{ $t('landingPage.email_enterprise') }}</label>
                <input type="email" class="form-control" id="email" v-model="email" required>
              </div>
              <div class="form-group">
                <label for="address">{{ $t('landingPage.adresse_enterprise') }}</label>
                <input type="text" class="form-control" id="address" v-model="address" required>
              </div>
              <div class="form-group">
                <label for="phone">{{ $t('landingPage.num') }}</label>
                <input type="tel" class="form-control" id="phone" v-model="phone" required>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-secondary mr-3" disabled>{{ $t('general.previous') }}</button>
                <button type="submit" class="btn btn-primary">{{ $t('general.next') }}</button>
              </div>
            </form>
            <form v-else-if="currentStep === 2" @submit.prevent="nextStep" enctype="multipart/form-data">
              <div class="form-group">
                <label for="logo">{{ $t('new.message') }}</label>
                <input type="file" class="form-control-file" id="logo" @change="onFileChange" accept="image/*" required>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-secondary mr-3" @click="previousStep"></button>
                <button type="submit" class="btn btn-primary">{{ $t('landingPage.terminer_configuration') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1,
      appName: '',
      email: '',
      address: '',
      phone: '',
      logo: null,
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < 2) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    onFileChange(event) {
      this.logo = event.target.files[0];
    },
  },
};
</script>
