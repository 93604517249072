<template>
    <div class="container-fluid p-0">
        <div v-if="isDispo">
            <div class="row mb-2 mb-xl-3">
                <div class="col-auto d-none d-sm-block">
                    <h3><strong>{{ $t('page.parteners.parteners') }}</strong></h3>
                </div>

                <div class="col-auto ms-auto text-end mt-n1">
                    <button v-if="$can('users_create')" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#new-partener-modal">
                        {{ $t('page.parteners.new_partener') }}
                    </button>
                </div>
            </div>
            <div class="modal fade" id="user-detail-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <div class="card" v-if="!isStart && user">
                                <div class="row card-header mb-2 mb-xl-3">
                                    <div class="col-auto d-none d-sm-block">
                                        <h5 class="card-title">
                                            <span>{{ user?.name }}</span>
                                        </h5>
                                    </div>
            
                                    <div class="col-auto text-end">
                                        <router-link v-if="$can('user_roles_view')" :to="'user/'+user.id+'/permissions'" class="btn btn-primary btn-sm btn-sm rounded">
                                            {{ $t('page.parteners.define_roles') }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <table class="table table-sm mt-2 mb-4">
                                        <tbody>
                                            <tr>
                                                <th>{{ $t('general.name') }}</th>
                                                <td class="text-end">{{ user?.name }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('forms.company') }}</th>
                                                <td class="text-end">
                                                    <span v-if="user && user.entreprise__name">{{ user.entreprise__name }}</span>
                                                    <span v-else> --- </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('general.email') }}</th>
                                                <td class="text-end">{{ user?.email }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('general.phone') }}</th>
                                                <td class="text-end">{{ user?.phone }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('page.users.department') }}</th>
                                                <td class="text-end">{{ user?.department__name }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('general.country') }}</th>
                                                <td class="text-end">{{ user?.country__name }}</td>
                                            </tr>
                                            <tr>
                                                <th>{{ $t('general.status') }}</th>
                                                <td class="text-end">
                                                    <span v-show="user?.status == 'active'" class="badge bg-success">{{ $t('general.active') }}</span>
                                                    <span v-show="user?.status != 'active'" class="badge bg-warning">{{ $t('general.inactive') }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td v-if="$can('active_or_desactive_user')" colspan="2" class="text-end">
                                                    <span v-show="user?.status == 'active'">
                                                        <button @click="changeUserStatus()" class="btn btn-danger text-end" title="Desactivate"><i
                                                                class="fas fa-times"></i>
                                                                {{ $t('page.users.desactivate') }}</button></span>
                                                    <span v-show="user?.status != 'active'">
                                                        <button @click="changeUserStatus()" class="btn btn-primary text-end" title="Activate">
                                                            {{ $t('page.users.activate') }}</button></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <empty-page-component v-else code="" :message="$t('general.loading') +'...'"></empty-page-component>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="new-partener-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('page.parteners.new_partener') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3" >
                            <form @submit.prevent="addNewPartener" class="row g-3 needs-validation" novalidate>
                                <div class="col-md-12">
                                    <label for="validationCustom01" class="form-label">{{ $t('page.users.name') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="text" v-model="userFormData.name" class="form-control" id="validationCustom01" :placeholder="$t('page.users.name')"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_name') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom03" class="form-label">{{ $t('page.users.email') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="email" v-model="userFormData.email" class="form-control" id="validationCustom03" placeholder="Email"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_email') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom08" class="form-label">{{ $t('new.Department') }}</label>
                                    <select class="form-select" v-model="userFormData.department" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option v-for="dep in departments" :key="dep.id" :value="dep.id" >{{ dep.name }}</option>
                                    </select>                                    
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_department') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom08" class="form-label">{{ $t('page.users.adresse') }}</label>
                                    <input type="text" v-model="userFormData.address" class="form-control" id="validationCustom08" placeholder="Address">
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_address') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom04" class="form-label">{{ $t('page.users.phone') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <input type="phone" v-model="userFormData.phone" class="form-control" id="validationCustom04" placeholder="Phone"
                                        required>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.enter_phone') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('general.country') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select choices-single-country" v-model="userFormData.country" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option v-for="country in countries" :key="country.id" :value="country.id" >{{ country.name }} ({{ country.code }})</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.select_country') }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="validationCustom05" class="form-label">{{ $t('general.type') }} <i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" v-model="userFormData.type" id="validationCustom05" required>
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option value="Individual">{{ $t('forms.individual') }}</option>
                                        <option value="Company">{{ $t('forms.company') }}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.select_type') }}
                                    </div>
                                </div>
                                <div v-if="userFormData.type == 'Company'" class="col-md-6">
                                    <label for="validationCustom06" class="form-label">{{ $t('page.parteners.choose_enterprise') }}<i
                                            class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                    <select class="form-select" v-model="userFormData.entreprise" id="validationCustom05">
                                        <option selected disabled value="">{{ $t('general.choice') }}...</option>
                                        <option v-for="entreprise in entreprises" :key="entreprise.id" :value="entreprise.id">{{ entreprise.name }}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        {{ $t('page.parteners.choose_enterprise') }}
                                    </div>
                                </div>
                                <div v-if="userFormData.type == 'Company'" class="col-md-6">
                                    <label for="validationCustom06" class="form-label">{{ $t('page.parteners.new_entreprise') }}</label>
                                    <button type="button" @click="showAddEnterpriseModal" class="btn btn-info">{{
                                        $t('page.parteners.add_new_entreprise') }}</button>
                                </div>
                                <div v-if="userFormData.type == 'Company'">          
                                    <div class="modal-body m-3" v-if="showEnterpriseModal">
                                        <form @submit.prevent="addNewEntreprise" class="row g-3 needs-validation" novalidate>
                                            <div class="col-md-6">
                                                <label for="validationCustom01" class="form-label">{{ $t('general.name') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.name" class="form-control"
                                                    id="validationCustom01" :placeholder="$t('general.name')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.name') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom02" class="form-label">{{ $t('general.phone') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.phone" class="form-control"
                                                    id="validationCustom02" :placeholder="$t('general.phone')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.phone') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom03" class="form-label">{{ $t('general.email') }} <i
                                                        class="text-danger align-middle me-2 fas fa-fw fa-asterisk"></i></label>
                                                <input type="text" v-model="entrepriseFormData.email" class="form-control"
                                                    id="validationCustom03" :placeholder="$t('general.email')" required>
                                                <div class="invalid-feedback">
                                                    {{ $t('general.phone') }}
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="validationCustom04" class="form-label">{{ $t('general.address') }}</label>
                                                <input type="text" v-model="entrepriseFormData.address" class="form-control"
                                                    id="validationCustom04" :placeholder="$t('general.address')">
                                            </div>
                                            <div class="col-12">
                                                <button v-show="isStart" class="btn btn-primary" type="submit" id="add_project">
                                                    <div class="spinner-border spinner-border-sm text-danger me-2" role="status"></div>
                                                    <div class="spinner-border spinner-border-sm text-warning" role="status"></div>
                                                </button>
                                                <button v-show="!isStart" class="btn btn-primary" type="submit">{{
                                                    $t('page.parteners.add_new_entreprise') }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <CustomButton
                                        buttonType="button"
                                        buttonSubmitType="submit"
                                        buttonClass="btn-primary"
                                        :buttonText="$t('page.parteners.add_new_partener')"
                                        :isLoading="isStart"
                                        :loadingText="$t('general.loading') + '...'"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row card pb-4">
                <div class="col-xl-12">
                    <DatatableComponent 
                        :headers="tableHeaders" 
                        :data="parteners" 
                        :paginable="true" 
                        :currentPage="currentPage"
                        :totalPage="totalPage"
                        :isSearchable="true"
                        :filters="tableFilters"
                        :reloadFunction="initUsers"
                        :isLoading="isLoading"
                    />
                </div>
            </div>
        </div>
        <div v-else class="loader">
            <empty-page-component code="" :message="$t('general.loading') + '...'"></empty-page-component>
        </div>
    </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import DatatableComponent from '@/components/General/DatatableComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue'

export default {
    name: 'partenersComponent',
    data() {
        return {
            isDispo: false,
            parteners: [],
            entreprises: [],
            isLoading: false,
            departments: [],
            showEnterpriseModal: false,
            user: null,
            isUser: false,
            isStart: false,
            userFormData:{
                name: null,
                email: null,
                department: null,
                country: '',
                phone: null,
                user_type: null, 
                password: null, 
                type: 'Individual',
                entreprise: null
            },
            
            typeSubmitEntreprise: 'create',
            entrepriseFormData:{
                name: null,
                address : null,
                phone : null,
                email: null,
                status : 'active'
            },
            countries:[],
            currentPage: 1,
            totalPage: 1,
            tableHeaders: [
                { name: this.$t('page.users.name'), key: 'name', type: 'key' },
                { name: this.$t('page.users.department'), key: 'department__name', type: 'key' },
                { name: this.$t('page.users.phone'), key: 'phone', type: 'key' },
                { name: this.$t('page.users.country'), key: 'country__name', type: 'key' },
                { name: this.$t('page.users.user_type'), key: 'user_type__name', type: 'key', badge: 'bg-primary' },
                { name: this.$t('general.address'), key: 'address', type: 'key' },
                { name: this.$t('page.users.action'), 
                    type: 'action', 
                    actions: [
                        { type: 'modal_view', text: '', function: this.userDetails, show_text: false, target: '#user-detail-modal', check_permission: () => this.$can('users_view')  },
                    ] 
                },
            ],
            tableFilters: [
                { key: 'country', options: [], label: this.$t('page.users.country'), type: 'select', value: 'all' },
                { key: 'department', options: [], label: this.$t('page.users.department'), type: 'select', value: 'all' },
                { key: 'entreprise', options: [], label: this.$t('page.parteners.entreprise'), type: 'select', value: 'all' },
            ],
        }
    },
    components: {
        DatatableComponent,
        CustomButton,
        EmptyPageComponent
    },
    async mounted(){
        this.isDispo = false
        await this.initData()
        await this.initUsers()
        this.isDispo = true
    },
    methods: {
        ...mapActions('ticket', ['getAllDepartments',]),
        ...mapActions('auth', ['userList', 'registerUser', 'activateOrDeactivateUser', 'getCountries']),
        ...mapActions('entreprise', ['fetchEntreprises', 'addEntreprise']),
        ...mapActions('user_type', ['getUserTypeByName']),

        async initUsers(options = { search: '', filters: {}, perPage: 10, page: 1 }) {
            this.isLoading = true
            // Ajouter le type à filters de manière correcte
            options.filters = { ...options.filters, type: 'partner' };

            let response = await this.userList(options);
            this.parteners = response.data || []
            this.currentPage = response.current_page || 1;
            this.totalPage = response.total_pages || 1;
            this.isLoading = false
        }, 
        async initData() {
            this.entreprises = await this.fetchEntreprises()
            this.departments = await this.getAllDepartments()
            this.countries = await this.getCountries()
            this.tableFilters[0].options = this.countries
            this.tableFilters[1].options = this.departments
            this.tableFilters[2].options = this.entreprises
        },
        async userDetails(user){
            this.isStart = true
            this.user = null
            this.user = this.parteners.find(element => element.id === user.id);
            this.isStart = false
        },
        showAddEnterpriseModal() {
            this.showEnterpriseModal = true
        },
        async addNewPartener(){
            this.userFormData.password = this.$genererMotDePasse(10)
            let user_type = await this.getUserTypeByName('Partener')
            if(this.userFormData.type == 'Individual'){
                this.userFormData.entreprise = null
            }
            this.userFormData.user_type = user_type.id
            this.isStart = true
            let response
            try {
                response = await this.registerUser(this.userFormData)
                this.$successNotyf(this.$t('message.partener_success'))
                this.userFormData.name = null,
                this.userFormData.email = null,
                this.userFormData.department = null,
                this.userFormData.phone = null,
                this.userFormData.user_type = null, 
                this.userFormData.password = null
                this.userFormData.address = null
                jQuery('#new-partener-modal').modal('toggle');  // eslint-disable-line no-undef
            } catch (error) {
                this.isStart = false
                this.$errorNotyf(error)
            }
            this.isStart = false
            this.initUsers()
        },
        async addNewEntreprise(){
            try {
                this.isStart = true
                await this.addEntreprise(this.entrepriseFormData)
                this.$successNotyf(this.$t('message.entreprise_success'))
                this.entreprises = await this.fetchEntreprises()
                this.entrepriseFormData.name = null
                this.entrepriseFormData.address = null
                this.entrepriseFormData.email = null
                this.entrepriseFormData.phone = null
                this.showEnterpriseModal = false
            } catch (error) {
                this.$errorNotyf(error)
            }
            this.initData()
            this.isStart = false
        },
        async changeUserStatus(){
            let userId = this.user.id
            await this.activateOrDeactivateUser({id: userId})
            this.initUsers()
            this.userDetails(userId)
        },
    },
}
</script>
<style></style>
