<template>
    <div class="container-fluid">
        <h1 class="h3 mb-3">{{ $t('auth.audittrail') }}</h1>
        <div class="card mb-4">
            <div class="card-body">
                <form>
                    <div class="form-group">
                        <label for="user-select">{{ $t('auth.filter_user') }}</label>
                        <select id="user-select" class="form-control" v-model="selectedUser" @change="getUserAuditTrails">
                            <option value="">{{ $t('auth.all') }}</option>
                            <option v-for="user in userLists" :value="user.id" :key="user.id">{{ user.name }}</option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-body">
                <div v-show="auditTrails_.length" class="table-responsive">
                    <table class="able table-hover my-0">
                        <thead>
                            <tr>
                                <th>{{ $t('auth.actions') }}</th>
                                <th>{{ $t('auth.user') }}</th>
                                <th>{{ $t('auth.date') }}</th>
                                <th>{{ $t('auth.ip_address') }}</th>
                                <th>{{ $t('auth.hostname') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="auditTrail in auditTrails_" :key="auditTrail.id">
                                <td>{{ auditTrail.actions }}</td>
                                <td>{{ auditTrail.user.name }}</td>
                                <td>{{ $formatDateDDMMYYYYHHMM(auditTrail.action_date) }}</td>
                                <td>{{ auditTrail.ip_address }}</td>
                                <td>{{ auditTrail.hostname }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-show="!auditTrails_.length">
                    <div class="alert alert-info alert-dismissible" role="alert">
                        <div class="alert-icon">
                            <i class="far fa-fw fa-bell"></i>
                        </div>
                        <div class="alert-message">
                            <strong>{{ $t('auth.not_valable') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
    name: 'AuditTrailPage',
    data() {
        return {
            auditTrails_: [],
            userLists: [],
            selectedUser: '',
            table: false,
            options: {
                responsive: true,
                lengthChange: true,
                select: {
                    style: "multi"
                },
                lengthMenu:[5,10,15,20,25, 50],
            }
        }
    },
    async mounted() {
        await this.getAuditTrails()
        await this.getUsers()
    },
    methods: {
        ...mapActions('auth', ['users', 'auditTrails', 'userAuditTrails']),
        async getAuditTrails() {
            let response = await this.auditTrails()
            this.auditTrails_ = response
        },
        async getUsers() {
            let response = await this.users()
            this.userLists = response
        },
        async getUserAuditTrails() {
            await this.destroyTable();
            if (this.selectedUser) {
                let response = await this.userAuditTrails(this.selectedUser)
                this.auditTrails_ = response
            } else {
                this.getAuditTrails()
            }
            setTimeout(() => {
            }, 0)
        },
        async destroyTable() {
            if (this.table) { // eslint-disable-line no-undef
                this.table.clear() // eslint-disable-line no-undef
                this.table.destroy() // eslint-disable-line no-undef
                this.table = null // eslint-disable-line no-undef
            }
        },
    }
}
</script>
  