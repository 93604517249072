<template>
  <div class="container-fluid p-0">
    <div class="card p-2">
      <div class="d-flex items-center justify-content-between">
        <h3>{{ $t('page.tickets.details') }}</h3>
        <button @click="goBack" class="btn btn-secondary" :title="$t('general.back')">
          <i class="align-middle fas fa-fw fa-arrow-left"></i> {{ $t('page.tickets.back') }}
        </button>
      </div>
    </div>
    <div class="card mb-3" v-if="canAssignTicket && $can('assign_ticket')">
      <div class="card-header">
        <h5 class="card-title mb-0">{{ $t('page.tickets.assign') }}</h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="assignTicket" class="d-flex items-center justify-content-between">
          <!-- Assignment Type -->
          <div class="d-flex align-items-center">
            <label class="form-label me-2 mb-0">{{ $t('page.tickets.assignedto') }}:</label>
            <select v-model="assignmentType" class="form-select">
              <option value="user">{{ $t('page.tickets.user') }}</option>
              <option value="department">{{ $t('page.tickets.usergroup') }}</option>
            </select>
          </div>
  
          <!-- User Selection -->
          <div v-if="assignmentType === 'user'" class="d-flex align-items-center">
            <label class="form-label me-2 mb-0">{{ $t('page.tickets.userchoice') }}</label>
            <select v-model="selectedUser" class="form-select">
              <option value="">{{ $t('page.users.choice') }}...</option>
              <option v-for="user in allUsers" :key="user.id" :value="user.id"
                :selected="ticket.latest_assignment && ticket.latest_assignment.assigned_to && user.id === ticket.latest_assignment.assigned_to.id">
                {{ user.name }}
              </option>
            </select>
          </div>
  
          <!-- Department Selection -->
          <div v-if="assignmentType === 'department'" class="d-flex align-items-center">
            <label class="form-label me-2 mb-0">{{ $t('page.tickets.departmentchoice') }}</label>
            <select v-model="selectedDepartment" class="form-select">
              <option value="">{{ $t('page.users.choice') }}...</option>
              <option v-for="dept in departments" :key="dept.id" :value="dept.id"
                :selected="ticket.latest_assignment && ticket.latest_assignment.assigned_group && dept.id === ticket.latest_assignment.assigned_group.id">
                {{ dept.name }}
              </option>
            </select>
          </div>
  
          <!-- Buttons -->
          <div>
            <div v-if="isAssigned && !isCompletedTicket" class="d-flex align-items-center">
              <button @click="reassignTicket" class="btn btn-primary" :disabled="isReassigning">
                {{ isReassigning ? '' : $t('page.tickets.reassign') }}
                <div v-if="isReassigning" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
              </button>
            </div>
            <div v-else-if="!isAssigned" class="d-flex align-items-center">
              <button type="submit" class="btn btn-primary" :disabled="isAssigning">
                {{ isAssigning ? '' : $t('page.tickets.assign') }}
                <div v-if="isAssigning" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div :class="(!isEditing) ? 'col-md-12' : 'col-md-8'">
        <div class="card mb-3">
          <div class="card-header bg-primary text-white">
            <h5 class="mb-0">{{ $t('page.tickets.details') }}</h5>
          </div>

          <div v-if="ticket" class="card-body">
            <!-- Ticket Title -->
            <h5 class="mb-3">
              <strong>{{ $t('page.tickets.name') }}:</strong> {{ ticket.title }}
            </h5>

            <!-- Status -->
            <div class="mb-3">
              <strong>{{ $t('page.tickets.status') }}:</strong>
              <span class="badge" :class="{
                'bg-primary': ticket.status === 'Open',
                'bg-info': ticket.status === 'In Progress',
                'bg-success': ticket.status === 'Completed'
              }">
                {{ ticket.status }}
              </span>
            </div>

            <!-- Description -->
            <div class="mb-3">
              <strong>{{ $t('page.tickets.description') }}:</strong>
              <p class="mb-0">{{ ticket.description }}</p>
            </div>

            <!-- Assigned To -->
            <div class="mb-3">
              <strong>{{ $t('page.tickets.assignedto') }}:</strong>
              <p class="mb-0">
                <span v-if="ticket.latest_assignment">
                  <span v-if="ticket.latest_assignment.assigned_to">
                    {{ $t('page.tickets.user') }}: {{ ticket.latest_assignment.assigned_to.name }}
                  </span>
                  <span v-else-if="ticket.latest_assignment.assigned_group">
                    {{ $t('page.tickets.usergroup') }}: {{ ticket.latest_assignment.assigned_group.name }}
                  </span>
                </span>
                <span v-else>{{ $t('page.tickets.notassigned') }}</span>
              </p>
            </div>

            <!-- Due Date -->
            <div class="mb-3">
              <strong>{{ $t('page.tickets.due_date') }}:</strong>
              <p class="mb-0">{{ $formatDateWithTime(ticket.resolution_deadline) }}</p>
            </div>

            <!-- Started Time -->
            <div class="mb-3">
              <strong>{{ $t('page.tickets.started') }}:</strong>
              <p class="mb-0">{{ $formatDateWithTime(ticket.started_time) }}</p>
            </div>

            <!-- Edit Button -->
            <div v-if="canUpdateTicket" class="text-end mt-3">
              <a v-if="!isAssignedToCurrentUser" class="btn btn-warning btn-sm" href="#" @click="startEdit">
                {{ $t('page.tickets.edit_ticket') }}
              </a>
            </div>
          </div>

          <div v-else class="card-body text-center">
            <empty-page-component code="" :message="$t('general.loading')"></empty-page-component>
          </div>
        </div>
      </div>
      <div v-if="isEditing" class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">{{ $t('page.tickets.update') }}</h5>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-start">
              <form @submit.prevent="editTicket">
                <div class="mb-3">
                  <label for="title" class="form-label">{{ $t('page.tickets.name') }}</label>
                  <input v-model="editedTicket.title" type="text" class="form-control" id="title" required>
                </div>
                <div class="mb-3">
                  <label for="description" class="form-label">Description</label>
                  <textarea v-model="editedTicket.description" class="form-control" id="description" rows="3"
                    required></textarea>
                </div>
                <button type="submit" class="btn btn-primary me-2" :disabled="isEditingLoading">{{ isEditingLoading ? '' :
                  $t('page.tickets.update') }}
                  <div v-if="isEditingLoading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">{{ $t('general.loading') }}...</span>
                  </div>
                </button>
                <CustomButton
                    buttonType="button"
                    buttonClass="btn-secondary"
                    :buttonText="$t('general.cancel')"
                    :loadingText="$t('general.loading') + '...'"
                    @click="cancelEdit"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyPageComponent from '@/components/General/EmptyPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'TicketDetail',
  components: {
    EmptyPageComponent,
    CustomButton
  },

  data() {
    return {
      isEditingLoading: false,
      isReassigning: false,
      isAssigning: false,
      allUsers: null,
      ticket: [],
      selectedUser: null,
      selectedUserType: null,
      userTypes: [],
      selectedDepartment: null,
      departments: [],
      assignmentType: 'user',
      isEditing: false,
      editedTicket: {},
    };
  },

  watch: {
    assignmentType(newValue) {
      if (newValue === 'user') {
        this.selectedUserType = null;
        this.selectedDepartment = null;
      } else if (newValue === 'userType') {
        this.selectedUser = null;
        this.selectedDepartment = null;
      } else if (newValue === 'department') {
        this.selectedUser = null;
        this.selectedUserType = null;
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['getCurrentUser']),

    canUpdateTicket() {
      return this.ticket.status !== 'Completed' && this.ticket.status !== 'In Progress';
    },

    canAssignTicket() {
      return this.ticket.status !== 'Completed' && !this.isAssignedToGroup && !this.isAssignedToDepartment;
    },

    isAssigned() {
      return this.ticket.latest_assignment && (this.ticket.latest_assignment.assigned_to || this.ticket.latest_assignment.assigned_group || this.ticket.latest_assignment.assigned_department);
    },

    isAssignedToGroup() {
      return this.ticket.latest_assignment && this.ticket.latest_assignment.assigned_group;
    },

    isAssignedToDepartment() {
      return this.ticket.latest_assignment && this.ticket.latest_assignment.assigned_department;
    },

    isCompletedTicket() {
      return this.ticket.status === 'Completed';
    },

    isAssignedToCurrentUser() {
      return (this.ticket.latest_assignment &&
        ((this.ticket.latest_assignment.assigned_to &&
          this.ticket.latest_assignment.assigned_to.id === this.getCurrentUser?.id) ||
          (this.ticket.latest_assignment.assigned_group &&
            this.ticket.latest_assignment.assigned_group.id === this.getCurrentUser.user_type.id) ||
          (this.ticket.latest_assignment.assigned_department &&
            this.ticket.latest_assignment.assigned_department.id === this.getCurrentUser.department.id)));
    }
  },

  methods: {
    ...mapActions('ticket', ['fetchTickets', 'getDepartments', 'fetchTicket', 'updateTicket', 'assignTicketToUser', 'assignTicketToUserType', 'assignTicketToDepartment', 'reassignTicketToUser']),
    ...mapActions('auth', ['users', '_currentUser', 'getUsersByUserTypeName',]),
    ...mapActions('user_type', ['fetchUserTypes']),

    async loadTicket() {
      try {
        const ticketId = this.$route.params.ticket_id;
        const response = await this.fetchTicket({ id: ticketId });
        this.ticket = response.data;

        // Sélectionner l'utilisateur assigné par défaut
        if (this.ticket.latest_assignment && this.ticket.latest_assignment.assigned_to) {
          this.selectedUser = this.ticket.latest_assignment.assigned_to.id;
        }
      } catch (error) {
        console.error('Error loading ticket:', error);
      }
    },

    async loadUsers() {
      try {
        this.allUsers = await this.users()
      } catch (error) {
        console.error('Error loading users:', error);
      }
    },

    async loadUserTypes() {
      try {
        this.userTypes = await this.fetchUserTypes()
      } catch (error) {
        console.error('Error loading user types:', error);
      }
    },

    async loadDepartments() {
      try {
        this.departments = await this.getDepartments()
      } catch (error) {
        console.error('Error loading departments:', error);
      }
    },

    async assignTicket() {
      this.isAssigning = true;
      try {
        if (this.assignmentType === 'user' && this.selectedUser) {
          await this.assignTicketToUser({
            ticket_id: this.$route.params.ticket_id,
            user_id: this.selectedUser
          });
        } else if (this.assignmentType === 'department' && this.selectedDepartment) {
          await this.assignTicketToDepartment({
            ticket_id: this.$route.params.ticket_id,
            department_id: this.selectedDepartment
          });
        }
        await this.loadTicket();
        this.$successNotyf(this.$t('messages.d'));
      } catch (error) {
        console.error('Error assigning ticket:', error);
        this.$errorNotyf(this.$t('messages.c'));
      } finally {
        this.isAssigning = false;
      }
    },

    async reassignTicket() {
      this.isReassigning = true;
      try {
        if (this.assignmentType === 'user' && this.selectedUser) {
          await this.reassignTicketToUser({
            ticket_id: this.$route.params.ticket_id,
            user_id: this.selectedUser
          });
        } else if (this.assignmentType === 'department' && this.selectedDepartment) {
          await this.assignTicketToDepartment({
            ticket_id: this.$route.params.ticket_id,
            user_type_id: this.selectedDepartment
          });
        }
        await this.loadTicket();
        this.$successNotyf(this.$t('messages.d'));
      } catch (error) {
        console.error('Error assigning ticket:', error);
        this.$errorNotyf(this.$t('messages.c'));
      } finally {
        this.isReassigning = false;
      }
    },

    startEdit() {
      this.editedTicket = { ...this.ticket };
      this.isEditing = true;
    },

    cancelEdit() {
      this.isEditing = false;
    },

    goBack() {
      window.history.go(-1);
    },

    async editTicket() {
      this.isEditingLoading = true;
      try {
        await this.updateTicket({
          ticket_id: this.$route.params.ticket_id,
          ...this.editedTicket
        });
        this.$successNotyf(this.$t('messages.b'));
        this.isEditing = false;
        await this.loadTicket();
      } catch (error) {
        this.$errorNotyf(this.$t('messages.a'));
        console.error('Error updating ticket:', error);
      } finally {
        this.isEditingLoading = false;
      }
    }
  },

  mounted() {
    this.loadTicket();
    this.loadDepartments();
    this.loadUsers();
    this.loadUserTypes();
  }
};
</script>
