<script>
import moment from 'moment'
export default {
    name: "HelperVue",
    methods: {
        $errorNotyf(error) {
            if (error.message) {
                window.notyf.open({
                    type: 'danger',
                    message: error.message,
                    dismissible: true
                });
            } else if (typeof error === 'object') {
                const errors = { ...error };

                const notifications = [];

                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        notifications.push({
                            type: 'danger',
                            message: `${key.toUpperCase()} : ${errors[key]}`,
                            dismissible: true,
                        });
                    }
                }

                // Afficher les notifications
                for (const notification of notifications) {
                    window.notyf.open(notification);
                }
            } else if (Array.isArray(error)) {
                // Si error est un tableau d'erreurs
                for (const errorMsg of error) {
                    window.notyf.open({
                        type: 'danger',
                        message: errorMsg,
                        dismissible: true
                    });
                }
            }
        },

        $successNotyf(message, type='success') {
            window.notyf.open({
                type: type,
                message: message,
                dismissible: true
            });
        },
        $formatDateDDMMYYYY(date) {
            return date ? moment(date).format("DD-MM-YYYY") : '-- -- ----'
        },
        $formatDateDDMMYYYYHHMM(date) {
            return date ? moment(date).format("DD-MM-YYYY HH:mm") : '-- -- ----'
        },
        $genererMotDePasse(longueur) {
            const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,.-=";
            let motDePasse = "";
            for (let i = 0; i < longueur; i++) {
                const indexAleatoire = Math.floor(Math.random() * caracteres.length);
                motDePasse += caracteres.charAt(indexAleatoire);
            }
            return motDePasse;
        },
        $formatDate(date) {
            return date ? moment(date).format("DD MMM YYYY") : null
        },
        $formatDateWithTime(date) {
            return date ? moment(date).format("DD MMM YYYY HH:mm") : null
        },
        $formatDuration(floatDuration) {
            // Calcul des heures, minutes et secondes
            const hours = Math.floor(floatDuration);
            const minutes = Math.floor((floatDuration - hours) * 60);
            const seconds = Math.round(((floatDuration - hours) * 60 - minutes) * 60);

            // Construction de la chaîne formatée
            let formattedDuration = '';

            if (hours > 0) {
                formattedDuration += `${hours}H `;
            }
            if (minutes > 0 || hours > 0) {  // Ajouter les minutes si l'heure est présente ou si minutes > 0
                formattedDuration += `${minutes}min `;
            }
            formattedDuration += `${seconds}s`;

            return formattedDuration;
        },
        $closeModal(modalId) {
			const modal = document.getElementById(modalId);
			if (modal) {
				modal.classList.remove("show");
				modal.style.display = 'none';
				document.body.classList.remove('modal-open');
				document.body.style.overflow = ''; //Réinitialise le défilement
				document.body.style.paddingRight = ''; // Si un padding au niveau du scrollbar
				document.querySelector('.modal-backdrop')?.remove();
			}
        }
    }
}
</script>