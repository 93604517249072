<template>
    <div class="card">
        <table class="table table-hover">
            <thead>
                <!-- Nouveau row pour les entêtes supplémentaires -->
                <tr>
                    <th colspan="6">
                        <div class="d-flex justify-content-center align-items-center">
                            <img :src="companyLogo" alt="Logo Société" class="img-fluid me-3"
                                style="max-width: 200px;" />
                            <div>
                                {{ $t('evaluation.report_header') }}<br>
                                {{ $t('evaluation.report_frequency') }}: {{ $t('evaluation.' + reportFrequency) }}<br>
                                {{ $t('evaluation.report_start_date') }}: {{ formattedReportStartDate }}<br>
                                {{ $t('evaluation.report_end_date') }}: {{ formattedReportEndDate }}
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>{{ $t('user_month_stat.client') }}</th>
                    <th>{{ $t('user_month_stat.project') }}</th>
                    <th>{{ $t('user_month_stat.duration') }}</th>
                    <th>{{ $t('user_month_stat.tasks') }}</th>
                    <th>{{ $t('user_month_stat.niveau') }}</th>
                    <th>{{ $t('evaluation.status') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in dataReport" :key="item.user.user_id">
                    <td>{{ getClientName(item.user) }}</td>
                    <td>{{ getProjectName(item.projects[0].project) }}</td>
                    <td>
                        <ul>
                            <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                                {{ $formatDuration(task.duration) }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                                {{ task.task_name }}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                            {{ $t('evaluation.' + task.niveau) }}
                        </li>
                    </td>
                    <td>
                        <li v-for="task in item.projects[0].tasks" :key="task.task_id">
                            {{ $t('evaluation.' + task.status) }}
                        </li>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="bg-secondary text-white">
                    <td colspan="5">{{ $t('evaluation.total_hours') }} : </td>
                    <td><strong>{{ $formatDuration(calculateTotalDuration()) }}</strong></td>
                </tr>
                <tr class="bg-warning">
                    <td colspan="5">{{ $t('evaluation.general_moyenne') }} : </td>
                    <td><strong class="text-dark">{{ calculateAverage() }} /20</strong></td>
                </tr>
                <tr class="bg-info">
                    <td colspan="5">{{ $t('evaluation.total_completed') }} : </td>
                    <td><strong>{{ calculateDurationByStatus('Completed').totalDuration }}</strong></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'HelloComponent',
    data() {
        return {
            companyLogo: "../img/avatars/eruditLogo.png",
        }
    },
    props: {
        dataReport: {
            type: Array,
            required: true
        },
        reportFrequency: {
            type: String,
            required: true
        },
        reportStartDate: {
            type: String,
            required: true
        },
        reportEndDate: {
            type: String,
            required: true
        }
    },
    computed: {
        formattedReportStartDate() {
            return moment(this.reportStartDate, 'DD/MM/YYYY').format('DD MMM YYYY');
        },
        formattedReportEndDate() {
            return moment(this.reportEndDate, 'DD/MM/YYYY').format('DD MMM YYYY');
        }
    },
    methods: {
        getClientName(user) {
            if (user.entreprise_name) {
                return user.entreprise_name;
            } else {
                return `${user.name}`;
            }
        },
        getProjectName(project) {
            return project.project_name;
        },
        calculateTotalDuration() {
            let totalDuration = 0;
            for (const item of this.dataReport) {
                for (const task of item.projects[0].tasks) {
                    totalDuration += task.duration;
                }
            }
            return totalDuration;
        },
        calculateDurationByStatus(status) {
            let totalDuration = 0;
            let statusCount = 0;

            for (const item of this.dataReport) {
                for (const task of item.projects[0].tasks) {
                    if (task.status === status) {
                        totalDuration += task.duration;
                        statusCount++;
                    }
                }
            }

            return {
                totalDuration,
                statusCount
            };
        },
        calculateAverage() {
            let totalDuration = 0;
            let completedTasksCount = 0;

            for (const item of this.dataReport) {
                for (const task of item.projects[0].tasks) {
                    if (task.status === 'Completed') {  // Compter uniquement les tâches "Completed"
                        totalDuration += task.duration;
                        completedTasksCount++;
                    }
                }
            }

            if (completedTasksCount > 0) {
                return (totalDuration / completedTasksCount).toFixed(2);  // Calcul de la moyenne
            } else {
                return "0.00";  // Retourne "0.00" si aucune tâche "Completed"
            }
        },
    }
}
</script>